import { Container, createStyles } from '@mantine/core'
import { useFirebase } from 'firebase/useFirebase'
import { Suspense, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useCustomizerStore } from 'store/customizerStore'
import { Loader, UserButtonProps } from '../common'
import { UserHeader } from './UserHeader'
import { VerticalSidebar } from './VerticalSidebar'

const useStyles = createStyles(
  (theme, { isCollapsed }: { isCollapsed: boolean }) => ({
    wrapper: {
      display: 'flex',
      minHeight: '100dvh',
      flexGrow: 1,
      marginLeft: isCollapsed ? 75 : 280,
      transition: 'all .4s cubic-bezier(.25,.8,.25,1)',

      [`${theme.fn.smallerThan('sm')}`]: {
        marginLeft: 0,
      },
    },
    content: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
    },
  }),
)

interface PanelViewProps {
  user: UserButtonProps
  onLogout: () => void
}

export const PanelView = ({ user, onLogout }: PanelViewProps) => {
  const isSidebarCollapsed = useCustomizerStore(state => state.sidebarCollapsed)
  const { classes } = useStyles({
    isCollapsed: isSidebarCollapsed,
  })
  const checkNotificationsState = useFirebase()

  useEffect(() => {
    const abortController = new AbortController()
    checkNotificationsState(abortController.signal)

    return () => {
      abortController.abort()
    }
  }, [])

  return (
    <div className={classes.wrapper}>
      <VerticalSidebar />
      <div className={classes.content}>
        <UserHeader user={user} onLogout={onLogout} />
        <Container
          p="lg"
          size={2200}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            flexGrow: 1,
          }}
        >
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Container>
      </div>
    </div>
  )
}
