import { Group, SegmentedControl } from '@mantine/core'
import {
  TAvailableDashboardList,
  useGetAvailableDashboards,
} from 'api/dashboards/getAvailableDashboards'
import {
  useDashboardIdContext,
  useDeviceModelContext,
} from 'components/devices/DeviceIndicators'
import { useParams } from 'react-router-dom'
import { Query } from '..'
import { useDashboardContext } from './DashboardProvider'

export const DashboardSelector = () => {
  const dashboard = useDashboardContext()
  const { deviceId } = useParams()
  const deviceModelId = useDeviceModelContext()
  const query = useGetAvailableDashboards({
    deviceId: deviceId!,
    modelId: deviceModelId,
  })
  const { setDashboardId } = useDashboardIdContext()

  return (
    <Group align={'center'}>
      <Query.Wrapper disableNoDataComponent query={query}>
        <Query.Data>
          {(data: TAvailableDashboardList) => (
            <SegmentedControl
              styles={{
                root: {
                  maxWidth: '75vw',
                  overflow: 'auto',
                },
              }}
              data={data.map(item => ({
                value: item.id,
                label: item.name ?? 'No name',
                disabled: item.disabled,
              }))}
              value={dashboard.id}
              onChange={val => setDashboardId(val)}
            />
          )}
        </Query.Data>
      </Query.Wrapper>
    </Group>
  )
}
