import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { TDeviceGroupList } from 'shared/types'
import { api } from '..'

export const getDeviceGroupList = (params?: Record<string, string>) =>
  api
    .get<TDeviceGroupList>('/v1/device-group', {
      params,
    })
    .then(({ data }) => data)

export const getDeviceGroupListForMap = (params?: Record<string, string>) =>
  api
    .get<TDeviceGroupList>('/v1/device-group', {
      params,
    })
    .then(({ data }) => data)

export const deviceGroupListQuery = (params?: Record<string, string>) => ({
  queryFn: () => getDeviceGroupList(params),
  queryKey: ['deviceGroups', params],
})

export const deviceGroupListHelper = {
  queryFn: () =>
    getDeviceGroupList({ results: '100' }).then(data =>
      data?.items?.map?.(group => ({
        value: group?.id,
        label: group?.name,
      })),
    ),
  queryKey: ['deviceGroup.selectList', 'helper'],
}

export const deviceGroupListForMapViewHelper = {
  queryFn: () =>
    getDeviceGroupListForMap({ results: '100' }).then(data =>
      data?.items?.map?.(group => ({
        value: group?.id,
        label: group?.name,
      })),
    ),
  queryKey: ['deviceGroup-for-map-helper.select'],
}

export const useGetDeviceGroupList = () =>
  usePaginatedQuery(deviceGroupListQuery)
