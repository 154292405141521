import { ActionIcon } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import { useTranslation } from 'react-i18next'
import { TbTrash } from 'react-icons/tb'
import { useDeleteRow } from './hooks/useDeleteRow'

export const DeleteDashboardRow = ({ rowId }: { rowId: string }) => {
  const { t } = useTranslation()
  const mutation = useDeleteRow({ rowId })

  const onClick = () => {
    openConfirmModal({
      title: t('dashboard.deleteRow'),
      children: t('dashboard.deleteRowConfirm'),
      confirmProps: { color: 'red', loading: mutation.isLoading },
      labels: { confirm: t('generic.delete'), cancel: t('generic.cancel') },
      onConfirm: mutation.mutate,
    })
  }

  return (
    <ActionIcon onClick={onClick} variant="light" color="red">
      <TbTrash />
    </ActionIcon>
  )
}
