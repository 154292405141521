import { Box, Loader, Text, Title } from '@mantine/core'
import { useGetLastMeasurement } from 'api/measurements/getLastMeasurement'
import { useDayjs } from 'hooks/useDayjs'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import { TIndicator } from 'shared/types'

type NumericalValueProps = {
  indicator: TIndicator['code']
  deviceId: string
}

export const NumericalValue = ({
  indicator,
  deviceId,
}: NumericalValueProps) => {
  const { t } = useTranslation()
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())
  const dayjs = useDayjs()
  const { data, isSuccess, isLoading } = useGetLastMeasurement(
    deviceId,
    indicator,
    params?.toDate,
  )

  if (isLoading)
    return (
      <div
        style={{
          height: '350px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Loader variant="bars" color="orange" />
      </div>
    )

  if (isSuccess) {
    const isOlderThanSixHours = dayjs().diff(dayjs(data.date), 'hours') > 6

    return (
      <div
        style={{
          height: '350px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <div>
          <Title order={2} color={isOlderThanSixHours ? 'orange' : undefined}>
            {data.value!.toFixed(2)} {data?.unit?.symbol}
          </Title>
          <Box>
            <Text c="dimmed">
              {isOlderThanSixHours && t('measurement.isOld')}
            </Text>
            <Text c="dimmed">
              {t('generic.date')}:{' '}
              {dayjs(data?.date).format('DD MMM YYYY, HH:mm')}
            </Text>
          </Box>
        </div>
      </div>
    )
  }
  return null
}
