import { z } from 'zod'

export const SignUpSchema = z
  .object({
    email: z.string().email(),
    password: z.string(),
    confirmPassword: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    organizationName: z.string(),
    agreement: z.literal(true),
    CfTurnstileResponse: z.string(),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: "Passwords don't match",
        path: ['confirmPassword'],
      })
    }
  })

export type TSignUpFields = z.infer<typeof SignUpSchema>
