import { FaNetworkWired } from 'react-icons/fa'
import { FiCpu } from 'react-icons/fi'
import { RiRouterLine } from 'react-icons/ri'
import { TDevice } from 'shared/types'

const getDeviceIcon = (deviceType: TDevice['type']) => {
  switch (deviceType) {
    case 'LoraGateway':
      return RiRouterLine
    case 'SNMP':
      return FaNetworkWired
    case 'LoraDevice':
    default:
      return FiCpu
  }
}

export default getDeviceIcon
