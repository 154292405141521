import { z } from 'zod'
import { AlertLevelEnum } from '../alertRules'

export const exceedSchema = z.object({
  id: z.string(),
  alertId: z.string(),
  indicator: z
    .object({
      code: z.string().nullable(),
      name: z.string().nullable(),
      unit: z.string().nullish(),
    })
    .nullable(),
  currentValue: z.number(),
  value: z.number(),
  mathSymbol: z.string(),
})

export const logSchema = z.object({
  id: z.string(),
  date: z.string(),
  status: z.string(),
  comment: z.string().nullish(),
  createdBy: z.string().nullish(),
})

export const alertStatusSchema = z.enum([
  'Resolved',
  'Unresolved',
  'Normalized',
])

export const alertSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  createdAt: z.string(),
  name: z.string(),
  description: z.string().nullish(),
  level: AlertLevelEnum,
  status: alertStatusSchema,
  lastUpdatedAt: z.string().nullish(),
  exceeds: z.array(exceedSchema),
  logs: z.array(logSchema),
})

export type TAlertStatus = z.infer<typeof alertStatusSchema>

export type TAlert = z.infer<typeof alertSchema>

export type TExceed = z.infer<typeof exceedSchema>

export type TLog = z.infer<typeof logSchema>
