import { r } from 'config/routes'
import { TDeviceType } from 'shared/types'

export const getDeviceRoute = (type: TDeviceType) => {
  switch (type) {
    case 'LoraDevice':
      return r['loraDevices.view']
    case 'LoraGateway':
      return r['gateways.view']
    case 'SNMP':
      return r['snmpDevices.view']
    default:
      return r['loraDevices.view']
  }
}
