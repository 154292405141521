import { z } from 'zod'

export const RemindPasswordSchema = z.object({
  email: z.string().email(),
})

export type RemindPassword = z.infer<typeof RemindPasswordSchema>

export const ResetPasswordSchema = z
  .object({
    token: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: "Passwords don't match",
      })
    }
  })

export type TResetPasswordFields = z.infer<typeof ResetPasswordSchema>
