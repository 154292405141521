import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { Grid, Group } from '@mantine/core'
import { TDashboardColumn } from 'api/dashboards/getDashboard'
import { MeasurementAreaChart } from 'components/chart'
import { MeasurementAreaChartPublic } from 'components/chart/area/MeasurementAreaChartPublic'
import { Ref, forwardRef, memo } from 'react'
import { useParams } from 'react-router-dom'
import { DashboardColumnSizer } from './DashboardColumnSizer'
import { SortableItemProps } from './DashboardProvider'
import { DeleteDashboardColumn } from './DeleteDashboardColumn'
import { useDashboardStore } from './hooks/useDashboardStore'
import { dashboardColumnSize } from './utils/dashboardColumnSize'
import { animateLayoutChanges } from './utils/layoutAnimationHandler'

export const DashboardColumn = memo((column: TDashboardColumn) => {
  const isEditMode = useDashboardStore(state => state.isEditMode)

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: column.id,
    data: column,
    disabled: !isEditMode,
    animateLayoutChanges: animateLayoutChanges,
  })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: isEditMode ? 'pointer' : 'auto',
    opacity: isDragging ? 0.8 : undefined,
  }

  return (
    <DashboardColumnItem
      ref={setNodeRef}
      style={style}
      listeners={listeners}
      attributes={attributes}
      {...column}
    />
  )
})
DashboardColumn.displayName = 'DashboardColumn'

export const DashboardColumnItem = forwardRef(
  (
    {
      style,
      attributes,
      listeners,
      indicator,
      name,
      columnSize,
      dashboardId,
      isPublicView,
      columnType,
      ...props
    }: TDashboardColumn &
      SortableItemProps & {
        dashboardId?: string
        isPublicView?: boolean
        columnType?: number
      },
    ref: Ref<HTMLDivElement>,
  ) => {
    const width = dashboardColumnSize.get(columnSize)?.colSpan ?? 2
    const { deviceId } = useParams()
    const isEditMode = useDashboardStore(state => state.isEditMode)

    return (
      <Grid.Col
        sx={{ position: 'relative' }}
        md={width}
        ref={ref}
        style={style}
      >
        <div {...attributes} {...listeners}>
          {isPublicView ? (
            <MeasurementAreaChartPublic
              name={name}
              disabled={isEditMode}
              columnType={columnType}
              deviceId={deviceId!}
              dashboardId={dashboardId}
              indicator={indicator}
            />
          ) : (
            <MeasurementAreaChart
              name={name}
              disabled={isEditMode}
              deviceId={deviceId!}
              indicator={indicator}
              columnType={columnType}
            />
          )}
        </div>
        {isEditMode && (
          <Group sx={{ position: 'absolute', top: '1em', right: '1em' }}>
            <DashboardColumnSizer
              columnSize={columnSize}
              columnId={props.id}
              rowId={props.dashboardRowId}
            />
            <DeleteDashboardColumn
              rowId={props.dashboardRowId}
              columnId={props.id}
            />
          </Group>
        )}
      </Grid.Col>
    )
  },
)
DashboardColumnItem.displayName = 'DashboardColumnItem'
