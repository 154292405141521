import { useQuery } from '@tanstack/react-query'
import { TMeasurementItem } from 'shared/types'
import { api } from '..'

export const getLastMeasurement = (
  deviceId: string,
  indicatorCode: string,
  dateTo: string | null | undefined,
) =>
  api
    .get<TMeasurementItem>('/v1/measurements/last-value', {
      params: {
        deviceId,
        indicatorCode,
        dateTo,
      },
    })
    .then(({ data }) => data)

export const lastMeasurementQueryKey = ({
  deviceId,
  indicatorCode,
  dateTo,
}: {
  deviceId: string
  indicatorCode: string
  dateTo?: string | undefined | null
}) => ['measurements.chart.lastValue', deviceId, indicatorCode, dateTo]

export const lastMeasurementQuery = (
  deviceId: string,
  indicatorCode: string,
  dateTo: string | undefined | null,
) => ({
  queryFn: () => getLastMeasurement(deviceId, indicatorCode, dateTo),
  queryKey: lastMeasurementQueryKey({ deviceId, indicatorCode, dateTo }),
})

export const useGetLastMeasurement = (
  deviceId?: string,
  indicatorCode?: string,
  dateTo?: string | undefined | null,
) => {
  if (!deviceId || !indicatorCode)
    throw new Error('Missing deviceId or indicatorCode')

  const query = lastMeasurementQuery(deviceId, indicatorCode, dateTo)

  return useQuery({
    ...query,
    useErrorBoundary: false,
    staleTime: 5 * 60 * 1000,
  })
}
