import { TDashboardColumn } from 'api/dashboards/getDashboard'
import { dashboardColumnSize } from './dashboardColumnSize'

export const calculateRemainingSpace = (columns: TDashboardColumn[]) => {
  const COLUMN_COUNT = 6
  const occupiedSpace = columns.reduce(
    (acc, curr) => acc + dashboardColumnSize.get(curr.columnSize)!.colSpan,
    0,
  )

  return COLUMN_COUNT - occupiedSpace
}
