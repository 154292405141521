import { showNotification } from '@mantine/notifications'
import { T } from 'components/common/T'
import { z } from 'zod'
import { api } from '..'

export const exportDashboardToCSVSchema = z.object({
  fromDate: z.string().nullish(),
  toDate: z.string().nullish(),
})

export type TExportDashboardToCSVFields = z.infer<
  typeof exportDashboardToCSVSchema
>

export const exportDashboardTOCSV =
  ({
    deviceId,
    dashboardId,
    dashboardName,
  }: {
    deviceId: string
    dashboardId: string
    dashboardName: string
  }) =>
  async (data: TExportDashboardToCSVFields) => {
    return api
      .get(`v1/dashboards/export-csv`, {
        responseType: 'blob',
        params: {
          deviceId,
          dashboardId,
          fromDate: data?.fromDate,
          toDate: data?.toDate,
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute(
          'download',
          dashboardName ? `${dashboardName}.csv` : 'Dashboard.csv',
        )
        document.body.appendChild(link)
        link.click()
      })
      .catch(e => {
        showNotification({
          color: 'red',
          message: <T>systemMessages.unexpectedError</T>,
        })
      })
  }
