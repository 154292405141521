import { useEffect, useMemo, useState } from 'react'
import { createStyles, UnstyledButton, Menu, Image, Group } from '@mantine/core'
import { TbChevronDown } from 'react-icons/tb'
import { useGetLanguages } from 'api/translations'
import { useTranslation } from 'react-i18next'
import { api } from 'api/api'
import { useQueryClient } from '@tanstack/react-query'

const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: 150,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 7]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

export function LanguagePicker() {
  const queryClient = useQueryClient()
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles({ opened })
  const { data, isLoading, isSuccess } = useGetLanguages()
  const { i18n } = useTranslation()
  const handleSelect = (id: string) => () => i18n.changeLanguage(id)

  useEffect(() => {
    api.defaults.headers.common['Accept-Language'] = i18n?.language
    queryClient.invalidateQueries()
  }, [i18n?.language])

  const items = useMemo(
    () =>
      data && isSuccess
        ? Array.from(data).map(([code, { name }]) => {
            return (
              <Menu.Item
                icon={<Flag code={code} name={name} />}
                onClick={handleSelect(code)}
                key={name}
              >
                {name}
              </Menu.Item>
            )
          })
        : [],
    [data, isSuccess],
  )

  return (
    <Menu
      disabled={isLoading}
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="md"
      width="target"
      transitionProps={{
        transition: 'pop-top-right',
      }}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control}>
          {data && isSuccess && (
            <Group spacing="xs">
              <Flag code={i18n.language} name={i18n.language} />
              <span className={classes.label}>
                {data.get(i18n.language)?.name}
              </span>
            </Group>
          )}
          <TbChevronDown size={16} className={classes.icon} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  )
}

export const Flag = ({ code, name }: { code: string; name: string }) => {
  const flagCode = code.toLocaleLowerCase().split('-').at(-1)

  return (
    <Image
      src={`https://flagcdn.com/${flagCode}.svg`}
      alt={name}
      width={18}
      height={18}
      radius={9999}
      fit="cover"
    />
  )
}
