import dayjs from 'dayjs'

/*
  Transforms measurements to add null values when gap between measurements is longer than 30 minutes by default.
  This operation is required by ApexCharts in order to not interpolate empty values on chart. 
*/
export function transformMeasurements<
  T extends { date: string; value: number | null },
>(measurements: T[], gapMinutes = 30) {
  const transformed: T[] = []

  measurements.forEach((item, index) => {
    transformed.push(item)
    if (index < measurements.length - 1) {
      const nextItem = measurements[index + 1]
      const diffMinutes = Math.abs(
        dayjs(item.date).diff(dayjs(nextItem.date), 'minute'),
      )

      if (diffMinutes > gapMinutes) {
        const newItem1 = {
          ...item,
          value: null,
          date: dayjs(item.date).add(1, 'minute').toISOString(),
        }
        const newItem2 = {
          ...nextItem,
          value: null,
          date: dayjs(nextItem.date).subtract(1, 'minute').toISOString(),
        }

        transformed.push(newItem1, newItem2)
      }
    }
  })

  return transformed
}
