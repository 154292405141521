import { useQuery } from '@tanstack/react-query'
import { TAlertRule } from 'shared/types'
import { api } from '..'

export const getAlertRule = (alertRuleId: string) =>
  api.get<TAlertRule>(`/v1/alert-rules/${alertRuleId}`).then(({ data }) => data)

export const alertRuleQuery = (alertRuleId: string) => ({
  queryFn: () => getAlertRule(alertRuleId),
  queryKey: ['alertRule', alertRuleId],
})

export const useGetAlertRule = (alertRuleId?: string) => {
  if (!alertRuleId) throw new Error('Missing alertRuleId')
  const query = alertRuleQuery(alertRuleId)

  return useQuery({
    ...query,
  })
}
