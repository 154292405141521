import { Pagination } from '../helper'
import { z } from 'zod'

export const snmpVariableSchema = z.object({
  id: z.string(),
  packetId: z.string(),
  deviceId: z.string(),
  oid: z.string(),
  value: z.string(),
  codeType: z.string(),
  date: z.string(),
})

export const snmpPacketSchema = z.object({
  packetId: z.string(),
  deviceId: z.string(),
  date: z.string(),
  variables: z.array(snmpVariableSchema),
})

export type TSnmpPacket = z.infer<typeof snmpPacketSchema>
export type TSnmpVariable = z.infer<typeof snmpVariableSchema>

export type TSnmpPacketList = Pagination<TSnmpPacket>
