import { z } from 'zod'

export const assignAlertToDeviceSchema = z.object({
  alertRulesId: z.array(z.string()),
})

export type TAssignAlertToDeviceFields = z.infer<
  typeof assignAlertToDeviceSchema
>

export const assignDeviceToAlertRuleSchema = z.object({
  devicesId: z.array(z.string()),
})

export type TAssignDeviceToAlertFields = z.infer<
  typeof assignDeviceToAlertRuleSchema
>
