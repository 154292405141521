import { useQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import { TDeviceIndicatorsResponse } from 'shared/types'
import { api } from '..'

export const getModelIndicators = (modelId: string) =>
  api
    .get<TDeviceIndicatorsResponse>(`/v1/device-models/${modelId}/indicators`)
    .then(({ data }) => data.indicators)

export const getModelIndicatorsOptionsHelper = (
  modelId: string,
  t: TFunction,
) => ({
  queryKey: ['indicators', 'helper', modelId],
  queryFn: () =>
    getModelIndicators(modelId).then(data =>
      data.map(item => ({
        value: item.indicatorCode,
        label: `${
          item?.indicator?.isExternal
            ? item?.indicator?.name
            : t(`indicator.${item.indicatorCode}`)
        } ${item.indicator?.unit ? `(${item.indicator.unit})` : ''}`,
        unit: item.indicator.unit,
      })),
    ),
})

export const useGetModelIndicators = (modelId?: string) => {
  if (!modelId) throw new Error('Missing modelId')

  return useQuery({
    queryFn: () => getModelIndicators(modelId),
    queryKey: ['indicators', modelId],
    staleTime: Infinity,
  })
}
