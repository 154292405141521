import { openConfirmModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { useMutation } from '@tanstack/react-query'
import { deleteDashboard } from 'api/dashboards/deleteDashboard'
import { TDashboard } from 'api/dashboards/getDashboard'
import { useTranslation } from 'react-i18next'

export const useRemoveDashboard = (
  { id }: Pick<TDashboard, 'id'>,
  onDelete: () => void,
) => {
  const { t } = useTranslation()

  const removeDashboardMutation = useMutation({
    mutationFn: deleteDashboard(id),

    onSuccess: () => {
      showNotification({
        color: 'green',
        message: 'Dashboard removed successfully',
      })
      onDelete()
    },
    onError: () => {
      showNotification({
        color: 'red',
        message: 'An error has occured',
      })
    },
  })

  const openModal = () => {
    openConfirmModal({
      title: t('dashboard.delete'),
      children: 'Czy na pewno chcesz usunąć ten panel?',
      confirmProps: {
        color: 'red',
        disabled: removeDashboardMutation.isLoading,
      },
      onConfirm: () => removeDashboardMutation.mutate(),
      labels: { confirm: t('generic.delete'), cancel: t('generic.cancel') },
    })
  }

  return openModal
}
