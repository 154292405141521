import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { deleteRow } from 'api/dashboards/addRow'
import { AxiosError } from 'axios'
import { useParams } from 'react-router-dom'
import { ErrorResponse } from 'shared/types'
import { useDashboardContext } from '../DashboardProvider'

export const useDeleteRow = ({ rowId }: { rowId: string }) => {
  const { deviceId } = useParams()
  const queryClient = useQueryClient()
  const dashboard = useDashboardContext()

  const onError = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data.errors) {
      showNotification({
        color: 'red',
        message: error.response.data.errors
          .map(error => error.message)
          .join(', '),
      })
      return
    }
    showNotification({
      color: 'red',
      message: 'An error occured while removing a row',
    })
  }

  const mutation = useMutation({
    mutationFn: deleteRow({ rowId, dashboardId: dashboard.id }),
    onSuccess: () => {
      queryClient.refetchQueries('dashboards' as unknown as readonly unknown[])
    },
    onError,
  })

  return mutation
}
