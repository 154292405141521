import { Badge, Box, Flex, Loader, Text, Tooltip } from '@mantine/core'
import { useGetLastMeasurement } from 'api/measurements/getLastMeasurement'
import { useDayjs } from 'hooks/useDayjs'
import { useTranslation } from 'react-i18next'
import { TbClock } from 'react-icons/tb'
import { useSearchParams } from 'react-router-dom'
import { TIndicator } from 'shared/types'

type LastMeasurementProps = {
  indicator: TIndicator['code']
  deviceId: string
}

export const LastMeasurement = ({
  indicator,
  deviceId,
}: LastMeasurementProps) => {
  const { t } = useTranslation()
  const dayjs = useDayjs()
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())
  const { data, isSuccess, isLoading } = useGetLastMeasurement(
    deviceId,
    indicator,
    params?.toDate,
  )

  if (isLoading)
    return (
      <Badge
        ml={'auto'}
        color="orange"
        styles={{
          inner: {
            textTransform: 'none',
          },
        }}
        variant="outline"
        p={'md'}
      >
        <Flex align="center" justify="center">
          <Loader size="xs" variant="bars" color="orange" />
        </Flex>
      </Badge>
    )

  if (isSuccess) {
    const isOlderThanSixHours = dayjs().diff(dayjs(data.date), 'hours') > 6

    return (
      <Tooltip
        withArrow
        label={
          <Box>
            <Text>{isOlderThanSixHours && t('measurement.isOld')}</Text>
            <Text>{dayjs(data?.date).format('DD MMM YYYY, HH:mm')}</Text>
          </Box>
        }
      >
        <Badge
          ml={'auto'}
          color={isOlderThanSixHours ? 'orange' : 'brand'}
          styles={{
            inner: {
              textTransform: 'none',
            },
          }}
          variant="outline"
          p={'md'}
        >
          <Flex align="center" gap="xs">
            <TbClock size="1.8em" />
            <Text size="xl">
              {data.value!.toFixed(2)} {data?.unit?.symbol}
            </Text>
          </Flex>
        </Badge>
      </Tooltip>
    )
  }

  return null
}
