import { Loader } from 'components'
import { StrictMode, Suspense } from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import 'config/sentry'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <StrictMode>
    <Suspense fallback={<Loader />}>
      <App />
    </Suspense>
  </StrictMode>,
)
