import { api } from 'api/api'
import { AxiosResponse } from 'axios'
import { Pagination } from 'shared/types'

export async function* iterateResults<DataType>(
  url: string,
  queryParams?: Record<string, string | undefined>,
) {
  let params: Record<string, string | number> | undefined = undefined

  do {
    const res: AxiosResponse<Pagination<DataType>> = await api.get(url, {
      params: {
        ...queryParams,
        ...params,
      },
    })

    const results = res.data.items
    params = res.data.hasNextPage
      ? { page: res.data.currentPage + 1 }
      : undefined

    yield* results
  } while (params !== undefined)
}
