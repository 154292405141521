import { AutoSubmit, InputImageRadioGroup } from 'components/form'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { TbMoonStars, TbSun, TbSunMoon } from 'react-icons/tb'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'
import { SettingsSection } from './DisplaySettings'

export const ThemeSettings = () => {
  const { t } = useTranslation()
  const colorScheme = useCustomizerStore(state => state.colorScheme)
  const { toggleColorScheme } = useCustomizerActions()
  const methods = useForm({
    defaultValues: {
      colorScheme: colorScheme === null ? 'system' : colorScheme,
    },
  })

  return (
    <SettingsSection title={t('generic.theme')}>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(data =>
            toggleColorScheme(data.colorScheme),
          )}
        >
          <InputImageRadioGroup
            cols={3}
            name="colorScheme"
            items={[
              {
                icon: TbSun,
                title: t('generic.light'),
                value: 'light',
              },
              {
                icon: TbMoonStars,
                title: t('generic.dark'),
                value: 'dark',
              },
              {
                icon: TbSunMoon,
                title: t('generic.systemColorScheme'),
                value: 'system',
              },
            ]}
          />
          <AutoSubmit time={0} />
        </form>
      </FormProvider>
    </SettingsSection>
  )
}
