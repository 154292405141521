import { DateTimePicker, DateTimePickerProps } from '@mantine/dates'
import dayjs from 'dayjs'
import { Ref, forwardRef } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

type InputDatetimePickerProps = Omit<
  DateTimePickerProps,
  'value' | 'onChange'
> & {
  value?: string | undefined
  onChange?: (_val: string | undefined) => void
}

export const InputDatetimePicker = (
  props: InputDatetimePickerProps & { name: string },
) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={props.name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <DateTimePickerPure
            {...field}
            {...props}
            ref={ref}
            error={error?.message}
            icon={props.icon}
          />
        )
      }}
    />
  )
}

const DateTimePickerPure = forwardRef(
  (props: InputDatetimePickerProps, ref: Ref<HTMLButtonElement>) => {
    const { setValue } = useFormContext()
    const onChange = (value: Date | null) => {
      const valueAsISOString = value ? dayjs(value).toISOString() : undefined
      props?.onChange?.(valueAsISOString)
    }

    const value = props.value ? dayjs(props.value).toDate() : null

    return (
      <DateTimePicker
        ref={ref}
        {...props}
        value={value}
        onChange={onChange}
        clearButtonProps={{
          onClick: () => {
            onChange(null)
            setValue(props.name!, null)
          },
        }}
      />
    )
  },
)

DateTimePickerPure.displayName = 'DateTimePickerPure'
