import { z } from 'zod'

export const registerSnmpFields = z.object({
  deviceId: z.string(),
  ipAddress: z.string().ip(),
  port: z.number(),
  community: z.string(),
})

export type TRegisterSnmpFields = z.infer<typeof registerSnmpFields>

export const registerSnmpResponseSchema = z.object({
  deviceId: z.string(),
})

export type TRegisterSnmpResponse = z.infer<typeof registerSnmpResponseSchema>
