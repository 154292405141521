const prefix = ''

export const organizationUrls = {
  'organizations.list': `${prefix}/organizations`,
  'organizations.view': `${prefix}/organization`,
  'organizations.edit': `${prefix}/organization/edit`,
  'organizations.user.view': (userId: ':userId') =>
    `${prefix}/organization/users/${userId}`,
  'organizations.user.add': `${prefix}/organization/users/add`,
}
