import { z } from 'zod'

export const alertRuleGroupSchema = z.object({
  id: z.string(),
  logicalOperator: z.string(),
})

export const alertRuleGroupsSchema = z.object({
  groups: z.array(alertRuleGroupSchema),
})

export type TAlertRuleGroup = z.infer<typeof alertRuleGroupSchema>

export type TAlertRuleGroups = z.infer<typeof alertRuleGroupsSchema>
