import {
  Alert,
  Button,
  Container,
  Group,
  Text,
  Title,
  createStyles,
} from '@mantine/core'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Link, To, isRouteErrorResponse, useRouteError } from 'react-router-dom'
import { ZodError } from 'zod'

const useStyles = createStyles(
  (theme, { isRouteError }: { isRouteError: boolean }) => ({
    root: {
      paddingBlock: isRouteError ? 80 : 0,
    },

    label: {
      textAlign: 'center',
      fontWeight: 900,
      fontSize: 220,
      lineHeight: 1,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
      color:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[4]
          : theme.colors.gray[2],

      [theme.fn.smallerThan('sm')]: {
        fontSize: 120,
      },
    },

    title: {
      textAlign: 'center',
      fontWeight: 900,
      fontSize: 38,

      [theme.fn.smallerThan('sm')]: {
        fontSize: 32,
      },
    },

    description: {
      maxWidth: 500,
      margin: 'auto',
      marginTop: theme.spacing.xl,
      marginBottom: `calc(${theme.spacing.xl} * 1.5)`,
    },
  }),
)

export function ErrorView({ to }: { to?: To }) {
  const { t } = useTranslation()
  const error = useRouteError()
  const { classes } = useStyles({ isRouteError: isRouteErrorResponse(error) })

  return (
    <Container className={classes.root}>
      {isRouteErrorResponse(error) ? (
        <>
          <div className={classes.label}>{error.status}</div>

          <Title className={classes.title}>
            {t('generic.secretPlaceTitle')}
          </Title>
          <Text
            color="dimmed"
            size="lg"
            align="center"
            className={classes.description}
          >
            {error.status === 404 && t('generic.secretPlaceNote')}
            {error.status !== 404
              ? error.statusText
              : t('systemMessages.unknownErrorOccured')}
          </Text>
        </>
      ) : (
        <ErrorFallbackPure error={error} />
      )}
      {!!to && (
        <Group position="center">
          <Button component={Link} to={to} size="md">
            {t('generic.takeMeBack')}
          </Button>
        </Group>
      )}
    </Container>
  )
}

const getRouteErrorText = (t: TFunction, status?: number) => {
  switch (status) {
    case 404:
      return t('systemMessages.resourceDoesNotExist')
    case 401:
    case 403:
      return t('systemMessages.noAccessToResource')
    default:
      return t('systemMessages.unableToDetermineError')
  }
}

export const ErrorFallbackPure = ({ error }: { error: unknown }) => {
  const { t } = useTranslation()
  return (
    <Container>
      {error instanceof AxiosError ? (
        <Alert
          title={`${t('systemMessages.anErrorOccured')} (${
            error.response?.status ?? error.status
          } ${error.response?.statusText ?? null})`}
          color={'red'}
          variant="light"
        >
          {getRouteErrorText(t, error.response?.status)}
        </Alert>
      ) : error instanceof ZodError ? (
        <Alert title={t('systemMessages.unexpectedDataStructure')} color="red">
          {error.issues.map(issue => issue.message).join(', ')}
        </Alert>
      ) : error instanceof Error ? (
        <Alert title={t('systemMessages.unknownError')} color="red">
          {error.message}
        </Alert>
      ) : null}
    </Container>
  )
}
