import {
  Anchor,
  Button,
  Group,
  Notification,
  rem,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { TAlertListItem, useGetAlertList } from 'api/alerts/getAlertsList'
import { AlertLevelBadge } from 'components/devices/AlertLevelBadge'
import { r } from 'config/routes'
import { useTranslation } from 'react-i18next'
import { MdOutlineNotificationImportant } from 'react-icons/md'
import { Link } from 'react-router-dom'
import { NoDataPromptProps, QueryPaginated } from '..'
import { BiParty } from 'react-icons/bi'
import { useDayjs } from 'hooks/useDayjs'

type AlertListProps = {
  filters?: Record<string, string>
  noDataProps?: Partial<NoDataPromptProps>
}

export const AlertsList = ({ filters, noDataProps }: AlertListProps) => {
  const theme = useMantineTheme()
  const { t } = useTranslation()
  const query = useGetAlertList({ results: '5', ...filters })

  return (
    <QueryPaginated.Wrapper withPagination={false} query={query}>
      <QueryPaginated.Data>
        <Stack spacing={0}>
          {query.data?.items.map(item => (
            <AlertListItem key={item.id} {...item} />
          ))}
        </Stack>
        <Button mt="sm" compact fullWidth component={Link} to={r['alert.list']}>
          {t('generic.seeAll')}
        </Button>
      </QueryPaginated.Data>
      <QueryPaginated.NoData
        style={{
          background: theme.colorScheme === 'light' ? theme.white : undefined,
        }}
        icon={BiParty}
        title={t('alert.noData')}
        titleNoFiltered={t('alert.noData')}
        {...noDataProps}
      />
    </QueryPaginated.Wrapper>
  )
}

const AlertListItem = (data: TAlertListItem) => {
  const dayjs = useDayjs()

  return (
    <Anchor
      component={Link}
      sx={theme => ({
        '&: hover': {
          textDecoration: 'none',
        },
        boxShadow: 'none',
        borderBottom: `${rem(1)} solid ${
          theme.colorScheme === 'light'
            ? theme.colors.gray[3]
            : theme.colors.dark[3]
        }`,
        ['&:last-of-type']: {
          borderBottom: 'none',
        },
      })}
      to={r['alert.view'](data.id)}
    >
      <Notification
        color="red"
        icon={<MdOutlineNotificationImportant size="1.2rem" />}
        title={
          <Group align={'center'} position="apart">
            <Stack spacing={2}>
              {data.name}
              <Text color="dimmed">
                {dayjs(data.createdAt).format('DD.MM, HH:mm')}
              </Text>
            </Stack>
            <AlertLevelBadge level={data.level} />
          </Group>
        }
        withCloseButton={false}
        sx={theme => ({
          boxShadow: 'none',
          '&:hover': {
            backgroundColor:
              theme.colorScheme === 'dark'
                ? theme.colors.dark[5]
                : theme.colors.gray[2],
          },
        })}
      >
        {data?.device?.name}
      </Notification>
    </Anchor>
  )
}
