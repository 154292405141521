import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const UserList = lazy(() => import('../List'))
const UserSingle = lazy(() => import('../Single'))

export const UserRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['user.list'],
    element: <UserList />,
  }),
  createPanelRoute({
    path: r['user.view'](),
    element: <UserSingle />,
  }),
]
