import { z } from 'zod'

export const editUserPersonalSchema = z.object({
  phoneNumber: z.string().nullish(),
  firstName: z.string(),
  lastName: z.string(),
  CfTurnstileResponse: z.string(),
})

export type TEditUserInfoFields = z.infer<typeof editUserPersonalSchema>

export const editUserPasswordSchema = z
  .object({
    currentPassword: z.string(),
    newPassword: z.string(),
    confirmNewPassword: z.string(),
  })
  .superRefine(({ newPassword, confirmNewPassword }, ctx) => {
    if (confirmNewPassword !== newPassword) {
      ctx.addIssue({
        code: 'custom',
        message: "Passwords don't match",
        path: ['confirmNewPassword'],
      })
    }
  })

export type TEditUserPasswordFields = z.infer<typeof editUserPasswordSchema>
