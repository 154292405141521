import { TDevice, TTag } from 'shared/types'
import { api } from '..'

export const getDevicesDetails = (devices: string[]) =>
  api
    .get<{ items: (TDevice & { tags: TTag[] })[] }>(
      `/v1/devices/details?${devices
        .map((id, i) => `devices[${i}]=${id}`)
        .join('&')}`,
    )
    .then(({ data }) => new Map(data.items.map(item => [item.deviceId, item])))
