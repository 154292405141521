import { UseQueryResult } from '@tanstack/react-query'
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react'
import { Pagination } from 'shared/types'
import { ErrorFallbackPure } from '../ErrorView'
import { QueryErrorProps } from '../Query/QueryError'
import { QueryLoader } from '../Query/QueryLoader'
import { QueryPaginatedData, QueryPaginatedDataProps } from './QueryData'
import { QueryPaginatedError } from './QueryError'
import { QueryPaginatedLoader } from './QueryLoader'
import { QueryPaginatedNoData, QueryPaginatedNoDataProps } from './QueryNoData'

export type QueryPaginatedProps<T> = {
  query: UseQueryResult<Pagination<T>>
  withPagination?: boolean
}

const QueryPaginatedWrapper = <T,>({
  query,
  children,
  withPagination = true,
}: PropsWithChildren<QueryPaginatedProps<T>>) => {
  const childrenArray = Children.toArray(children)

  const loader = childrenArray.find(
    child => isValidElement(child) && child.type === QueryLoader,
  ) as ReactElement<PropsWithChildren<Partial<QueryPaginatedProps<T>>>>
  const noData = childrenArray.find(
    child => isValidElement(child) && child.type === QueryPaginatedNoData,
  ) as ReactElement<QueryPaginatedNoDataProps<T>>
  const data = childrenArray.find(
    child => isValidElement(child) && child.type === QueryPaginatedData,
  ) as ReactElement<
    PropsWithChildren<
      Partial<QueryPaginatedProps<T>> & QueryPaginatedDataProps<T>
    >
  >
  const error = childrenArray.find(
    child => isValidElement(child) && child.type === QueryPaginatedError,
  ) as ReactElement<
    PropsWithChildren<Partial<QueryPaginatedProps<T>> & QueryErrorProps>
  >

  if (!data) return null

  return (
    <>
      {isValidElement(loader) ? (
        cloneElement(loader, { ...loader.props, query })
      ) : (
        <QueryPaginatedLoader query={query} />
      )}
      {isValidElement(noData) ? (
        cloneElement(noData, { ...noData.props, query })
      ) : (
        <QueryPaginatedNoData title="No data" query={query} />
      )}
      {isValidElement(data) ? (
        cloneElement(data, { ...data.props, query, withPagination })
      ) : (
        <QueryPaginatedLoader query={query} />
      )}
      {isValidElement(error) ? (
        cloneElement(error, { ...error.props, query })
      ) : (
        <QueryPaginatedError query={query}>
          {error => <ErrorFallbackPure error={error} />}
        </QueryPaginatedError>
      )}
    </>
  )
}

export const QueryPaginated = {
  Wrapper: QueryPaginatedWrapper,
  Loader: QueryPaginatedLoader,
  NoData: QueryPaginatedNoData,
  Data: QueryPaginatedData,
  Error: QueryPaginatedError,
}
