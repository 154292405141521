import { Radio, RadioGroupProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'

interface InputRadioProps extends RadioGroupProps {
  name: string
}

export const InputRadio = ({ name, children, ...props }: InputRadioProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => {
        return (
          <Radio.Group {...field} {...props} ref={ref}>
            {children}
          </Radio.Group>
        )
      }}
    />
  )
}
