import { InputSelect, InputSelectProps } from './Input_Select'
import { SelectItem } from '@mantine/core'
import { QueryFunction, QueryKey, useQuery } from '@tanstack/react-query'

export interface InputAsyncSelectProps extends Omit<InputSelectProps, 'data'> {
  queryKey: QueryKey
  queryFn: QueryFunction<SelectItem[], QueryKey>
}

export const InputAsyncSelect = ({
  queryKey,
  queryFn,
  required,
  ...props
}: InputAsyncSelectProps) => {
  const { data, isLoading } = useQuery({
    queryKey,
    queryFn,
    enabled: !props.disabled,
  })

  const items = data ?? []

  return (
    <InputSelect
      {...props}
      required={required}
      data={items}
      disabled={props.disabled || isLoading}
    />
  )
}
