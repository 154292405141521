import { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { InputSelect } from './Input_Select'

interface IInputBoolean {
  name: string
  icon?: IconType
  placeholder?: string
  label?: string
  style?: CSSProperties
}

const InputBoolean = ({
  name,
  icon,
  placeholder,
  label,
  style,
}: IInputBoolean) => {
  const { t } = useTranslation()
  return (
    <InputSelect
      name={name}
      label={label}
      placeholder={placeholder}
      data={[
        {
          value: true as unknown as string,
          label: t('generic.yes'),
        },
        {
          value: false as unknown as string,
          label: t('generic.no'),
        },
      ]}
      clearable
      style={style}
      icon={icon}
    />
  )
}

export default InputBoolean
