import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import { TOrganizationsList } from 'shared/types'
import { api } from '..'

export const getOrganizations = (params?: Record<string, string>) =>
  api
    .get<TOrganizationsList>('/v1/organizations', { params })
    .then(({ data }) => {
      return data
    })

export const getOrganizationsQuery = (params?: Record<string, string>) => ({
  queryKey: ['emails.list', params],
  queryFn: () => getOrganizations(params),
})

export const useGetOrganizations = () =>
  usePaginatedQuery(getOrganizationsQuery)

export const organizationsListHelper = {
  queryFn: () =>
    getOrganizations({ results: '100' }).then(data =>
      data?.items?.map?.(({ id, name }) => ({
        value: id,
        label: name,
      })),
    ),
  queryKey: ['organizations', 'helper'],
}
