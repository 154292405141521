import { SelectItem } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { InputAsyncSelectProps } from './Input_AsyncSelect'
import { InputSelect } from './Input_Select'

type InputAsyncSearchSelectProps = {
  searchKey?: string
  debounceTime?: number
  queryFn: (params: Record<string, string>) => Promise<SelectItem[]>
} & Omit<InputAsyncSelectProps, 'queryFn'>

export const InputAsyncSearchSelect = ({
  queryKey,
  queryFn,
  required,
  searchKey = 'search',
  debounceTime = 500,
  ...props
}: InputAsyncSearchSelectProps) => {
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, debounceTime)
  const { data, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => queryFn({ [searchKey]: pageParam }),
    enabled: !props.disabled,
  })

  useEffect(() => {
    if (!debouncedSearch || props.disabled) return

    fetchNextPage({ pageParam: debouncedSearch })
  }, [debouncedSearch])

  const items = useMemo(
    () =>
      data?.pages
        ? [
            ...new Map(
              data.pages
                .flatMap(page => page)
                .map(item => [item['value'], item]),
            ).values(),
          ]
        : [],
    [data],
  )

  return (
    <InputSelect
      {...props}
      required={required}
      data={items}
      disabled={props.disabled || isLoading}
      onSearchChange={setSearch}
    />
  )
}
