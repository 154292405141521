import { HubConnectionState } from '@microsoft/signalr'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { useConnection } from 'components/common/SignalRProvider'
import dayjs from 'dayjs'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { TMeasurementItem, TMeasurementsChart } from 'shared/types'
import { transformMeasurements } from 'utils/transformMeasurements'
import { z } from 'zod'
import { api } from '..'
import { lastMeasurementQueryKey } from './getLastMeasurement'

export const formatValues = (
  formatter: (v: string) => string,
  params?: Record<string, string>,
) =>
  params
    ? Object.fromEntries(
        Object.entries(params).map(([k, v]) => [k, formatter(v)]),
      )
    : params

export const measurementsChartQueryKey = ({
  deviceId,
  indicatorCode,
  params,
}: {
  deviceId: string
  indicatorCode: string
  params?: Record<string, string>
}) => [
  'measurements.chart',
  deviceId,
  indicatorCode,
  formatValues(v => dayjs(v).format('DD.MM.YYYY, HH:mm'), params),
]

export const getChartMeasurements = (
  deviceId: string,
  indicatorCode: string,
  params?: Record<string, string>,
) =>
  api
    .get<TMeasurementsChart>('/v1/measurements/chart', {
      params: {
        deviceId,
        indicatorCode,
        ...params,
      },
    })
    .then(({ data }) =>
      data && data.measurements ? transformMeasurements(data.measurements) : [],
    )

export const measurementChartQuery = (
  deviceId: string,
  indicatorCode: string,
  params?: Record<string, string>,
) => ({
  queryFn: () => getChartMeasurements(deviceId, indicatorCode, params),
  queryKey: measurementsChartQueryKey({ deviceId, indicatorCode, params }),
})

export const useGetChartMeasurements = (
  deviceId?: string,
  indicatorCode?: string,
  manualParams?: { fromDate: string; toDate: string },
) => {
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())

  if (!deviceId || !indicatorCode)
    throw new Error('Missing deviceId or indicatorCode')

  const query = measurementChartQuery(
    deviceId,
    indicatorCode,
    manualParams ?? params,
  )

  useSubscribeToChartMeasurements()

  return useQuery({
    ...query,
    keepPreviousData: true,
    useErrorBoundary: false,
    // staleTime: 1000 * 5 * 60,
  })
}

const useSubscribeToChartMeasurements = () => {
  const queryClient = useQueryClient()
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())
  const paramsHash = JSON.stringify(params)

  const connection = useConnection()

  useEffect(() => {
    if (connection.state !== HubConnectionState.Connected) return

    connection.on('ReceiveMeasurement', (_device: string, message: unknown) => {
      const newMeasurement = getMeasurementItem(message)

      if (
        params?.toDate === undefined ||
        dayjs(newMeasurement.date).diff(dayjs(params.toDate), 'minutes') < 30
      )
        // Update chart only when user isn't zoomed out for more than 30 minutes distance from now
        queryClient.setQueryData<TMeasurementItem[]>(
          measurementsChartQueryKey({
            deviceId: newMeasurement.deviceId,
            indicatorCode: newMeasurement.indicator!.code,
            params,
          }),
          old => (old ? [newMeasurement, ...old] : [newMeasurement]),
        )

      queryClient.setQueryData<TMeasurementItem>(
        lastMeasurementQueryKey({
          deviceId: newMeasurement.deviceId,
          indicatorCode: newMeasurement.indicator!.code,
        }),
        old => ({ ...newMeasurement, unit: old?.unit ?? newMeasurement.unit }),
      )
    })

    return () => {
      connection.off('ReceiveMeasurement')
    }
  }, [connection.state, paramsHash, queryClient])
}

const receiveMeasurementSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  packetId: z.string(),
  value: z.number(),
  date: z.string(),
  indicator: z.object({
    code: z.string(),
    name: z.string().nullable(),
    unit: z.string().nullable(),
    category: z.string().nullable(),
  }),
})

const getMeasurementItem = (
  receiveMeasurementMessage: unknown,
): TMeasurementItem => {
  const { indicator, date, id, packetId, value, deviceId } =
    receiveMeasurementSchema.parse(receiveMeasurementMessage)

  const newMeasurement = {
    id,
    date,
    deviceId,
    packetId,
    value,
    indicator: {
      code: indicator.code,
      name: indicator.code,
    },
    unit: {
      name: indicator.unit ?? '',
      quantity: '',
      symbol: '',
    },
  }

  return newMeasurement
}
