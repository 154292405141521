import { closeAllModals, closeModal, openModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { GenericAbortSignal } from 'axios'
import { useCallback } from 'react'
import {
  dontAskAgainKey,
  NotificationRequestModal,
  notificationRequestModalId,
} from './NotificationRequestModal'
import { setupFirebase } from './setupFirebase'

const notificationsSupported = () =>
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window

export const useFirebase = () => {
  const dontAskAgain = window.localStorage.getItem(dontAskAgainKey) === 'true'
  const onAllow = useCallback(async (signal?: GenericAbortSignal) => {
    try {
      const permission = await Notification.requestPermission()
      if (permission !== 'granted')
        throw new Error(
          'You did not grant permission to receive notifications.',
        )

      closeAllModals()
      await setupFirebase(signal)
    } catch (error) {
      console.dir(error)
      if (error instanceof Error)
        showNotification({
          color: 'red',
          message: error.message,
        })
      closeModal(notificationRequestModalId)
    }
  }, [])

  const checkNotificationsState = useCallback(
    (signal?: GenericAbortSignal) => {
      if (!notificationsSupported()) return

      if (Notification.permission === 'granted') setupFirebase(signal)
      if (Notification.permission === 'default' && !dontAskAgain)
        openModal({
          size: 'lg',
          children: (
            <NotificationRequestModal onAllow={() => onAllow(signal)} />
          ),
          modalId: notificationRequestModalId,
        })

      if (Notification.permission === 'denied' && !dontAskAgain) {
        showNotification({
          color: 'orange',
          message:
            'You have denied permission to notifications. You will have to enable them manually by clicking on the icon to the left of the address bar.',
        })
      }
    },
    [dontAskAgain],
  )

  return checkNotificationsState
}
