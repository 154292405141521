import { createStyles, Group, Menu } from '@mantine/core'
import { openModal } from '@mantine/modals'
import { AlertsDropdown } from 'components/alerts/AlertsDropdown'
import { r } from 'config/routes'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import {
  TbBuilding,
  TbLogout,
  TbPlaylistAdd,
  TbSettings,
  TbUser,
} from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { UserButtonMenu, UserButtonProps } from '../common'
import { DisplaySettings } from './displaySettings/DisplaySettings'
import { SearchSpotlight } from './SearchSpotlight'
import { SidebarHamburger } from './VerticalSidebar'

const useStyles = createStyles(theme => ({
  header: {
    backgroundColor:
      theme.colorScheme === 'dark' ? theme.colors.dark[6] : '#fff',
    boxShadow: '0 0 15px #0000000d',
  },
}))

export const openDisplaySettings = (t: TFunction) => {
  openModal({
    title: t('generic.appSettings'),
    children: <DisplaySettings />,
    size: 'lg',
  })
}

export const UserHeader = ({
  user,
  onLogout,
}: {
  user: UserButtonProps
  onLogout: () => void
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Group
      className={classes.header}
      spacing={'lg'}
      position="right"
      align={'center'}
      px="sm"
    >
      <SidebarHamburger style={{ marginRight: 'auto' }} />
      <SearchSpotlight />
      <AlertsDropdown />
      <UserButtonMenu {...user}>
        <Menu.Dropdown>
          <Menu.Item
            data-testid={'user-dropdown-register-device'}
            component={Link}
            to={r['devices.register']}
            icon={<TbPlaylistAdd size={14} />}
          >
            {t('sensor.register')}
          </Menu.Item>
          <Menu.Item
            component={Link}
            to={r['organizations.view']}
            icon={<TbBuilding size={14} />}
          >
            {t('organization.single')}
          </Menu.Item>
          <Menu.Item
            onClick={() => openDisplaySettings(t)}
            icon={<TbSettings size={14} />}
          >
            {t('generic.appSettings')}
          </Menu.Item>
          <Menu.Label>{t('user.account')}</Menu.Label>
          <Menu.Item
            component={Link}
            to={r['account']('info')}
            icon={<TbUser size={14} />}
          >
            {t('user.accountSettings')}
          </Menu.Item>
          <Menu.Item
            data-testid={'user-dropdown-logout'}
            onClick={onLogout}
            icon={<TbLogout size={14} />}
          >
            {t('user.logout')}
          </Menu.Item>
        </Menu.Dropdown>
      </UserButtonMenu>
    </Group>
  )
}
