import { ScrollArea, Stack } from '@mantine/core'
import { useGetDevice } from 'api/devices'
import { Query } from 'components/common'
import { DeviceCard } from 'components/devices'
import { useEffect } from 'react'
import { useMap } from 'react-leaflet'
import { useParams } from 'react-router-dom'
import { TDevice } from 'shared/types'
import { DeviceIndicatorsList } from 'views/panel/Locations/Map/components/DeviceIndicatorsList'

export const DeviceMapView = () => {
  const { deviceId } = useParams()
  const query = useGetDevice(deviceId!)
  const map = useMap()

  useEffect(() => {
    if (!query?.data?.localization) return
    map.flyTo(
      {
        lat: query?.data?.localization?.latitude,
        lng: query?.data?.localization?.longitude,
      },
      22,
    )
  }, [query?.data, map])

  return (
    <Query.Wrapper query={query}>
      <Query.Data>
        {(data: TDevice) => (
          <ScrollArea type={'hover'} h={'calc(100dvh - 100px)'}>
            <Stack>
              <DeviceCard
                wrapperProps={{ style: { boxShadow: 'none' }, p: 0 }}
                data={data}
                link
              />
              {data.type === 'LoraDevice' && (
                <DeviceIndicatorsList
                  name={data?.name}
                  deviceId={data.deviceId}
                  deviceModelId={data.modelId}
                />
              )}
            </Stack>
          </ScrollArea>
        )}
      </Query.Data>
    </Query.Wrapper>
  )
}
