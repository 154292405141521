import { setupAxios } from 'api/axios'
import { Providers } from 'config/Providers'
import 'config/i18n'
import 'leaflet/dist/leaflet.css'
import Router from 'views/Router'

setupAxios()
function App() {
  return (
    <Providers>
      <Router />
    </Providers>
  )
}

export default App
