import { z } from 'zod'

export const packetSchema = z.object({
  id: z.string(),
  rawPacketId: z.string(),
  gatewayId: z.string(),
  gatewayEU: z.string(),
  deviceId: z.string(),
  devAdr: z.string().nullable(),
  tmst: z.number(),
  time: z.string(),
  freq: z.number(),
  datr: z.string(),
  codr: z.string(),
  lsnr: z.number(),
  rssi: z.number(),
  fCnt: z.string(),
  data: z.string(),
})
