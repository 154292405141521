import { useQuery } from '@tanstack/react-query'
import { paginationSchema } from 'utils/paginationSchema'
import { z } from 'zod'
import { api } from '..'

export const downlinkPresetListItemSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  createdAt: z.string(),
  port: z.number(),
  payload: z.string(),
  title: z.string(),
  insertMode: z.number(),
})

export const messagingPresetsQueryKey = (deviceModelId: string) => [
  'downlink',
  deviceModelId,
  'presets',
]

export type TDownlinkPresets = Awaited<ReturnType<typeof getDownlinkPresets>>
export type TDownlinkPresetListItem = TDownlinkPresets[number]
const getDownlinkPresets = (deviceModelId: string) =>
  api
    .get(`/v1/lorawan/devices/${deviceModelId}/messages/templates`, {
      params: { results: 100 },
    })
    .then(
      ({ data }) =>
        paginationSchema(downlinkPresetListItemSchema).parse(data).items,
    )

export const useGetPresets = (deviceModelId: string) =>
  useQuery({
    queryKey: messagingPresetsQueryKey(deviceModelId),
    queryFn: () => getDownlinkPresets(deviceModelId),
  })
