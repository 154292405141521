import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const GatewayList = lazy(() => import('../List'))
const SingleGateway = lazy(() => import('../Single'))
const EditGateway = lazy(() => import('../Edit'))

export const gatewayRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['gateways.list'],
    element: <GatewayList />,
  }),
  createPanelRoute({
    path: r['gateways.view'](),
    element: <SingleGateway />,
  }),
  createPanelRoute({
    path: r['gateways.edit'](),
    element: <EditGateway />,
  }),
]
