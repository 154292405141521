import { TAvailableDeviceTabs } from 'components/index'

const prefix = ''

export const loraDevicesUrls = {
  'loraDevices.list': `${prefix}/lora-devices`,
  'loraDevices.view': (
    deviceId = ':deviceId',
    tab: TAvailableDeviceTabs | ':tabValue' = ':tabValue',
  ) => `${prefix}/lora-devices/${deviceId}/${tab}`,
  'loraDevices.edit': (deviceId = ':deviceId', tab = ':tabValue') =>
    `${prefix}/lora-devices/${deviceId}/edit/${tab}`,
  'loraDevices.dashboard': (
    deviceId = ':deviceId',
    dashboardId = ':dashboardId',
  ) => `${prefix}/devices/${deviceId}/dashboards/${dashboardId}`,
}
