import {
  createStyles,
  Radio,
  RadioProps,
  SimpleGrid,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { IconType } from 'react-icons'

const useStyles = createStyles((theme, { checked }: { checked: boolean }) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    transition: 'background-color 150ms ease, border-color 150ms ease',
    border: `1px solid ${
      checked
        ? theme.fn.variant({ variant: 'outline', color: theme.primaryColor })
            .border
        : theme.colorScheme === 'dark'
        ? theme.colors.dark[8]
        : theme.colors.gray[3]
    }`,
    borderRadius: theme.radius.sm,
    padding: theme.spacing.sm,
    backgroundColor: checked
      ? theme.fn.variant({ variant: 'light', color: theme.primaryColor })
          .background
      : theme.colorScheme === 'dark'
      ? theme.colors.dark[8]
      : theme.white,
    cursor: 'pointer',
  },

  body: {
    flex: 1,
    marginLeft: theme.spacing.md,
  },
}))

type ImageCheckboxProps = Omit<
  RadioProps,
  'onChange' | 'name' | 'value' | 'icon'
> & {
  title: string
  description?: string
  icon: IconType
  value: string
  currentValue?: string
  onChange: (...e: unknown[]) => void
}

export function ImageRadio({
  title,
  description,
  className,
  icon: Icon,
  value,
  currentValue,
  onChange,
  ...others
}: ImageCheckboxProps) {
  const checked = currentValue === value
  const { classes, cx, theme } = useStyles({ checked: !!checked })

  return (
    <UnstyledButton
      data-testid={`input-radio-${value}`}
      onClick={() =>
        onChange({
          target: {
            value,
          },
        })
      }
      className={cx(classes.button, className)}
    >
      <Icon color={theme.colors.brand[5]} size={'2rem'} />

      <div className={classes.body}>
        {description && (
          <Text color="dimmed" size="xs" sx={{ lineHeight: 1 }} mb={5}>
            {description}
          </Text>
        )}
        <Text weight={500} size="sm" sx={{ lineHeight: 1 }}>
          {title}
        </Text>
      </div>

      <Radio value={value} checked={checked} {...others} />
    </UnstyledButton>
  )
}

type ImageRadioGroupProps = {
  items: Omit<ImageCheckboxProps, 'name' | 'onChange'>[]
  name: string
  cols?: number
}

export const InputImageRadioGroup = ({
  name,
  items,
  cols = 2,
}: ImageRadioGroupProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => {
        return (
          <Radio.Group {...field} ref={ref}>
            <SimpleGrid
              sx={{ width: '100%' }}
              cols={cols}
              breakpoints={[{ maxWidth: 'md', cols: 1 }]}
            >
              {items.map(deviceType => (
                <ImageRadio
                  {...deviceType}
                  currentValue={field.value}
                  onChange={field.onChange}
                  key={deviceType.value}
                />
              ))}
            </SimpleGrid>
          </Radio.Group>
        )
      }}
    />
  )
}
