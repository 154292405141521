import { TFunction } from 'i18next'

const downlinkInsertModesArr = (t: TFunction) => [
  // {
  //   value: 'Unresolved',
  //   label: 'Push',
  // },
  {
    value: 1,
    label: 'Push',
  },
  {
    value: 2,
    label: 'Replace',
  },
]

export const downlinkInsertModes = (t: TFunction) =>
  new Map(
    downlinkInsertModesArr(t).map(({ value, ...rest }) => {
      return [value, rest]
    }),
  )
