import { z } from 'zod'

export const editOrganizationSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  primaryColor: z.string().nullish(),
  sidebarColor: z.string().nullish(),
})

export type TEditOrganizationFields = z.infer<typeof editOrganizationSchema>
