import { PropsWithChildren } from 'react'
import { NoDataPrompt, NoDataPromptProps } from '../NoDataPrompt'
import { QueryProps } from './Query'

export type QueryNoDataProps<T> = PropsWithChildren<
  Partial<QueryProps<T>> & NoDataPromptProps
>

export const QueryNoData = <T,>({
  query,
  children,
  disableNoDataComponent,
  ...props
}: QueryNoDataProps<T>) => {
  if (disableNoDataComponent) return null
  if (!query) throw new Error('Missing query data')
  if (
    query.data === null ||
    (query.data && Array.isArray(query.data) && query.data.length === 0)
  )
    return children ? <>{children}</> : <NoDataPrompt {...props} />

  return null
}
