import { ActionIcon, Badge, BadgeProps, Flex } from '@mantine/core'
import { TbX } from 'react-icons/tb'
import { useRemoveTagMutation } from './hooks/useRemoveTagMutation'

type TagBadgeProps = BadgeProps & {
  value: string
  label?: string
  deviceId: string
}

export const TagBadge = ({
  value,
  label,
  color = 'brand',
  deviceId,
}: TagBadgeProps) => {
  const removeMutation = useRemoveTagMutation({ deviceId })

  return (
    <Badge
      sx={theme => ({
        border: `1px solid ${theme.colors[color][6]}`,
      })}
      mr={'xs'}
      pr={2}
    >
      <Flex>
        {label ?? value}
        <ActionIcon
          onClick={() => removeMutation.mutate(value)}
          size="xs"
          radius="xl"
          variant="transparent"
          ml="xs"
        >
          <TbX size={10} />
        </ActionIcon>
      </Flex>
    </Badge>
  )
}
