import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const LoraDeviceList = lazy(() => import('../LoRaDevice/List'))
const LoraDeviceAdd = lazy(() => import('../LoRaDevice/Add'))
const LoraDeviceView = lazy(() => import('../LoRaDevice/Single'))

const LoraGatewayList = lazy(() => import('../LoRaGateway/List'))
const LoraGatewayAdd = lazy(() => import('../LoRaGateway/Add'))
const LoraGatewayView = lazy(() => import('../LoRaGateway/Single'))

export const qrCodesRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['qrCodes.loraDevices.list'],
    element: <LoraDeviceList />,
  }),
  createPanelRoute({
    path: r['qrCodes.loraDevices.add'],
    element: <LoraDeviceAdd />,
  }),
  createPanelRoute({
    path: r['qrCodes.loraDevices.view'](),
    element: <LoraDeviceView />,
  }),

  createPanelRoute({
    path: r['qrCodes.gateways.list'],
    element: <LoraGatewayList />,
  }),
  createPanelRoute({
    path: r['qrCodes.gateways.add'],
    element: <LoraGatewayAdd />,
  }),
  createPanelRoute({
    path: r['qrCodes.gateways.view'](),
    element: <LoraGatewayView />,
  }),
]
