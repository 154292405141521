import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { TRANSLATIONS_API_URL } from './env'

import { zodI18nMap } from 'zod-i18n-map'
import zodPL from 'zod-i18n-map/locales/pl/zod.json'
import zodEN from 'zod-i18n-map/locales/en/zod.json'
import { z } from 'zod'

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en-GB',
    debug: import.meta.env.DEV,
    load: 'currentOnly',
    detection: {
      order: ['queryString', 'localStorage', 'cookie'],
      caches: ['localStorage', 'cookie'],
      lookupCookie: 'connectedlife.i18next',
      lookupLocalStorage: 'connectedlife.i18nextLng',
    },
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: `${TRANSLATIONS_API_URL}/v1/translations/translations/{{lng}}.json`,
    },
  })
i18n.addResourceBundle('pl-PL', 'zod', zodPL)
i18n.addResourceBundle('en-GB', 'zod', zodEN)

z.setErrorMap(zodI18nMap)
