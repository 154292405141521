import { useEffect, useRef } from 'react'
import { useFormContext } from 'react-hook-form'

export const AutoSubmit = ({
  time = 500,
  submitWhenAllFilled = false,
}: {
  time?: number
  submitWhenAllFilled?: boolean
}) => {
  const { watch } = useFormContext()
  const submitRef = useRef<HTMLButtonElement>(null)
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>()

  useEffect(() => {
    const subscription = watch(data => {
      clearTimeout(timeoutRef?.current)
      const values = Object.values(data)
      const filledValues = values.filter(Boolean)
      if (submitWhenAllFilled && values.length !== filledValues.length) return
      timeoutRef.current = setTimeout(() => {
        submitRef?.current?.click()
      }, time)
    })

    return () => {
      subscription.unsubscribe()
      clearTimeout(timeoutRef?.current)
    }
  }, [watch])

  return <button type="submit" hidden ref={submitRef} />
}
