import { Pagination } from '../helper'
import { z } from 'zod'

export const chartLabelListItemSchema = z.object({
  chartLabelId: z.string(),
  deviceId: z.string(),
  indicatorCode: z.string(),
  dateFrom: z.string(),
  dateTo: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  hexColor: z.string(),
})

export type TChartLabelListItem = z.infer<typeof chartLabelListItemSchema>

export type TChartLabelList = Pagination<TChartLabelListItem>
