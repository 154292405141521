import { closeAllModals } from '@mantine/modals'
import { useQueryClient } from '@tanstack/react-query'
import {
  createDownlinkPreset,
  createDownlinkPresetSchema,
} from 'api/loraDevices/createDownlinkPreset'
import { messagingPresetsQueryKey } from 'api/loraDevices/getDownlinkPresets'
import { useFormMutation } from 'hooks/useFormMutation'

export const useCreateDownlinkPreset = (
  deviceModelId: string,
  fPort?: number,
  payload?: string,
  insertMode?: number,
) => {
  const queryClient = useQueryClient()

  const mutation = useFormMutation({
    mutationFn: createDownlinkPreset(deviceModelId!),
    schema: createDownlinkPresetSchema,
    onSuccess: (data, variables) => {
      // const newItem: TDownlinkPresetListItem = {
      //   id: data.id,
      //   createdAt: new Date().toISOString(),
      //   deviceId,
      //   insertMode: variables.insertMode,
      //   payload: variables.payload,
      //   port: variables.fPort,
      //   title: variables.title,
      // }

      // queryClient.setQueryData<TDownlinkPresets>(
      //   messagingPresetsQueryKey(deviceId!),
      //   old => (old ? {...old, items: [newItem, ...old.items]} : {...old, items: [newItem]}),
      // )
      queryClient.invalidateQueries(messagingPresetsQueryKey(deviceModelId!))
      closeAllModals()
    },
    initial: {
      fPort,
      payload,
      insertMode: insertMode?.toString?.() as unknown as number,
    },
  })

  return mutation
}
