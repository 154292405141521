import { z } from 'zod'

export const createLocationSchema = z.object({
  name: z.string(),
  description: z.string().max(100).nullish(),
})

export type TLocationGeneralInfo = z.infer<typeof createLocationSchema>

export const locationBoundariesSchema = z.object({
  boundaries: z.array(
    z.object({
      latitude: z.number(),
      longitude: z.number(),
    }),
  ),
})

export type TLocationBoundaries = z.infer<typeof locationBoundariesSchema>

export const createLocationResponseSchema = z.object({
  locationId: z.string(),
})

export type TCreateLocationResponse = z.infer<
  typeof createLocationResponseSchema
>
