import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'
import { ZodSchema } from 'zod'

type FiltersFields = Record<string, string>

export const useFilterForm = (schema: ZodSchema) => {
  const [params, setParams] = useSearchParams()
  const queryParams = Object.fromEntries(params.entries())

  for (const [key] of Object.entries(queryParams)) {
    const paginationKeys = ['page', 'results']
    if (paginationKeys.includes(key)) delete queryParams[key]
  }

  const methods = useForm<FiltersFields>({
    defaultValues: { ...schema.default, ...queryParams },
    resolver: zodResolver(schema),
  })

  const onSubmit = methods.handleSubmit((data: FiltersFields) => {
    //get all queryParams which are not included in filters form like: Page, PerPage
    const queryParams = Object.fromEntries(params.entries())

    //remove empty filters from url old filters params
    for (const [key, value] of Object.entries(data)) {
      if (value === undefined || value === null || value === '')
        delete data[key as keyof FiltersFields]
      delete queryParams[key]
    }

    //set queryParams and overwrite them by filters form data
    setParams({ ...queryParams, ...data, page: '1' })
  })

  return { onSubmit, methods }
}
