import { z } from 'zod'

export const organizationMemberSchema = z.object({
  memberId: z.string(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  state: z.enum(['active', 'locked']),
  role: z.string(),
  createdAt: z.string(),
})

export const organizationMembersSchema = z.object({
  members: z.array(organizationMemberSchema),
})

export type TOrganizationMembers = z.infer<typeof organizationMembersSchema>

export type TOrganizationMember = z.infer<typeof organizationMemberSchema>
