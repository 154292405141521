import { z } from 'zod'

export const EditDeviceSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  provider: z.string().optional(),
  modelId: z.string().optional(),
  networkProvider: z
    .number()
    .transform(val => Number(val))
    .optional(),
})

export type TEditDeviceFields = z.infer<typeof EditDeviceSchema>
