import { z } from 'zod'

export const deviceModelsResponseSchema = z.object({
  items: z.array(
    z.object({
      name: z.string(),
    }),
  ),
})

export type TDeviceModelsResponse = z.infer<typeof deviceModelsResponseSchema>
