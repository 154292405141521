import { z } from 'zod'
import { Pagination } from '../helper'

const photoObjectSchema = z.object({
  id: z.string(),
  url: z.string(),
})

export const organizationOwnerSchema = z.object({
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  phoneNumber: z.string(),
  createdAt: z.string(),
})

export const organizationSchema = z.object({
  id: z.string(),
  organizationId: z.string(),
  ownerDetail: organizationOwnerSchema,
  name: z.string(),
  description: z.string(),
  primaryColor: z.string().nullable(),
  sidebarColor: z.string().nullable(),
  textLogo: photoObjectSchema.nullable(),
  textLogoId: z.string().nullable(),
  logo: photoObjectSchema.nullable(),
  logoId: z.string().nullable(),
})

export type TPhotoObject = z.infer<typeof photoObjectSchema>

export type TOrganization = z.infer<typeof organizationSchema>

export type TOrganizationOwner = z.infer<typeof organizationOwnerSchema>

export type TOrganizationsList = Pagination<
  Pick<TOrganization, 'name' | 'description' | 'id'>
>
