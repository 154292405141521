import { createStyles } from '@mantine/core'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'

const useStyles = createStyles({
  button: {
    all: 'unset',
    cursor: 'pointer',
  },
})

export const NavbarToggle = () => {
  const { classes, cx } = useStyles()
  const sidebarCollapsed = useCustomizerStore(state => state.sidebarCollapsed)
  const { toggleSidebarCollapse } = useCustomizerActions()

  return (
    <button
      onClick={() => toggleSidebarCollapse()}
      className={cx(classes.button, 'nav-toggle')}
    >
      <svg
        stroke="currentColor"
        fill="none"
        strokeWidth="0"
        viewBox="0 0 24 24"
        height="1.5rem"
        width="1.5rem"
        xmlns="http://www.w3.org/2000/svg"
      >
        {!sidebarCollapsed && (
          <path
            d="M12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"
            fill="currentColor"
          ></path>
        )}
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
          fill="currentColor"
        ></path>
      </svg>
    </button>
  )
}
