import { z } from 'zod'
import { Pagination } from '../helper'

export const gatewayListItemSchema = z.object({
  gatewayId: z.string(),
  eui: z.string(),
  isOnline: z.boolean(),
  deviceGroups: z
    .array(
      z.object({
        id: z.string().nullable(),
        name: z.string().nullable(),
        description: z.string().nullable(),
        locationId: z.string().nullable(),
        countDevice: z.number().nullable(),
      }),
    )
    .nullable(),
})

export type TGatewayListItem = z.infer<typeof gatewayListItemSchema>

export type TGatewayList = Pagination<TGatewayListItem>
