import { api } from '../..'
import { z } from 'zod'

export const assignDashboardToDeviceSchema = z.object({
  dashboardsId: z.array(z.string()),
})
export type TAssignDashboardToDeviceFields = z.infer<
  typeof assignDashboardToDeviceSchema
>

export const assignDashboardToDevice = (
  { deviceId }: { deviceId: string },
  data: TAssignDashboardToDeviceFields,
) => api.post(`/v1/dashboards/devices/${deviceId}/dashboards`, data)
