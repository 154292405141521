import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const OidsList = lazy(() => import('../List'))
const CreateOid = lazy(() => import('../Create'))

export const snmpOidsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['oids.list'],
    element: <OidsList />,
  }),
  createPanelRoute({
    path: r['oids.add'],
    element: <CreateOid />,
  }),
]
