import { z } from 'zod'

export const availableRoles = ['ActiveMember', 'PassiveMember'] as const

const organizationMemberRolesArray = (t: (key: string) => string) => [
  {
    value: 'ActiveMember',
    label: t(`role.ActiveMember`),
    description: t(`role.ActiveMemberDescription`),
  },
  {
    value: 'PassiveMember',
    label: t(`role.PassiveMember`),
    description: t(`role.PassiveMemberDescription`),
  },
]

export const organizationMemberRolesOptions = (t: (key: string) => string) =>
  organizationMemberRolesArray(t).map(({ value, label, description }) => ({
    value: value.toString(),
    label,
    description,
  }))

export const RoleEnum = z.enum(availableRoles)
export type TRole = z.infer<typeof RoleEnum>

export const changeRoleSchema = z.object({
  role: RoleEnum,
})

export type TChangeRoleFields = z.infer<typeof changeRoleSchema>
