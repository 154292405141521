import { ColorInput, ColorInputProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'

export type ColorPickerProps = {
  name: string
} & Omit<ColorInputProps, 'error'>

export const InputColor = ({
  name,
  label,
  placeholder,
  ...props
}: ColorPickerProps) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        return (
          <ColorInput
            {...field}
            {...props}
            error={error?.message}
            placeholder={placeholder}
            label={label}
          />
        )
      }}
    />
  )
}
