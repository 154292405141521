import { z } from 'zod'

export const localizationSchema = z.object({
  latitude: z.number().min(-90).max(90).nullish(),
  longitude: z.number().min(-180).max(180).nullish(),
  altitude: z
    .number()
    .nullish()
    .transform(val => (val === null ? undefined : val)),
  locationId: z.string().nullish(),
})

export type TLocalization = z.infer<typeof localizationSchema>
