import styled from '@emotion/styled'
import { createStyles } from '@mantine/core'
import { useDayjs } from 'hooks/useDayjs'
import { MouseEventHandler } from 'react'
import { useNavigate } from 'react-router-dom'
import { TDatatable } from '.'

const hasName = (value: unknown): value is { name: string } => {
  return typeof value === 'object' && value !== null && 'name' in value
}

const useRowStyles = createStyles(
  (theme, { hasClickEvent }: { hasClickEvent: boolean }) => ({
    tr: {
      borderBottom: `1px solid ${
        theme.colorScheme === 'light'
          ? theme.colors.gray[2]
          : theme.colors.dark[7]
      }`,
      position: 'relative',
      cursor: hasClickEvent ? 'pointer' : 'default',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      borderRadius: theme.radius.lg,

      [`@media (min-width: ${theme.breakpoints.lg})`]: {
        display: 'table-row',
      },

      '&:hover': {
        backgroundColor: hasClickEvent
          ? theme.colorScheme === 'light'
            ? theme.colors.gray[1]
            : theme.colors.dark[5]
          : undefined,
      },
    },
    mobileLabel: {
      all: 'unset',
      display: 'block',
      opacity: 0.7,
      [`@media (min-width: ${theme.breakpoints.lg})`]: {
        display: 'none',
      },
    },
  }),
)

export type DatatableRowProps<TItem> = {
  headers?: TDatatable<TItem>[]
  exclude?: string[]
  href?: (_data: TItem) => string
  onClick?: (_data: TItem) => void
  value: TItem
} & Partial<Omit<HTMLTableRowElement, 'children'>>

export const DatatableRow = <TItem,>({
  value,
  headers,
  href,
  onClick,
  className,
}: DatatableRowProps<TItem>) => {
  const navigate = useNavigate()
  const dayjs = useDayjs()
  const { classes, cx } = useRowStyles({ hasClickEvent: !!href || !!onClick })

  if (!headers) throw new Error('No datatable headers present')

  const handleRowClick: MouseEventHandler<HTMLTableRowElement> = e => {
    if (!href) return
    if (e.button === 1) {
      window.open(href(value), '_blank')
      return
    }
  }

  return (
    <tr
      data-testid={`datatable-row-${hasName(value) ? value.name : ''}`}
      onAuxClick={handleRowClick}
      onClick={() => (onClick ? onClick(value) : href && navigate(href(value)))}
      className={cx(classes.tr, className)}
    >
      {headers.map((header, i) => (
        <Td
          mobileGrow={headers[i].mobileGrow}
          mobileHidden={headers[i].mobileHidden}
          key={i}
        >
          <button className={classes.mobileLabel}>{headers[i]?.label}</button>
          {header.selector(value, { dayjs })}
        </Td>
      ))}
    </tr>
  )
}

type TdProps = {
  mobileHidden?: boolean
  mobileGrow?: boolean
}

const Td = styled.td<TdProps>`
  display: ${props => (props.mobileHidden ? 'none' : 'block')};
  flex-grow: ${props => (props.mobileGrow ? 1 : 0)};
  width: ${props => (props.mobileGrow ? '100%' : 'auto')};
  padding: ${({ theme }) => theme.spacing.sm};
  font-size: 0.9rem;
  font-weight: 500;

  @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
    display: table-cell;
    vertical-align: middle;
  }
`
