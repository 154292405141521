const prefix = ''

export const qrCodesUrls = {
  'qrCodes.loraDevices.list': `${prefix}/lora-devices/qr-codes`,
  'qrCodes.loraDevices.add': `${prefix}/lora-devices/qr-codes/add`,
  'qrCodes.loraDevices.view': (deviceId = ':deviceId') =>
    `${prefix}/lora-devices/qr-codes/${deviceId}`,
  'qrCodes.gateways.list': `${prefix}/lora-gateways/qr-codes`,
  'qrCodes.gateways.add': `${prefix}/lora-gateways/qr-codes/add`,
  'qrCodes.gateways.view': (deviceId = ':deviceId') =>
    `${prefix}/lora-gateways/qr-codes/${deviceId}`,
}
