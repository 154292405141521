import { TFunction } from 'i18next'
import { TbAlertTriangle, TbCheck, TbEye } from 'react-icons/tb'

const alertStatusArray = (t: TFunction) => [
  {
    value: 'Resolved',
    label: t('alert.resolved'),
    color: 'gray',
    icon: TbEye,
  },
  {
    value: 'Unresolved',
    label: t('alert.unresolved'),
    color: 'red',
    icon: TbAlertTriangle,
  },
  {
    value: 'Normalized',
    label: t('alert.normalized'),
    color: 'green',
    icon: TbCheck,
  },
]

export const alertStatus = (t: TFunction) =>
  new Map(
    alertStatusArray(t).map(({ value, ...rest }) => {
      return [value, rest]
    }),
  )

export const alertStatusOptions = (t: TFunction) =>
  alertStatusArray(t).map(({ value, label, icon }) => ({
    value,
    label,
    icon,
  }))
