import { Textarea, TextareaProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'

interface InputTextAreaProps {
  name: string
  label?: string | React.ReactNode
  placeholder?: string
  required?: boolean
  type?: string
}

export const InputTextarea = ({
  name,
  ...props
}: InputTextAreaProps & TextareaProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <Textarea
            autosize
            minRows={2}
            {...field}
            {...props}
            ref={ref}
            error={error?.message}
          />
        )
      }}
    />
  )
}
