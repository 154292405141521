import { Group, Modal, Stack, Text } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  assignAlertRuleToDevice,
  deleteAlertRuleFromDevice,
  getAlertRuleByDeviceQuery,
} from 'api/alertRuleDevice'
import { useGetAlertRuleList } from 'api/alertRules'
import {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { TbMinus, TbPlus } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { TAlertRule, TAlertRuleList } from 'shared/types'
import { IconButton, QueryPaginated, SearchFilters } from '..'
import { AlertLevelBadge } from './AlertLevelBadge'

type TAlertRuleDeviceContext = {
  assignedIds: string[]
}
const AlertRuleDeviceContext = createContext<TAlertRuleDeviceContext>({
  assignedIds: [],
})
const useAlertRuleDeviceContext = () => useContext(AlertRuleDeviceContext)

export const AssignAlertRuleModal = ({
  assignedIds,
  opened,
  setOpened,
}: {
  assignedIds: string[]
  opened: boolean
  setOpened: Dispatch<SetStateAction<boolean>>
}) => {
  const { t } = useTranslation()
  const query = useGetAlertRuleList()

  return (
    <Modal
      size="lg"
      opened={opened}
      onClose={() => setOpened(prev => !prev)}
      title={t('alert.templateAssign')}
    >
      <AlertRuleDeviceContext.Provider value={{ assignedIds }}>
        <Stack>
          <SearchFilters noWrapper />
          <QueryPaginated.Wrapper query={query}>
            <QueryPaginated.Data>
              {(data: TAlertRuleList) =>
                data.items.map(item => <ListItem key={item.id} {...item} />)
              }
            </QueryPaginated.Data>
          </QueryPaginated.Wrapper>
        </Stack>
      </AlertRuleDeviceContext.Provider>
    </Modal>
  )
}

const ListItem = (item: TAlertRule) => {
  const { assignedIds } = useAlertRuleDeviceContext()
  const [isAssigned, setIsAssigned] = useState<boolean>(
    () => !!assignedIds.find(id => id === item.id),
  )

  return (
    <Group
      align="center"
      py={'lg'}
      sx={theme => ({
        borderBottom: `1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.dark[4]
            : theme.colors.gray[5]
        }`,
        '&:last-child': {
          borderBottom: 'none',
        },
      })}
      position="apart"
    >
      <Stack spacing={'xs'} align="flex-start">
        <Text>{item.name}</Text>
        <AlertLevelBadge level={item.level} />
      </Stack>
      <AssignDeviceButton
        isAssigned={isAssigned}
        setIsAssigned={setIsAssigned}
        alertRuleId={item.id}
      />
    </Group>
  )
}

const AssignDeviceButton = ({
  setIsAssigned,
  alertRuleId,
  isAssigned,
}: {
  isAssigned: boolean
  setIsAssigned: Dispatch<SetStateAction<boolean>>
  alertRuleId: string
}) => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const mutationFn = isAssigned
    ? deleteAlertRuleFromDevice
    : assignAlertRuleToDevice
  const icon = isAssigned ? TbMinus : TbPlus
  const color = isAssigned ? 'red' : 'brand'
  const tooltip = isAssigned ? t('alert.delete') : t('alert.templateAssign')
  const queryClient = useQueryClient()
  const queryKey = getAlertRuleByDeviceQuery(deviceId!).queryKey

  const onSuccess = async () => {
    setIsAssigned(!isAssigned)
    queryClient.refetchQueries(queryKey)
  }

  const onError = () => {
    showNotification({
      color: 'red',
      message: t('generic.errorOccured'),
    })
  }

  const mutation = useMutation({
    mutationFn: mutationFn(deviceId!, alertRuleId),
    onSuccess,
    onError,
  })

  return (
    <IconButton
      onClick={() => mutation.mutate()}
      loading={mutation.isLoading}
      label={tooltip}
      icon={icon}
      color={color}
    />
  )
}
