import { NumberInput, NumberInputProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { IconType } from 'react-icons'

export const InputNumber = ({
  icon: Icon,
  name,
  required,
  ...props
}: Omit<NumberInputProps, 'name' | 'icon'> & {
  name: string
  icon?: IconType
}) => {
  const { control } = useFormContext()

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, onChange, value, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <NumberInput
            {...field}
            {...props}
            ref={ref}
            onChange={value => {
              onChange(typeof value === 'number' ? value : null)
            }}
            value={typeof value === 'number' ? value : ''}
            error={error?.message}
            icon={Icon ? <Icon size={'1.2em'} /> : null}
            withAsterisk={required}
          />
        )
      }}
    />
  )
}
