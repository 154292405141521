import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const SNMPDeviceList = lazy(() => import('../List'))
const SNMPDevice = lazy(() => import('../Single'))
const SNMPDeviceEdit = lazy(() => import('../Edit'))

export const snmpDevicesRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['snmpDevices.list'],
    element: <SNMPDeviceList />,
  }),
  createPanelRoute({
    path: r['snmpDevices.view'](),
    element: <SNMPDevice />,
  }),
  createPanelRoute({
    path: r['snmpDevices.edit'](),
    element: <SNMPDeviceEdit />,
  }),
]
