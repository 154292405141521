import { TAvailableDeviceTabs } from 'components/devices'

const prefix = ''

export const snmpDevicesUrls = {
  'snmpDevices.list': `${prefix}/snmp-devices`,
  'snmpDevices.view': (
    deviceId = ':deviceId',
    tab: TAvailableDeviceTabs | ':tabValue' = ':tabValue',
  ) => `${prefix}/snmp-devices/${deviceId}/${tab}`,
  'snmpDevices.edit': (deviceId = ':deviceId', tab = ':tabValue') =>
    `${prefix}/snmp-devices/${deviceId}/edit/${tab}`,
}
