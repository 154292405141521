import { ErrorView } from 'components/common'
import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { DeviceMapView } from 'views/panel/Locations/Map/DeviceView'
import { createPanelRoute } from 'views/panel/PanelLayout'

const LocationList = lazy(() => import('../List'))
const CreateLocation = lazy(() => import('../Create'))
const SingleLocation = lazy(() => import('../Single'))
const EditLocation = lazy(() => import('../Edit'))
const MapLayout = lazy(() => import('../Map/MapLayout'))

export const locationRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['locations.list'],
    element: <LocationList />,
  }),
  createPanelRoute({
    path: r['locations.create'],
    element: <CreateLocation />,
  }),
  createPanelRoute({
    path: r['locations.view'](),
    element: <SingleLocation />,
    children: [
      {
        path: r['sensor'](),
        element: <DeviceMapView />,
        errorElement: <ErrorView />,
      },
    ],
  }),
  createPanelRoute({
    path: r['locations.edit'](),
    element: <EditLocation />,
  }),
  createPanelRoute({
    path: r['map'],
    element: <MapLayout />,
    children: [
      {
        path: r['mapSensor'](),
        element: <DeviceMapView />,
        errorElement: <ErrorView />,
      },
    ],
  }),
]
