import { Flex, Stack, Text } from '@mantine/core'
import { PropsWithChildren } from 'react'
import { IconType } from 'react-icons'

type LabeledContentProps = {
  label: string
  icon?: IconType
}

export const LabeledContent = ({
  label,
  icon: Icon,
  children,
}: PropsWithChildren<LabeledContentProps>) => {
  if (!children) return null

  return (
    <Stack spacing={2}>
      <Text size={'sm'} color="dimmed" weight={700}>
        <Flex align={'center'} gap={'xs'}>
          {Icon && <Icon size={'1.2em'} color="currentColor" />}
          {label}
        </Flex>
      </Text>
      {children}
    </Stack>
  )
}
