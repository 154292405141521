import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const IndicatorList = lazy(() => import('../List'))
const IndicatorEdit = lazy(() => import('../Edit'))
const IndicatorCreate = lazy(() => import('../Create'))

export const indicatorsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['indicator.list'],
    element: <IndicatorList />,
  }),
  createPanelRoute({
    path: r['indicator.add'],
    element: <IndicatorCreate />,
  }),
  createPanelRoute({
    path: r['indicator.edit'](),
    element: <IndicatorEdit />,
  }),
]
