import { Stack } from '@mantine/core'
import { QueryErrorProps } from '../Query/QueryError'
import { QueryPaginatedProps } from './QueryPaginated'

export const QueryPaginatedError = <T,>({
  children,
  query,
}: Partial<QueryPaginatedProps<T>> & QueryErrorProps) => {
  if (!query) throw new Error('Missing query data')

  if (query.isError)
    return (
      <Stack data-testid="query-error">
        {children instanceof Function ? children(query.error) : children}
      </Stack>
    )

  return null
}
