import { z } from 'zod'
import { Pagination } from '../helper'

export const userItemSchema = z.object({
  userId: z.string(),
  email: z.string().nullish(),
  phoneNumber: z.string().nullish(),
  firstName: z.string().nullish(),
  lastName: z.string().nullish(),
  role: z.string().nullish(),
  state: z.object({
    id: z.number().nullish(),
    name: z.string().nullish(),
  }),
  createdAt: z.string().nullish(),
})

export enum UserStatusENUM {
  Active = 1,
  Locked = 2,
}

export type TUserItem = z.infer<typeof userItemSchema>

export type TUserList = Pagination<TUserItem>
