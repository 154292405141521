import { z } from 'zod'
import { indicatorSchema } from '../helper'
import { logicalOperatorEnum } from './TLogicalOperators'
import { mathSymbolEnum } from './TMathSymbols'

export const alertRuleConditionSchema = z.object({
  id: z.string(),
  indicatorCode: z.string().nullable(),
  indicator: indicatorSchema.nullable(),
  value: z.number().nullable(),
  mathSymbol: z.string().nullable(),
  logicalOperator: z.string(),
})

export type TAlertRuleCondition = z.infer<typeof alertRuleConditionSchema>

export const alertRuleConditionListSchema = z.object({
  conditions: z.array(alertRuleConditionSchema),
})

export type TAlertRuleConditionList = z.infer<
  typeof alertRuleConditionListSchema
>

export const createAlertRuleConditionSchema = z.object({
  indicator: z.string().optional(),
  value: z.number().optional(),
  mathSymbol: mathSymbolEnum.optional(),
  logicalOperator: logicalOperatorEnum.optional(),
})

export type TCreateAlertRuleConditionFields = z.infer<
  typeof createAlertRuleConditionSchema
>

export const createAlertRuleConditionResponseSchema = z.object({
  ruleConditionId: z.string(),
})

export type TCreateAlertRuleConditionResponse = z.infer<
  typeof createAlertRuleConditionResponseSchema
>
