import { useMutation, useQueryClient } from '@tanstack/react-query'
import { assignTagToDevice, getDeviceTagsQuery } from 'api/tags'

export const useAssignTagMutation = ({ deviceId }: { deviceId: string }) => {
  const queryKey = getDeviceTagsQuery(deviceId).queryKey
  const queryClient = useQueryClient()

  const onMutate = async (newTag: string) => {
    await queryClient.cancelQueries(queryKey)
    const oldData = queryClient.getQueryData(queryKey)

    queryClient.setQueryData<{ value: string; label: string }[]>(
      queryKey,
      old => {
        const newItem = { value: newTag, label: newTag }
        if (!old || old?.length === 0) return [newItem]
        return [...old, newItem]
      },
    )

    return { oldData, newTag }
  }

  const onSettled = () => {
    queryClient.invalidateQueries(queryKey)
  }

  const mutation = useMutation({
    mutationFn: assignTagToDevice(deviceId),
    onMutate,
    onSettled,
  })

  return mutation
}
