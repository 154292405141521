import { z } from 'zod'
import { AlertLevelEnum } from './TAlertLevel'

export const createAlertRuleSchema = z.object({
  level: AlertLevelEnum.optional(),
  name: z.string().nullable(),
  sendSms: z.boolean().default(false),
  sendEmail: z.boolean().default(false),
})

export type TCreateAlertRuleFields = z.infer<typeof createAlertRuleSchema>

export const createAlertRuleResponse = z.object({
  alertRuleId: z.string(),
})

export type TCreateAlertRuleResponse = z.infer<typeof createAlertRuleResponse>
