const prefix = ''

export type TRawPacketTabs = ':tabValue' | 'list' | 'decoder'

export const packetUrls = {
  'loraPacket.list': `${prefix}/lorawan-packets`,
  'snmpPacket.list': `${prefix}/snmp-packets`,
  'rawPacket.list': (tabValue: TRawPacketTabs = ':tabValue') =>
    `${prefix}/raw-packets/${tabValue}`,
  'loraUdp.list': `${prefix}/lorawan-udp-logs`,
}
