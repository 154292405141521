import { z } from 'zod'
import { api } from '..'

export const setColumnSizeSchema = z.object({ size: z.string() })

export type TSetColumnSizeFields = z.infer<typeof setColumnSizeSchema>

export const setColumnSize =
  ({ rowId, columnId }: { rowId: string; columnId: string }) =>
  (data: TSetColumnSizeFields) =>
    api.put(`/v1/dashboards/rows/${rowId}/columns/${columnId}/size`, data)
