import { z } from 'zod'
import { indicatorSchema } from '../helper'

export const chartLabelSchema = z.object({
  chartLabelId: z.string(),
  deviceId: z.string(),
  indicatorCode: z.string(),
  indicator: indicatorSchema.nullish(),
  dateFrom: z.string(),
  dateTo: z.string(),
  title: z.string(),
  description: z.string().nullish(),
  hexColor: z.string(),
})

export type TChartLabel = z.infer<typeof chartLabelSchema>
