import { z } from 'zod'
import { deviceModelListItemSchema } from '../../../../apps/ConnectedLifePanel/src/api/models/getModelList'
import { Pagination } from '../helper'
import { DeviceTypeEnum } from './DeviceTypes'
import { localizationSchema } from './Localization'

export const deviceSchema = z.object({
  deviceId: z.string(),
  type: DeviceTypeEnum,
  provider: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  localization: localizationSchema.omit({ locationId: true }),
  locationId: z.string(),
  modelId: z.string().nullable(),
  model: deviceModelListItemSchema.nullable(),
  deviceGroups: z
    .array(
      z.object({
        id: z.string().nullable(),
        name: z.string().nullable(),
        description: z.string().nullable(),
        locationId: z.string().nullable(),
        countDevice: z.number().nullable(),
      }),
    )
    .nullable(),
  photoId: z.string().nullable(),
  photo: z.object({ id: z.string(), url: z.string() }).nullable(),
  networkProvider: z.object({
    id: z.number(),
    name: z.string(),
  }),
  organization: z
    .object({
      id: z.string().nullable(),
      name: z.string().nullable(),
    })
    .nullable(),
  isOnline: z.boolean(),
})

export type TModel = z.infer<typeof deviceModelListItemSchema>

export type TDevice = z.infer<typeof deviceSchema>

export type TDeviceList = Pagination<TDevice>
