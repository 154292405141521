import {
  TCreateChartLabelFields,
  TCreateChartLabelResponse,
} from 'shared/types'
import { api } from '..'

export const createChartLabel = (data: TCreateChartLabelFields) =>
  api
    .post<TCreateChartLabelResponse>('/v1/charts/labels', data)
    .then(({ data }) => data)
