import { forwardRef, useMemo } from 'react'
import InputMask from 'react-input-mask'
import { InputTextProps, InputTextPureProps } from './Input_Text'
import { Input, TextInputProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'
import { TbPhone } from 'react-icons/tb'

const InputP = forwardRef<
  HTMLInputElement,
  Omit<InputTextPureProps, 'size'> & { length?: number }
>((props, ref) => {
  const mask = useMemo(() => {
    return new Array(3).fill('hhh').join('-')
  }, [length])

  return (
    <InputMask
      inputRef={ref}
      {...props}
      formatChars={{ h: '[0-9]' }}
      mask={mask}
    />
  )
})

InputP.displayName = 'InputMask'

export const InputPhonePure = forwardRef<
  HTMLInputElement,
  Omit<TextInputProps, 'value'> & { value?: string }
>(({ wrapperProps, required, label, error, onChange, value, icon }, ref) => {
  return (
    <Input.Wrapper
      required={required}
      label={label}
      error={error}
      {...wrapperProps}
    >
      <Input
        data-invalid={!!error}
        component={InputP}
        ref={ref}
        onChange={onChange}
        value={value}
        icon={icon}
      />
    </Input.Wrapper>
  )
})

InputPhonePure.displayName = 'TextInputPhonePure'

export const InputPhone = ({
  name,
  icon: Icon = TbPhone,
  className,
  ...props
}: InputTextProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <InputPhonePure
            {...field}
            {...props}
            ref={ref}
            className={className}
            error={error?.message}
            icon={Icon ? <Icon size={'1.2em'} /> : null}
          />
        )
      }}
    />
  )
}
