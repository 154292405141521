import { forwardRef, useMemo } from 'react'
import InputMask from 'react-input-mask'
import { InputTextProps, InputTextPureProps } from './Input_Text'
import { Input, TextInputProps } from '@mantine/core'
import { Controller, useFormContext } from 'react-hook-form'

type InputBProps = {
  length?: number
  mask?: string
  separator?: string
  formatChars?: Record<string, string>
  maskChar?: string | null
}

const InputB = forwardRef<HTMLInputElement, InputTextPureProps & InputBProps>(
  (
    {
      length = 16,
      mask = 'hh',
      separator = ' ',
      formatChars = { h: '[0-9a-fA-F]' },
      size,
      maskChar = '_',
      ...props
    },
    ref,
  ) => {
    const inputMask = useMemo(() => {
      return new Array(length / 2).fill(mask).join(separator)
    }, [length])

    return (
      <InputMask
        inputRef={ref}
        {...props}
        formatChars={formatChars}
        mask={inputMask}
        maskChar={maskChar}
      />
    )
  },
)

InputB.displayName = 'InputMask'

export const InputBinaryPure = forwardRef<
  HTMLInputElement,
  Omit<TextInputProps, 'value'> & { value?: string } & InputBProps
>(
  (
    {
      wrapperProps,
      required,
      label,
      error,
      onChange,
      value,
      icon,
      length,
      mask,
      separator,
      formatChars,
      placeholder,
      maskChar,
      description,
      ...props
    },
    ref,
  ) => {
    return (
      <Input.Wrapper
        required={required}
        label={label}
        error={error}
        description={description}
        {...wrapperProps}
      >
        <Input
          data-invalid={!!error}
          component={InputB}
          ref={ref}
          onChange={onChange}
          value={
            typeof value === 'string' && value !== undefined && value !== null
              ? value.toUpperCase()
              : value
          }
          icon={icon}
          length={length}
          mask={mask}
          maskChar={maskChar}
          separator={separator}
          formatChars={formatChars}
          placeholder={placeholder}
          {...props}
        />
      </Input.Wrapper>
    )
  },
)

InputBinaryPure.displayName = 'TextInputBinaryPure'

export const InputBinary = ({
  name,
  icon: Icon,
  className,
  length,
  ...props
}: InputTextProps & InputBProps) => {
  const { control } = useFormContext()
  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, ...field }, fieldState }) => {
        const { error } = fieldState
        return (
          <InputBinaryPure
            {...field}
            {...props}
            length={length}
            ref={ref}
            className={className}
            error={error?.message}
            icon={Icon ? <Icon size={'1.2em'} /> : null}
          />
        )
      }}
    />
  )
}
