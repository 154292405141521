import { Pagination } from '../helper'
import { z } from 'zod'

export const locationItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
})

export type TLocationItem = z.infer<typeof locationItemSchema>

export type TLocationList = Pagination<TLocationItem>
