export const dashboardColumnSizeArray = [
  {
    value: 'OneOnOne',
    label: '1 / 1',
    colSpan: 6,
  },
  {
    value: 'OneOnTwo',
    label: '1 / 2',
    colSpan: 3,
  },
  {
    value: 'OneOnThree',
    label: '1 / 3',
    colSpan: 2,
  },
  {
    value: 'TwoOnThree',
    label: '2 / 3',
    colSpan: 4,
  },
]

export const dashboardColumnSize = new Map(
  dashboardColumnSizeArray.map(({ value, ...rest }) => {
    return [value, rest]
  }),
)

export const dashboardColumnSizeOptions = dashboardColumnSizeArray.map(
  ({ value, label }) => ({
    value,
    label,
  }),
)
