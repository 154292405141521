import { wrapCreateBrowserRouter } from '@sentry/react'
import { api } from 'api/api'
import { ErrorView } from 'components'
import { createPlainRoute } from 'components/layout/PlainLayout'
import { Spotlight } from 'config/Spotlight'
import { r } from 'config/routes'
import { removeFcmToken } from 'firebase/setupFirebase'
import { lazy, useEffect } from 'react'
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  useLoaderData,
} from 'react-router-dom'
import { TOrganization, TUser } from 'shared/types'
import { useAuthActions, useAuthStore } from 'store/authStore'
import { useCustomizerActions } from 'store/customizerStore'
import { authRoutes } from './auth/@Routes'
import { panelRoutes } from './panel/@Routes'

const PublicDashboardLoraDevices = lazy(
  () => import('views/panel/LoraDevices/PublicDashboard'),
)

const AppBoot = () => {
  const data = useLoaderData() as
    | (TUser & { organization: TOrganization })
    | null

  const { setLoginData, setOrganizationId } = useAuthActions()
  const { setBranding } = useCustomizerActions()

  useEffect(() => {
    if (data === null) return

    setLoginData(data)
    if (data.organization) {
      setOrganizationId(data.organization.organizationId)
      setBranding({
        logo: data.organization.logo?.url ?? null,
        logoText: data.organization.textLogo?.url ?? null,
        primaryColor: data.organization.primaryColor,
        sidebarColor: data.organization.sidebarColor,
      })
    }
  }, [])

  return (
    <Spotlight>
      <Outlet />
    </Spotlight>
  )
}

const createRouterWithSentry = wrapCreateBrowserRouter(createBrowserRouter)
const router = createRouterWithSentry([
  {
    path: '/',
    element: <AppBoot />,
    loader: async ({ request }: { request: Request }) => {
      try {
        const { isLoggedIn, user } = useAuthStore.getState()
        if (isLoggedIn) return user

        const [me, organization] = await Promise.all([
          api
            .get<TUser>('/v1/users/user', {
              signal: request.signal,
            })
            .then(({ data }) => data),
          api
            .get<TOrganization>('/v1/organization', {
              signal: request.signal,
            })
            .then(({ data }) => data)
            .catch(() => null),
        ])

        return { ...me, organization }
      } catch (e) {
        removeFcmToken()
        return null
      }
    },
    errorElement: <ErrorView to={r['panel']} />,
    children: [...authRoutes, ...panelRoutes],
  },
  createPlainRoute({
    path: r['loraDevices.dashboard'](),
    element: <PublicDashboardLoraDevices />,
  }),
])

const Router = () => {
  return <RouterProvider router={router} />
}

export default Router
