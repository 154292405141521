import {
  ActionIcon,
  Button,
  createStyles,
  Group,
  Menu,
  Tooltip,
} from '@mantine/core'
import { openModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { useMutation } from '@tanstack/react-query'
import { TDashboard } from 'api/dashboards/getDashboard'
import { setDashboardAsDefault } from 'api/dashboards/setDashboardAsDefault'
import { AxiosError } from 'axios'
import { useDeviceModelContext } from 'components/devices/DeviceIndicators'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { BiCustomize } from 'react-icons/bi'
import { IoClose } from 'react-icons/io5'
import { TbCheckbox, TbChevronDown, TbEdit, TbTrash } from 'react-icons/tb'
import { ErrorResponse } from 'shared/types'
import { DashboardCopy } from './DashboardCopy'
import { DashboardCreate } from './DashboardCreate'
import { useDashboardContext } from './DashboardProvider'
import { EditDashboardInfoForm } from './EditDashboardInfo'
import {
  useDashboardActions,
  useDashboardStore,
} from './hooks/useDashboardStore'
import { useRemoveDashboard } from './hooks/useRemoveDashboard'

export const DashboardCustomizer = ({ onChange }: { onChange: () => void }) => {
  const dashboard = useDashboardContext()
  const { toggleEditMode } = useDashboardActions()
  const isEditMode = useDashboardStore(state => state.isEditMode)

  const onToggle = () => {
    if (isEditMode) {
      showNotification({
        color: 'blue',
        message: 'Dashboard saved',
      })
    }
    toggleEditMode()
  }

  const { classes, theme } = useStyles()

  const Icon = isEditMode ? IoClose : BiCustomize

  useEffect(() => {
    return () => {
      if (isEditMode) toggleEditMode(false)
    }
  }, [isEditMode])

  return (
    <Group noWrap spacing={0}>
      <Tooltip label={isEditMode ? 'Exit edit mode' : 'Enter edit mode'}>
        <Button onClick={onToggle} className={classes.button}>
          <Icon size="1.2rem" />
        </Button>
      </Tooltip>
      <Menu
        transitionProps={{
          transition: 'pop',
        }}
        position="bottom-end"
        withinPortal
        disabled={isEditMode}
      >
        <Menu.Target>
          <ActionIcon
            variant="filled"
            color={theme.primaryColor}
            size={36}
            className={classes.menuControl}
            disabled={isEditMode}
          >
            <TbChevronDown size="1rem" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <DashboardCreate />
          <DashboardCopy dashboardId={dashboard.id} />
          <EditDashboard />
          <SetAsDefault />
          <DeleteDashboard onDelete={onChange} {...dashboard} />
        </Menu.Dropdown>
      </Menu>
    </Group>
  )
}

const useStyles = createStyles(theme => ({
  button: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  menuControl: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    borderLeft: `2px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white
    }`,
  },
}))

const DeleteDashboard = ({
  id,
  type,
  onDelete,
}: TDashboard & { onDelete: () => void }) => {
  const { t } = useTranslation()

  const openModal = useRemoveDashboard({ id }, onDelete)

  return (
    <Menu.Item onClick={openModal} c={'red'} icon={<TbTrash />}>
      {t('generic.delete')}
    </Menu.Item>
  )
}

const SetAsDefault = () => {
  const { t } = useTranslation()
  const dashboard = useDashboardContext()
  const deviceModelId = useDeviceModelContext()
  const mutation = useMutation({
    mutationFn: setDashboardAsDefault,
    onSuccess: () => {
      showNotification({
        color: 'green',
        message: t('dashboard.defaultSetSuccess'),
      })
    },
    onError: (data: AxiosError<ErrorResponse>) => {
      showNotification({
        color: 'red',
        message:
          data.response?.data.errors.map(error => error.message).join(', ') ??
          t('generic.errorOccured'),
      })
    },
  })

  if (!deviceModelId) return null

  return (
    <Menu.Item
      onClick={() => mutation.mutate({ dashboardId: dashboard.id })}
      icon={<TbCheckbox />}
    >
      Ustaw jako domyślny
    </Menu.Item>
  )
}

const EditDashboard = () => {
  const dashboard = useDashboardContext()
  const { t } = useTranslation()
  const onClick = () => {
    openModal({
      title: t('dashboard.edit'),
      children: (
        <EditDashboardInfoForm
          id={dashboard.id}
          initial={{ name: dashboard.name }}
        />
      ),
    })
  }

  return (
    <Menu.Item onClick={onClick} icon={<TbEdit />}>
      {t('generic.edit')}
    </Menu.Item>
  )
}
