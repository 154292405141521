import { z } from 'zod'

export const createChartLabelSchema = z.object({
  deviceId: z.string(),
  indicatorCode: z.string(),
  dateFrom: z.string().datetime(),
  dateTo: z.string().datetime(),
  title: z.string(),
  hexColor: z.string().regex(/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/),
  description: z.string().optional(),
})

export type TCreateChartLabelFields = z.infer<typeof createChartLabelSchema>

export type TCreateChartLabelResponse = {
  chartLabelId: string
}
