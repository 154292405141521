import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getDeviceTagsQuery, removeTagFromDevice } from 'api/tags'

export const useRemoveTagMutation = ({ deviceId }: { deviceId: string }) => {
  const queryKey = getDeviceTagsQuery(deviceId).queryKey
  const queryClient = useQueryClient()

  const onMutate = async (removedTagId: string) => {
    await queryClient.cancelQueries(queryKey)
    const oldData = queryClient.getQueryData(queryKey)

    queryClient.setQueryData<{ value: string; label: string }[]>(
      queryKey,
      old => {
        if (!old || old?.length === 0) return []
        return old.filter(tag => tag.value !== removedTagId)
      },
    )

    return { oldData, newTag: removedTagId }
  }

  const onSettled = () => {
    queryClient.invalidateQueries(queryKey)
  }

  const mutation = useMutation({
    mutationFn: removeTagFromDevice(deviceId),
    onMutate,
    onSettled,
  })

  return mutation
}
