import { useMantineColorScheme } from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { SpotlightAction, SpotlightProvider } from '@mantine/spotlight'
import { useGetDevicesSpotlight } from 'api/devices'
import { openDisplaySettings } from 'components/layout/UserHeader'
import { PropsWithChildren, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  TbBuilding,
  TbMoonStars,
  TbPlaylistAdd,
  TbQuestionCircle,
  TbSettings,
  TbSun,
  TbUser,
} from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { capitalizeFirstLetter } from 'shared/utils'
import { useCustomizerStore } from 'store/customizerStore'
import { navigationConfig } from './navigationConfig'
import { r } from './routes'

export const Spotlight = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const [query, setQuery] = useState<string>()
  const [debouncedQuery] = useDebouncedValue(query, 500)
  const { data, isLoading, isPaused } = useGetDevicesSpotlight(debouncedQuery)
  const selectedWorkspace = useCustomizerStore(state => state.selectedWorkspace)

  const searchResults = data ? data : []

  const items = useMemo(
    () => navigationConfig.get(selectedWorkspace)!(t),
    [t, selectedWorkspace],
  )

  const sideNavigation = useMemo(
    () =>
      items
        .filter(item => item.type === 'link')
        ?.map?.(item => ({
          title: capitalizeFirstLetter(item.label),
          onTrigger: () => navigate(item.type === 'link' ? item.href : '/'),
          icon:
            item.type === 'link' ? (
              <item.icon size="1.2rem" />
            ) : (
              <TbQuestionCircle size="1.2rem" />
            ),
        })),
    [t, items],
  )

  const actions: SpotlightAction[] = useMemo(
    () => [
      ...searchResults,
      {
        title: t('sensor.register'),
        onTrigger: () => navigate(r['devices.register']),
        icon: <TbPlaylistAdd size="1.2rem" />,
      },
      {
        title: t('organization.single'),
        onTrigger: () => navigate(r['organizations.view']),
        icon: <TbBuilding size="1.2rem" />,
      },
      {
        title: t('generic.appSettings'),
        onTrigger: () => openDisplaySettings(t),
        icon: <TbSettings size="1.2rem" />,
      },
      {
        title: t('user.accountSettings'),
        onTrigger: () => navigate(r['account']('info')),
        icon: <TbUser size="1.2rem" />,
      },
      {
        title: t('generic.theme'),
        onTrigger: () => toggleColorScheme(),
        icon:
          colorScheme === 'light' ? (
            <TbSun size="1.2rem" />
          ) : (
            <TbMoonStars size="1.2rem" />
          ),
        description: t(`generic.${colorScheme}`) as string,
      },
      ...sideNavigation,
    ],
    [t, colorScheme, data, sideNavigation],
  )

  return (
    <SpotlightProvider
      searchPlaceholder={t('generic.search') as string}
      shortcut={['mod + P', 'mod + K', '/']}
      nothingFoundMessage={
        isLoading || isPaused
          ? t('generic.searching')
          : t('generic.nothingFound')
      }
      actions={actions}
      highlightQuery
      query={query}
      onQueryChange={setQuery}
    >
      {children}
    </SpotlightProvider>
  )
}
