import { api } from 'api/api'

export const setDashboardAsDefault = async ({
  dashboardId,
}: {
  dashboardId: string
}) => {
  await api
    .put(`/v1/dashboards/${dashboardId}/default`)
    .then(({ data }) => data)
}
