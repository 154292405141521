import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { r } from 'config/routes'
import { ErrorView } from 'components'

const Login = lazy(() => import('../login'))
const Register = lazy(() => import('../register'))
const ForgotPassword = lazy(() => import('../forgotPassword'))
const ConfirmEmail = lazy(() => import('../confirmEmail'))
const ResetPassword = lazy(() => import('../resetPassword'))

export const authRoutes: RouteObject[] = [
  {
    path: r['index'],
    element: <Login />,
    errorElement: <ErrorView to={r['index']} />,
  },
  {
    path: r['register'],
    element: <Register />,
    errorElement: <ErrorView to={r['index']} />,
  },
  {
    path: r['forgotPassword'],
    element: <ForgotPassword />,
    errorElement: <ErrorView to={r['index']} />,
  },
  {
    path: r['confirmEmail'],
    element: <ConfirmEmail />,
    errorElement: <ErrorView to={r['index']} />,
  },
  {
    path: r['resetPassword'],
    element: <ResetPassword />,
    errorElement: <ErrorView to={r['index']} />,
  },
]
