import { useQuery } from '@tanstack/react-query'
import { TDevice } from 'shared/types'
import { api } from '..'

export const getDevice = (deviceId: string) =>
  api.get<TDevice>(`/v1/devices/${deviceId}`).then(({ data }) => {
    const localization = Object.entries(data.localization).reduce(
      (prev: Record<string, number>, [key, value]) => {
        if (value) prev[key] = Number(value)
        return prev
      },
      {},
    )

    return {
      ...data,
      localization,
    }
  })

export const useGetDevice = (deviceId?: string) => {
  if (!deviceId) throw new Error('Missing deviceId')
  return useQuery({
    queryFn: () => getDevice(deviceId),
    queryKey: ['device', deviceId],
  })
}
