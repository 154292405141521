import { Popover } from '@mantine/core'
import { PropsWithChildren } from 'react'
import { TLabelPopoverState } from '../area/MeasurementAreaChart'
import { LabelForm } from './LabelForm'

type LabelPopoverProps = {
  close: () => void
  state: TLabelPopoverState | null
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const clearSelection = (chart: any) => {
  chart.updateOptions({
    chart: {
      selection: {
        xaxis: {
          min: undefined,
          max: undefined,
        },
      },
    },
  })
}

export const LabelPopover = ({
  close,
  children,
  state,
}: PropsWithChildren<LabelPopoverProps>) => {
  const onCancel = () => {
    close()
    clearSelection(state?.chart)
  }

  return (
    <Popover shadow={'md'} withArrow position="bottom" opened={state !== null}>
      <Popover.Target>
        <div>{children}</div>
      </Popover.Target>
      <Popover.Dropdown>
        {state !== null && <LabelForm onCancel={onCancel} state={state} />}
      </Popover.Dropdown>
    </Popover>
  )
}
