import { TFunction } from 'i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { TiWarningOutline } from 'react-icons/ti'
import { VscError } from 'react-icons/vsc'
import { TAlertLevelsResponse } from 'shared/types'
import { api } from '..'

export const getAlertLevels = () =>
  api
    .get<TAlertLevelsResponse>('/v1/alert-rules/alert-levels')
    .then(({ data }) => data.levels)

export const alertLevelsOptionsHelper = (t: TFunction) => ({
  queryKey: ['alertLevels', 'options'],
  queryFn: () =>
    getAlertLevels().then(data =>
      data.map(({ value }) => ({ value, label: t(`alert.${value}`) })),
    ),
})

export const alertLevelOptions = (t: TFunction) => [
  {
    value: 'Info',
    label: t('alert.Info'),
    icon: HiOutlineInformationCircle,
  },
  {
    value: 'Warning',
    label: t('alert.Warning'),
    icon: TiWarningOutline,
  },
  {
    value: 'Critical',
    label: t('alert.Critical'),
    icon: VscError,
  },
]
