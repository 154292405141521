import { z } from 'zod'
import { emailStatusSchema } from './TEmailList'

export const emailSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  toEmail: z.string(),
  subject: z.string(),
  messages: z.string(),
  status: emailStatusSchema,
  template: emailStatusSchema,
})

export type TEmail = z.infer<typeof emailSchema>
