import { PropsWithChildren } from 'react'
import { NoDataPrompt, NoDataPromptProps } from '../NoDataPrompt'
import { QueryPaginatedProps } from './QueryPaginated'

export type QueryPaginatedNoDataProps<T> = PropsWithChildren<
  Partial<QueryPaginatedProps<T>> & NoDataPromptProps
>

export const QueryPaginatedNoData = <T,>({
  query,
  children,
  ...props
}: QueryPaginatedNoDataProps<T>) => {
  if (!query) throw new Error('Missing query data')
  if (query.data && query.data.items.length === 0)
    return children ? <>{children}</> : <NoDataPrompt {...props} />

  return null
}
