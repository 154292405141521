import { z } from 'zod'

export const availableAlertLevels = ['Info', 'Warning', 'Critical'] as const
export const AlertLevelEnum = z.enum(availableAlertLevels)

export type TAlertLevel = z.infer<typeof AlertLevelEnum>

export const alertLevelResponseSchema = z.object({
  levels: z.array(
    z.object({
      value: AlertLevelEnum,
    }),
  ),
})

export type TAlertLevelsResponse = z.infer<typeof alertLevelResponseSchema>
