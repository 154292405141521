import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const OrganizationsList = lazy(() => import('../List'))
const Organization = lazy(() => import('../Single'))
const AddMember = lazy(() => import('../AddMember'))
const EditOrganization = lazy(() => import('../EditOrganization'))

export const organizationRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['organizations.list'],
    element: <OrganizationsList />,
  }),
  createPanelRoute({
    path: r['organizations.view'],
    element: <Organization />,
  }),
  createPanelRoute({
    path: r['organizations.user.add'],
    element: <AddMember />,
  }),
  createPanelRoute({
    path: r['organizations.edit'],
    element: <EditOrganization />,
  }),
]
