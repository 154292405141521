import { z } from 'zod'
import { api } from '..'
import { TCreateDashboardFields } from './dashboardCreate'
import { assignDashboardToDevice } from './deviceDashboards/assignDashboardToDevice'
import { setDefaultDeviceDashboard } from './deviceDashboards/setAsDefaultDeviceDashboard'

const createDashboardResponse = z.object({
  dashboardId: z.string(),
})

export type TCopyDashboardResponse = z.infer<typeof createDashboardResponse>

export const copyDashboard =
  ({ deviceId, dashboardId }: { deviceId: string; dashboardId: string }) =>
  async (data: TCreateDashboardFields) => {
    const res = await api
      .post(`/v1/dashboards/${dashboardId}/copy`, data)
      .then(({ data }) => createDashboardResponse.parse(data))

    await assignDashboardToDevice(
      { deviceId },
      { dashboardsId: [res.dashboardId] },
    )
    await setDefaultDeviceDashboard({ deviceId, dashboardId: res.dashboardId })

    return res
  }
