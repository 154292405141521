import { z } from 'zod'
import { api } from '..'

export const editDashboardSchema = z.object({
  name: z.string().nullable(),
  description: z.string().optional(),
  isDefault: z.boolean().optional(),
})

export type TEditDashboardFields = z.infer<typeof editDashboardSchema>

export const editDashboard =
  (dashboardId: string) => (data: TEditDashboardFields) =>
    api.put(`/v1/dashboards/${dashboardId}`, data)
