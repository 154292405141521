import { Pagination } from '../helper'
import { z } from 'zod'

export const emailStatusSchema = z.object({
  id: z.number(),
  name: z.string(),
})

export const emailListItemSchema = z.object({
  id: z.string(),
  createdAt: z.string(),
  toEmail: z.string(),
  subject: z.string(),
  status: emailStatusSchema,
  template: emailStatusSchema,
})

export type TEmailListItem = z.infer<typeof emailListItemSchema>

export type TEmailStatus = z.infer<typeof emailStatusSchema>

export type TEmailList = Pagination<TEmailListItem>
