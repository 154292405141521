import { Button, Group, Menu, Stack } from '@mantine/core'
import { closeAllModals, openModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { useQueryClient } from '@tanstack/react-query'
import {
  TCreateDashboardFields,
  createDashboard,
  createDashboardSchema,
} from 'api/dashboards/dashboardCreate'
import {
  useDashboardIdContext,
  useDeviceModelContext,
} from 'components/devices/DeviceIndicators'
import { useFormMutation } from 'hooks/useFormMutation'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiRename } from 'react-icons/bi'
import { TbPlus } from 'react-icons/tb'
import { useParams } from 'react-router-dom'
import { HookFormError, InputSubmit, InputText } from '..'

export const DashboardCreate = () => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const modelId = useDeviceModelContext()
  const { setDashboardId } = useDashboardIdContext()

  const onClick = () => {
    openModal({
      title: t('dashboard.create'),
      children: (
        <CreateDashboardForm
          mutationFn={createDashboard({ deviceId: deviceId! })}
          deviceId={deviceId!}
          modelId={modelId}
          setDashboardId={setDashboardId}
        />
      ),
    })
  }

  return (
    <Menu.Item onClick={onClick} icon={<TbPlus />}>
      {t('dashboard.create')}
    </Menu.Item>
  )
}

export const CreateDashboardForm = ({
  deviceId,
  modelId,
  mutationFn,
  submitLabel,
  setDashboardId,
}: {
  deviceId: string
  modelId: string | null
  mutationFn: (data: TCreateDashboardFields) => Promise<{ dashboardId: string }>
  submitLabel?: string
  setDashboardId: React.Dispatch<React.SetStateAction<string | undefined>>
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { methods, onSubmit } = useFormMutation({
    mutationFn,
    schema: createDashboardSchema,
    onSuccess: data => {
      queryClient.refetchQueries([
        'dashboards',
        'available',
        { deviceId, modelId },
      ])

      closeAllModals()
      setDashboardId(data?.dashboardId)
    },
    onError: () => {
      showNotification({
        color: 'red',
        message: t('generic.errorOccured'),
      })
    },
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <DashboardFormFields submitLabel={submitLabel} />
      </form>
    </FormProvider>
  )
}

export const DashboardFormFields = ({
  isEdit,
  submitLabel,
}: {
  isEdit?: boolean
  submitLabel?: string
}) => {
  const { t } = useTranslation()

  return (
    <Stack>
      <InputText
        data-autofocus
        label={t('generic.name')}
        name="name"
        icon={BiRename}
      />
      <HookFormError />
      <Group position="right">
        <Button variant="outline" onClick={() => closeAllModals()}>
          {t('generic.cancel')}
        </Button>
        <InputSubmit
          mt={0}
          value={isEdit ? t('generic.save') : submitLabel ?? t('generic.add')}
        />
      </Group>
    </Stack>
  )
}
