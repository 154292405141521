import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Popover,
  TextInput,
} from '@mantine/core'
import { useDebouncedValue } from '@mantine/hooks'
import { useGetTags } from 'api/tags'
import { KeyboardEventHandler, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BiHash } from 'react-icons/bi'
import { TbPlus } from 'react-icons/tb'
import { Loader } from '..'
import { useAssignTagMutation } from './hooks/useAssignTagMutation'

export const InputTag = ({
  assignedTags,
  deviceId,
}: {
  assignedTags: string[]
  deviceId: string
}) => {
  const { t } = useTranslation()
  const [search, setSearch] = useState<string | undefined>(undefined)
  const [debouncedSearch] = useDebouncedValue(search, 500)
  const { data, isLoading, isSuccess } = useGetTags(debouncedSearch)
  const searchResults = useMemo(
    () =>
      isSuccess ? data.filter(tag => !assignedTags.includes(tag.value)) : [],
    [data, isSuccess],
  )

  const assignMutataion = useAssignTagMutation({ deviceId })

  const handleSubmit = (value?: string) => {
    if (!value) return
    assignMutataion.mutate(value)
    setSearch('')
  }

  const onKeyDown: KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key !== 'Enter') return
    const value = e.currentTarget.value.trim()

    handleSubmit(value)
  }

  return (
    <Popover
      width={280}
      trapFocus
      position="right-start"
      withArrow
      shadow={'md'}
    >
      <Popover.Target>
        <ActionIcon
          aria-label={t('sensor.addTag')}
          variant="outline"
          size="xs"
          sx={theme => ({
            border: `1px solid ${theme.colors.brand[6]}`,
          })}
        >
          <TbPlus size="1.5rem" color="currentColor" />
        </ActionIcon>
      </Popover.Target>
      <Popover.Dropdown>
        <TextInput
          label={t('sensor.tag')}
          mb={'md'}
          data-autofocus
          value={search}
          icon={<BiHash />}
          onChange={e => {
            setSearch(e.target.value)
          }}
          onKeyDown={onKeyDown}
        />
        {isLoading && <Loader />}
        <Group spacing={'xs'}>
          {searchResults.map(tag => (
            <BadgeButton
              onAdd={assignMutataion.mutate}
              key={tag.value}
              {...tag}
            />
          ))}
          {searchResults.length === 0 && (
            <Button
              onClick={() => handleSubmit(search)}
              fullWidth
              leftIcon={<TbPlus />}
            >
              {t('generic.add')} {debouncedSearch}
            </Button>
          )}
        </Group>
      </Popover.Dropdown>
    </Popover>
  )
}

const BadgeButton = ({
  onAdd,
  label,
}: {
  onAdd: (value: string) => void
  value: string
  label: string
}) => {
  return (
    <Badge
      onClick={() => onAdd(label)}
      component="button"
      sx={theme => ({
        cursor: 'pointer',
        border: `1px solid ${theme.colors.brand[6]}`,
      })}
    >
      {label}
    </Badge>
  )
}
