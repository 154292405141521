import { Popover, Stack, Switch } from '@mantine/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  TDashboard,
  getDefaultDashbaordQueryKey,
} from 'api/dashboards/getDashboard'
import {
  PublishDashboardOptions,
  publishDashboard,
} from 'api/dashboards/publishDashboard'
import { CodeBox, IconButton } from 'components/common'
import { r } from 'config/routes'
import { useTranslation } from 'react-i18next'
import { BsArrowUpRightSquare } from 'react-icons/all'

export const PublishDashboardButton = ({
  dashboardId,
  deviceId,
  isPublicDashboard,
}: {
  deviceId?: string
  dashboardId: string
  isPublicDashboard: boolean
}) => {
  const { t } = useTranslation()
  const location = new URL(window.location.href)
  const queryClient = useQueryClient()

  const onMutate = async (newDashboardOptions: PublishDashboardOptions) => {
    const queryKey = getDefaultDashbaordQueryKey(newDashboardOptions.deviceId)
    await queryClient.cancelQueries({ queryKey })

    // Snapshot the previous value
    const previousDashboardData = queryClient.getQueryData(queryKey)

    // Optimistically update to the new value
    queryClient.setQueryData<TDashboard>(
      queryKey,
      old => old && { ...old, isPublic: newDashboardOptions.isPublic },
    )

    // Return a context with the previous and new todo
    return { previousDashboardData, newDashboardOptions }
  }

  const mutation = useMutation({
    mutationFn: publishDashboard,
    onMutate,
    onSuccess: () => {
      queryClient.refetchQueries([
        'dashboards',
        'device',
        deviceId,
        dashboardId,
      ])
    },
    onError: (err, newTodo, context) => {
      queryClient.setQueryData(
        getDefaultDashbaordQueryKey(deviceId!),
        context?.previousDashboardData,
      )
    },
  })
  if (!deviceId) throw new Error('Missing deviceId')

  return (
    <Popover
      width={300}
      shadow={'md'}
      withArrow
      position={'bottom'}
      clickOutsideEvents={['mouseup', 'touchend']}
    >
      <Popover.Target>
        <div style={{ position: 'relative' }}>
          <IconButton
            size={'lg'}
            icon={BsArrowUpRightSquare}
            label={t('generic.publicDashboard')}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Stack spacing={'md'}>
          <Switch
            onChange={e =>
              mutation.mutate({
                dashboardId,
                deviceId,
                isPublic: e.target.checked,
              })
            }
            checked={isPublicDashboard}
            label={t('generic.publicDashboard')}
          />
          {isPublicDashboard ? (
            <CodeBox
              transformValue={value => value.toLowerCase()}
              value={
                location.host +
                r['loraDevices.dashboard'](deviceId, dashboardId)
              }
            />
          ) : null}
        </Stack>
      </Popover.Dropdown>
    </Popover>
  )
}
