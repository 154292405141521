import { Badge } from '@mantine/core'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { HiOutlineInformationCircle } from 'react-icons/hi'
import { TiWarningOutline } from 'react-icons/ti'
import { VscError } from 'react-icons/vsc'
import { TAlertLevel } from 'shared/types'
import { FaRegQuestionCircle } from 'react-icons/fa'

export const getAlertLevel = (level?: TAlertLevel) => {
  switch (level) {
    case 'Critical':
      return [VscError, 'red'] as const
    case 'Info':
      return [HiOutlineInformationCircle, 'blue'] as const
    case 'Warning':
      return [TiWarningOutline, 'yellow'] as const
    default:
      return [FaRegQuestionCircle, 'gray'] as const
  }
}

export const AlertLevelBadge = ({ level }: { level?: TAlertLevel }) => {
  const { t } = useTranslation()
  const [Icon, color] = useMemo(() => getAlertLevel(level), [level])

  return (
    <Badge
      size="md"
      color={color}
      sx={theme => ({ border: `1px solid ${theme.colors[color][6]}` })}
      leftSection={<Icon />}
    >
      {t(`alert.${level}`)}
    </Badge>
  )
}
