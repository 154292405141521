import { showNotification } from '@mantine/notifications'
import { addColumn, addColumnSchema } from 'api/dashboards/addColumn'
import { AxiosError } from 'axios'
import { useFormMutation } from 'hooks/useFormMutation'
import { ErrorResponse } from 'shared/types'

export const useInsertColumn = ({
  rowId,
  handleSuccess,
}: {
  rowId: string
  handleSuccess: () => void
}) => {
  const onError = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data.errors) {
      showNotification({
        color: 'red',
        message: error.response.data.errors
          .map(error => error.message)
          .join(', '),
      })
      return
    }
    showNotification({
      color: 'red',
      message: 'An error occured while adding new column',
    })
  }

  const mutation = useFormMutation({
    mutationFn: addColumn(rowId),
    schema: addColumnSchema,
    onSuccess: handleSuccess,
    onError,
  })

  return mutation
}
