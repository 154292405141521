import { api } from 'api/api'

export type PublishDashboardOptions = {
  deviceId: string
  dashboardId: string
  isPublic: boolean
}
export const publishDashboard = ({
  dashboardId,
  deviceId,
  isPublic,
}: PublishDashboardOptions) =>
  api.put(
    `/v1/dashboards/devices/${deviceId}/dashboards/${dashboardId}/public`,
    {
      deviceId,
      dashboardId,
      isPublic,
    },
  )
