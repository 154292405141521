import { z } from 'zod'
import { api } from '..'

export const copyDeviceSchema = z.object({
  name: z.string(),
  copyParser: z.boolean().default(false),
  networkProvider: z.number().nullish(),
  description: z.string().nullish(),
})
export type TCopyDeviceFields = z.infer<typeof copyDeviceSchema>

export const copyDevice =
  (deviceId: string, parsersIds: string[]) => (data: TCopyDeviceFields) =>
    api
      .post(`/v1/devices/${deviceId}/copy`, data, {
        params: {
          parsersIds,
        },
      })
      .then(({ data }) => data)
