import { alertListItemSchema } from 'shared/types'
import { paginationSchema } from 'utils/paginationSchema'
import { uniqueArray } from 'utils/uniqueArray'
import { api, getDevicesDetails } from '..'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export type TAlertList = Awaited<ReturnType<typeof getAlertList>>
export type TAlertListItem = TAlertList['items'][number]
export const getAlertList = async (params?: Record<string, string>) => {
  const { items, ...data } = await api
    .get('/v1/alerts', {
      params,
    })
    .then(({ data }) => paginationSchema(alertListItemSchema).parse(data))

  const deviceIds = uniqueArray(items.map(item => item.deviceId))
  const devices = await getDevicesDetails(deviceIds)

  const newItems = items.map(item => ({
    ...item,
    device: devices.get(item.deviceId)!,
  }))

  return { ...data, items: newItems }
}

export const alertListQuery = (params?: Record<string, string>) => ({
  queryFn: () => getAlertList(params),
  queryKey: ['alerts', params],
})

export const useGetAlertList = (manualParams?: Record<string, string>) =>
  usePaginatedQuery(alertListQuery, manualParams)
