import { PropsWithChildren } from 'react'
import { Loader } from '../Loader'
import { QueryProps } from './Query'

export const QueryLoader = <T,>({
  query,
  children,
}: PropsWithChildren<Partial<QueryProps<T>>>) => {
  if (!query) throw new Error('Missing query data')

  const isLoading = query.isLoading

  if (children && isLoading) return <>{children}</>

  if (!children && isLoading) return <Loader />

  return null
}
