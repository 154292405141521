import { Stack } from '@mantine/core'
import { ReactNode } from 'react'
import { QueryProps } from './Query'

export type QueryErrorProps = {
  children: ((_error: unknown) => ReactNode | ReactNode[]) | ReactNode
}

export const QueryError = <T,>({
  children,
  query,
}: Partial<QueryProps<T>> & QueryErrorProps) => {
  if (!query) throw new Error('Missing query data')

  if (query.isError)
    return (
      <Stack data-testid="query-error">
        {children instanceof Function ? children(query.error) : children}
      </Stack>
    )

  return null
}
