import { Group, SelectItem } from '@mantine/core'
import { useParams } from 'react-router-dom'
import { InputTag } from './InputTag'
import { TagBadge } from './TagBadge'

export const AssignedTagsList = ({ data }: { data: SelectItem[] }) => {
  const { deviceId } = useParams()

  return (
    <Group spacing={5}>
      {data.map(tag => (
        <TagBadge deviceId={deviceId!} key={tag.value} {...tag} />
      ))}
      <InputTag
        deviceId={deviceId!}
        assignedTags={data.map(tag => tag.value)}
      />
    </Group>
  )
}
