import { Collapse, Skeleton, TitleProps } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import { useGetChartLabels } from 'api/chartLabels'
import { useGetChartMeasurementsPublic } from 'api/measurements/getChartMeasurementsPublic'
import { useDashboardStore } from 'components/dashboards/hooks/useDashboardStore'
import { ColumnTypeENUM } from 'data/columnType'
import dayjs from 'dayjs'
import { useDisclosureState } from 'hooks/useDisclosureState'
import { memo, useMemo } from 'react'
import Chart from 'react-apexcharts'
import { useSearchParams } from 'react-router-dom'
import { TIndicator } from 'shared/types'
import { findMinAndMaxValueForChart } from 'utils/findMinAndMaxValueForChart'
import { LabelPopover } from '../labels/LabelPopover'
import { MeasurementHeader } from './MeasurementAreaChart'
import { NumericalValue } from './NumericalValue'
import { useAreaChartOptions } from './useAreaChartOptions'

export type MeasurementAreaChartPublicProps = {
  indicator: TIndicator['code']
  deviceId: string
  dashboardId?: string
  name?: string
  disabled?: boolean
  collapsable?: boolean
  titleProps?: TitleProps
  columnType?: number
}

export type TLabelPopoverState = {
  chart: ApexChart
  xasix: { min: number; max: number }
  deviceId: string
  indicatorCode: string
}

export const MeasurementAreaChartPublic = memo(
  ({
    deviceId,
    dashboardId,
    columnType,
    indicator,
    name,
    disabled,
    collapsable,
    titleProps,
  }: MeasurementAreaChartPublicProps) => {
    const [opened, { toggle }] = useDisclosure(false)
    const [params, setParams] = useSearchParams()
    const queryParams = Object.fromEntries(params.entries())

    const isEditMode = useDashboardStore(state => state.isEditMode)
    const { isOpen, close, open, state } =
      useDisclosureState<TLabelPopoverState>(false)
    const measurements = useGetChartMeasurementsPublic(
      deviceId,
      dashboardId,
      indicator,
    )
    const labels = useGetChartLabels({
      deviceId: deviceId!,
      indicatorCode: indicator,
    })

    const chartType = useMemo(() => {
      switch (columnType) {
        case ColumnTypeENUM.LinearWith0:
        case ColumnTypeENUM.AutomaticAutoScale:
          return 'area'
        case ColumnTypeENUM.Columnar:
          return 'bar'
        default:
          return 'numericalValue'
      }
    }, [columnType])

    const parsedMeasurements = useMemo(() => {
      if (!measurements?.data || !measurements?.data?.length) {
        return []
      }
      return measurements?.data?.map?.(item => item?.value)
    }, [measurements])

    const { series, options } = useAreaChartOptions({
      disableToolbar: disabled,
      deviceId,
      title: indicator,
      measurements,
      labels,
      yaxisMinValue:
        columnType === ColumnTypeENUM.AutomaticAutoScale
          ? undefined
          : Number(findMinAndMaxValueForChart?.(parsedMeasurements)?.min),
      yaxisMaxValue:
        columnType === ColumnTypeENUM.AutomaticAutoScale
          ? undefined
          : Number(findMinAndMaxValueForChart?.(parsedMeasurements)?.max),
      events: {
        selection: (chart, { xaxis }) => {
          open({
            chart,
            deviceId: deviceId!,
            indicatorCode: indicator,
            xasix: xaxis as {
              min: number
              max: number
            },
          })
        },
        zoomed(_chart, { xaxis }) {
          const fromDate = dayjs(xaxis.min).toISOString()
          const toDate = dayjs(xaxis.max).toISOString()

          setTimeout(() => setParams({ ...queryParams, fromDate, toDate }), 10)
        },
      },
    })

    const { isLoading, isSuccess } = measurements

    if (isLoading && !collapsable) return <Skeleton animate height={300} />
    if (isSuccess && !collapsable)
      return (
        <LabelPopover {...{ isOpen, close, open, state }}>
          <div style={{ pointerEvents: isEditMode ? 'none' : 'auto' }}>
            <MeasurementHeader
              name={name}
              deviceId={deviceId!}
              indicatorCode={indicator}
              disabled={disabled || chartType === 'numericalValue'}
              titleProps={titleProps}
            />
            {chartType !== 'numericalValue' ? (
              <Chart
                height={350}
                type={chartType}
                options={options}
                series={series}
              />
            ) : (
              <NumericalValue indicator={indicator} deviceId={deviceId} />
            )}
          </div>
        </LabelPopover>
      )

    if (isLoading && collapsable) return <Skeleton animate height={35} />
    if (isSuccess && collapsable)
      return (
        <div>
          <MeasurementHeader
            onCollapse={toggle}
            deviceId={deviceId!}
            name={name}
            indicatorCode={indicator}
            disabled={disabled}
            titleProps={titleProps}
          />
          <Collapse in={opened}>
            {chartType !== 'numericalValue' ? (
              <Chart
                height={350}
                type={chartType}
                options={options}
                series={series}
              />
            ) : (
              <NumericalValue indicator={indicator} deviceId={deviceId} />
            )}
          </Collapse>
        </div>
      )
    return null
  },
)
MeasurementAreaChartPublic.displayName = 'MeasurementAreaChartPublic'
