import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { api } from '..'
import { getDefaultDeviceDashboard } from './deviceDashboards/getDeviceDefaultDashboard'
import { getDefaultDeviceModelDashboard } from './deviceModelDashboards/getDeviceModelDefaultDashboard'

export const dashboardColumnSchema = z.object({
  id: z.string(),
  name: z.string(),
  orderNo: z.number(),
  columnSize: z.string(),
  dashboardRowId: z.string(),
  indicator: z.string(),
  columnType: z.number(),
})

export const dashboardRowSchema = z.object({
  id: z.string(),
  orderNo: z.number(),
  dashboardId: z.string(),
  columns: z.array(dashboardColumnSchema).catch([]),
})

export const dashboardSchema = z.object({
  id: z.string(),
  name: z.string().nullable(),
  description: z.string().nullish(),
  isDefault: z.boolean(),
  isPublic: z.boolean(),
  rows: z.array(dashboardRowSchema).catch([]),
})
type TDashboardSchema = z.infer<typeof dashboardSchema>

export type TDashboard = Awaited<ReturnType<typeof getSingleDashboard>>
export type TDashboardRow = TDashboard['rows'][number]
export type TDashboardColumn = TDashboardRow['columns'][number]
export type TDashboardItemType = 'ROW' | 'COL'
export type TDashboardType = 'MODEL' | 'DEVICE'

export const mapDashboard = (
  dashboard: TDashboardSchema,
  type: TDashboardType,
) => ({
  ...dashboard,
  type,
  rows: dashboard.rows
    .sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1))
    .map(row => ({
      ...row,
      type: 'ROW' as TDashboardItemType,
      columns: row.columns
        .sort((a, b) => (a.orderNo > b.orderNo ? 1 : -1))
        .map(col => ({
          ...col,
          type: 'COL' as TDashboardItemType,
        })),
    })),
})

export const getSingleDashboard = async (
  dashboardId: string,
  deviceId: string,
  type: TDashboardType,
  captchaToken?: string,
) => {
  const dashboard = await api
    .get(`/v1/devices/${deviceId}/dashboards/${dashboardId}`, {
      params: {
        CfTurnstileResponse: captchaToken,
      },
    })
    .then(({ data }) => dashboardSchema.parse(data))

  return mapDashboard(dashboard, type)
}

export const getPublicDashboard = async (
  dashboardId: string,
  deviceId: string,
  captchaToken?: string,
) => {
  const dashboard = await api
    .get(`/v1/devices/${deviceId}/dashboards/public/${dashboardId}`, {
      params: {
        CfTurnstileResponse: captchaToken,
      },
    })
    .then(({ data }) => dashboardSchema.parse(data))

  return mapDashboard(dashboard, 'DEVICE')
}

export const ensureDashboard = async ({
  deviceId,
  deviceModelId,
}: {
  deviceId: string
  deviceModelId?: string
}) => {
  try {
    const deviceDashboard = await getDefaultDeviceDashboard(deviceId)

    return mapDashboard(deviceDashboard, 'DEVICE')
  } catch (error) {
    if (!deviceModelId) throw new Error('Missing deviceModelId')
    const modelDashboard = await getDefaultDeviceModelDashboard(deviceModelId)

    return mapDashboard(modelDashboard, 'MODEL')
  }
}

export const getDefaultDashbaordQueryKey = (deviceId: string) => [
  'dashboards',
  'device',
  deviceId,
  'default',
]

export const useGetDeviceDefaultDashboard = ({
  deviceId,
  deviceModelId,
  onSuccess,
}: {
  deviceId: string
  deviceModelId?: string
  onSuccess?: (data: TDashboard) => void
}) => {
  return useQuery({
    queryKey: getDefaultDashbaordQueryKey(deviceId),
    queryFn: () => ensureDashboard({ deviceId, deviceModelId }),
    retry: false,
    useErrorBoundary: false,
    onSuccess,
  })
}

export const useGetDashboard = ({
  deviceId,
  dashboardId,
}: {
  deviceId?: string
  dashboardId?: string
}) => {
  if (!deviceId) throw new Error('Missing deviceId ')
  return useQuery({
    queryKey: ['dashboards', 'device', deviceId, dashboardId],
    queryFn: () => getSingleDashboard(dashboardId!, deviceId!, 'DEVICE'),
    retry: false,
    useErrorBoundary: false,
    enabled: !!dashboardId,
  })
}

export const useGetPublicDashboard = ({
  deviceId,
  dashboardId,
  captchaToken,
}: {
  deviceId?: string
  dashboardId?: string
  captchaToken?: string
}) => {
  if (!deviceId || !dashboardId)
    throw new Error('Missing deviceId or dashboardId')
  return useQuery({
    queryKey: ['dashboards', 'device', deviceId, dashboardId, captchaToken],
    queryFn: () => getPublicDashboard(dashboardId, deviceId, captchaToken),
    retry: false,
    useErrorBoundary: false,
  })
}
