import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const AlertRuleList = lazy(() => import('../List'))
const CreateAlertRule = lazy(() => import('../Edit'))

export const alertRulesRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['alertRule.list'],
    element: <AlertRuleList />,
  }),
  createPanelRoute({
    path: r['alertRule.edit'](),
    element: <CreateAlertRule />,
  }),
]
