import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'
import { api } from '..'

export const availableDashboardListSchema = z.array(
  z.object({
    id: z.string(),
    name: z.string().nullish(),
    description: z.string().nullish(),
    isDefault: z.boolean(),
    disabled: z.boolean().default(false),
  }),
)
export type TAvailableDashboardList = z.infer<
  typeof availableDashboardListSchema
>

type TGetAvailableDashboardsParams = {
  deviceId: string
  modelId: string | null
}

export type TAvailableDashboardsList = Awaited<
  ReturnType<typeof getAvailableDashboards>
>
export const getAvailableDashboards = async ({
  deviceId,
  modelId,
}: TGetAvailableDashboardsParams) => {
  const deviceDashboardsPromise = api
    .get(`/v1/dashboards/devices/${deviceId}/dashboards`)
    .then(({ data }) => availableDashboardListSchema.parse(data))
  const modelDashboardsPromise = modelId
    ? api
        .get(`/v1/dashboards/deviceModels/${modelId}/dashboards`)
        .then(({ data }) => availableDashboardListSchema.parse(data))
    : Promise.resolve([])

  const [deviceDashboards, modelDashboards] = await Promise.all([
    deviceDashboardsPromise,
    modelDashboardsPromise,
  ])

  return [
    ...deviceDashboards,
    ...modelDashboards.filter(dashboard =>
      deviceDashboards.find(d => d.id === dashboard.id) ? false : true,
    ),
  ]
}

export const useGetAvailableDashboards = (
  data: TGetAvailableDashboardsParams,
) =>
  useQuery({
    queryKey: ['dashboards', 'available', data],
    queryFn: () => getAvailableDashboards(data),
  })
