import { ReactNode } from 'react'
import { QueryProps } from './Query'

export type QueryDataProps<T> = {
  children: ((_data: T) => ReactNode | ReactNode[]) | ReactNode
}

export const QueryData = <T,>({
  children,
  query,
}: Partial<QueryProps<T>> & QueryDataProps<T>) => {
  if (!query) throw new Error('Missing query data')

  if (
    query.data &&
    ((Array.isArray(query.data) && query.data.length > 0) ||
      Object.keys(query.data).length > 0)
  )
    return <>{children instanceof Function ? children(query.data) : children}</>

  return null
}
