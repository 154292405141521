import { Box, Divider, Stack, Title } from '@mantine/core'
import { PropsWithChildren } from 'react'
import { FontSettings } from './FontSettings'
import { LanguageSettings } from './LanguageSettings'
import { ThemeSettings } from './ThemeSettings'

export const DisplaySettings = () => {
  return (
    <Stack spacing={'xl'}>
      <LanguageSettings />
      <FontSettings />
      <ThemeSettings />
    </Stack>
  )
}

export const SettingsSection = ({
  title,
  children,
}: PropsWithChildren<{ title: string }>) => {
  return (
    <Box pos={'relative'}>
      <Title tt="uppercase" fw={700} fz="sm">
        {title}
      </Title>
      <Divider mt="xs" mb={'lg'} />
      {children}
    </Box>
  )
}
