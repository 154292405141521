import { SelectItem, Skeleton } from '@mantine/core'
import { useGetDeviceTags } from 'api/tags'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { LabeledContent, Query } from '..'
import { AssignedTagsList } from './AssignedTagsList'
import { InputTag } from './InputTag'

export const DeviceTags = () => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const deviceTagsQuery = useGetDeviceTags(deviceId!)

  return (
    <LabeledContent label={t('sensor.tags')}>
      <Query.Wrapper query={deviceTagsQuery}>
        <Query.Loader>
          <Skeleton animate height={50} w={200} />
        </Query.Loader>
        <Query.Data>
          {(data: SelectItem[]) => <AssignedTagsList data={data} />}
        </Query.Data>
        <Query.NoData title={t('generic.noData')}>
          <InputTag
            deviceId={deviceId!}
            assignedTags={
              deviceTagsQuery.data
                ? deviceTagsQuery.data.map(tag => tag.value)
                : []
            }
          />
        </Query.NoData>
      </Query.Wrapper>
    </LabeledContent>
  )
}
