import {
  Box,
  createStyles,
  getStylesRef,
  Group,
  rem,
  Text,
} from '@mantine/core'
import { NavLink, To } from 'react-router-dom'
import { IconType } from 'react-icons'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'

export interface LinkCollapse {
  label: string
  link: string
}

const useStyles = createStyles(
  (theme, { isCollapsed }: { isCollapsed: boolean }) => {
    const control = getStylesRef('control')
    return {
      control: {
        display: 'block',
        position: 'relative',
        textDecoration: 'none',
        ref: control,
        fontWeight: 500,
        width: isCollapsed ? 58 : 'auto',
        padding: `${rem(8)} ${theme.spacing.md}`,
        color: theme.colors.gray[3],
        fontSize: theme.fontSizes.md,
        background: 'transparent',
        borderRadius: '4px',
        transition: 'width .4s cubic-bezier(.25,.8,.25,1);',

        '&.is-active': {
          // padding: `${theme.spacing.xs} ${theme.spacing.md}`,
          color: theme.black,
          background: theme.fn.gradient({
            from: theme.colors.brand[4],
            to: theme.colors.brand[7],
            deg: 45,
          }),
        },
      },

      link: {
        transition: 'all .4s cubic-bezier(.25,.8,.25,1);',
        [`.${control}:hover &`]: {
          transform: 'translateX(10px)',
        },
      },

      navLink: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        textTransform: 'capitalize',
        width: 180,
      },

      sublink: {
        fontWeight: 500,
        display: 'block',
        textDecoration: 'none',
        padding: `${theme.spacing.xs} ${theme.spacing.md}`,
        paddingLeft: 30,
        marginLeft: 30,
        fontSize: theme.fontSizes.sm,
        color: theme.white,
        borderLeft: `1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.dark[4]
            : theme.colors.gray[3]
        }`,

        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[7]
              : theme.colors.gray[0],
          color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        },
      },

      chevron: {
        transition: 'transform 200ms ease',
      },
    }
  },
)

interface LinksItemProps {
  isCollapsed: boolean
  icon: IconType
  label: string
  href: To
}

export function LinkItem({
  icon: Icon,
  label,
  href,
  isCollapsed,
}: LinksItemProps) {
  const { classes, cx } = useStyles({ isCollapsed })
  const mobileMenuOpened = useCustomizerStore(state => state.mobileMenuOpened)
  const { toggleMobileMenu } = useCustomizerActions()

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          cx(classes.control, isActive && 'is-active', 'nav-link-control')
        }
        caseSensitive
        to={href}
        onClick={() => {
          if (mobileMenuOpened) toggleMobileMenu()
        }}
        data-testid={`navigate-${href.toString().substring(1)}`}
      >
        <Group className={classes.link} position="apart" spacing={0}>
          {href && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                textDecoration: 'none',
                color: 'inherit',
              }}
            >
              {Icon && <Icon size={28} />}
              <Text ml="md" className={cx(classes.navLink, 'link-label')}>
                {label}
              </Text>
            </Box>
          )}
        </Group>
      </NavLink>
    </>
  )
}
