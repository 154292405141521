import { useQuery } from '@tanstack/react-query'
import { Buffer } from 'buffer'
import { useDayjs } from 'hooks/useDayjs'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'
import loraPacket from 'lora-packet'
import { paginationSchema } from 'utils/paginationSchema'
import { uniqueArray } from 'utils/uniqueArray'
import { z } from 'zod'
import { api, getDevicesDetails } from '..'

export const packetSchema = z.object({
  id: z.string(),
  rawPacketId: z.string(),
  gatewayId: z.string().nullable(),
  gatewayEU: z.string(),
  deviceId: z.string(),
  devAdr: z.string().nullable(),
  gatewayName: z.string().nullish(),
  tmst: z.number(),
  time: z.string().nullable(),
  freq: z.number(),
  datr: z.string(),
  codr: z.string(),
  lsnr: z.number(),
  rssi: z.number(),
  fCnt: z.string(),
  data: z.string().nullable(),
})

export type TLoRaPacketFilters = Partial<{
  deviceId: string
  devAdr: string
  gatewayId: string
  results: number
  orderBy: string
  sortOrder: 'ASC' | 'DESC'
}>

export type TPacketList = Awaited<ReturnType<typeof getPacketList>>
export type TPacketListItem = TPacketList['items'][number]

export const getPacketList = async (filters?: TLoRaPacketFilters) => {
  const { items, ...data } = await api
    .get('/v1/lorawan/packets', {
      params: filters,
    })
    .then(({ data }) => paginationSchema(packetSchema).parse(data))

  const deviceIds = uniqueArray(
    items.map(item => item.deviceId).filter(Boolean),
  ) as string[]
  const deviceDetails = await getDevicesDetails(deviceIds)

  const newItems = items.map(item => {
    const packet = item.data
      ? loraPacket.fromWire(Buffer.from(item.data, 'base64'))
      : null

    return {
      ...item,
      device: item.deviceId ? deviceDetails.get(item.deviceId) : null,
      packet: packet
        ? {
            mType: packet.getMType(),
            ...Object.entries(packet).reduce<Record<string, Buffer>>(
              (acc, [key, val]) => {
                acc[key] = val.toString('hex')
                return acc
              },
              {},
            ),
          }
        : null,
    }
  })

  return { ...data, items: newItems }
}

export const packetListQuery = (filters?: TLoRaPacketFilters) => ({
  queryFn: () => getPacketList(filters),
  queryKey: ['packets', filters],
  refetchOnWindowFocus: true,
  refetchInterval: 30 * 1000,
})

export const useGetLoraPackets = (manualFilters?: TLoRaPacketFilters) =>
  usePaginatedQuery(packetListQuery, manualFilters)

const FIVE_MINUTES = 5 * 60 * 1000
export const useGetLastPacket = (filters: TLoRaPacketFilters) => {
  const dayjs = useDayjs()

  return useQuery({
    queryFn: () =>
      api
        .get('/v1/lorawan/packets', {
          params: filters,
        })
        .then(({ data }) => paginationSchema(packetSchema).parse(data)),
    queryKey: ['packets', filters],
    refetchOnWindowFocus: true,
    select: data =>
      data.items.length > 0 ? dayjs(data.items[0].time + '+00:00') : null,
    refetchInterval: FIVE_MINUTES,
  })
}
