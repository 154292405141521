import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'
import { UseQueryResult } from '@tanstack/react-query/src/types'
import { useSearchParams } from 'react-router-dom'

export const DEFAULT_RESULTS = 25
export const DEFAULT_PAGE = 1

export const usePaginatedQuery = <
  TParams extends Record<string, string | number | undefined>,
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: (
    params?: TParams,
  ) => Omit<
    UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'initialData'
  > & { initialData?: () => undefined },
  manualParams?: TParams,
): UseQueryResult<TData, TError> => {
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries()) as TParams
  // const params = {} as TParams
  return useQuery({
    ...options({
      page: DEFAULT_PAGE,
      results: DEFAULT_RESULTS,
      ...params,
      ...manualParams,
    }),
    keepPreviousData: true,
  })
}
