import { PropsWithChildren } from 'react'
import { Loader } from '../Loader'
import { QueryPaginatedProps } from './QueryPaginated'

export const QueryPaginatedLoader = <T,>({
  query,
  children,
}: PropsWithChildren<Partial<QueryPaginatedProps<T>>>) => {
  if (!query) throw new Error('Missing query data')

  const isLoading = query.isLoading

  if (children && isLoading) return <>{children}</>

  if (!children && isLoading) return <Loader />

  return null
}
