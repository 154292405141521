import { TAvailableDeviceTabs } from 'components/devices'

const prefix = ''

export const gatewayUrls = {
  'gateways.list': `${prefix}/gateways`,
  'gateways.view': (
    gatewayId = ':deviceId',
    tab: TAvailableDeviceTabs | ':tabValue' = ':tabValue',
  ) => `${prefix}/gateways/${gatewayId}/${tab}`,
  'gateways.edit': (gatewayId = ':deviceId', tab = ':tabValue') =>
    `${prefix}/gateways/${gatewayId}/edit/${tab}`,
}
