import { Button, Group, Stack, Title } from '@mantine/core'
import { createChartLabel, getChartLabelsQuery } from 'api/chartLabels'
import {
  InputColor,
  InputSubmit,
  InputText,
  InputTextarea,
} from 'components/form'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { generateRandomHexColor } from 'shared/utils'
import {
  createChartLabelSchema,
  TChartLabel,
  TCreateChartLabelFields,
  TCreateChartLabelResponse,
} from 'shared/types'
import { TLabelPopoverState } from '../area/MeasurementAreaChart'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { zodResolver } from '@hookform/resolvers/zod'
import { useSearchParams } from 'react-router-dom'

export const LabelForm = ({
  state,
  onCancel,
}: {
  state: TLabelPopoverState
  onCancel: () => void
}) => {
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())
  const initial = {
    deviceId: state.deviceId,
    indicatorCode: state.indicatorCode,
    fromDate: dayjs(params.fromDate).toISOString(),
    toDate: dayjs(params.toDate).toISOString(),
  }
  const queryKey = getChartLabelsQuery({
    ...initial,
    fromDate: params?.fromDate ? initial.fromDate : undefined,
    toDate: params?.toDate ? initial.toDate : undefined,
  }).queryKey
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const onSuccess = async (
    data: TCreateChartLabelResponse,
    newLabel: TCreateChartLabelFields,
  ) => {
    await queryClient.cancelQueries(queryKey)
    const oldData = queryClient.getQueryData(queryKey)

    queryClient.setQueryData<TChartLabel[]>(queryKey, old => {
      const newItem = {
        chartLabelId: data.chartLabelId,
        ...newLabel,
      }
      if (!old || old?.length === 0) return [newItem]
      return [...old, newItem]
    })

    onCancel()

    return { oldData, newLabel }
  }

  const methods = useForm<TCreateChartLabelFields>({
    resolver: zodResolver(createChartLabelSchema),
    defaultValues: {
      ...initial,
      dateFrom: initial.fromDate,
      dateTo: initial.toDate,
      hexColor: generateRandomHexColor(),
    } as unknown as TCreateChartLabelFields,
  })

  const { mutateAsync, isLoading } = useMutation({
    mutationFn: createChartLabel,
    onSuccess,
  })

  const onSubmit: SubmitHandler<TCreateChartLabelFields> = async data => {
    try {
      return await mutateAsync({
        ...data,
        dateFrom: dayjs(state.xasix.min).toISOString(),
        dateTo: dayjs(state.xasix.max).toISOString(),
      })
    } catch {
      return false
    }
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Stack>
          <Title order={4}>{t('measurement.addLabel')}</Title>
          <InputText required name="title" label={t('generic.name')} />
          <InputColor required name="hexColor" label={t('generic.color')} />
          <InputTextarea name="description" label={t('generic.description')} />
          <Group position="right">
            <Button type="button" variant="light" onClick={onCancel}>
              {t('generic.cancel')}
            </Button>
            <InputSubmit loading={isLoading} mt={0} value={t('generic.save')} />
          </Group>
        </Stack>
      </form>
    </FormProvider>
  )
}
