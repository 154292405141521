const prefix = ''

export const locationUrls = {
  'locations.list': `${prefix}/locations`,
  'locations.view': (locationId = ':locationId') =>
    `${prefix}/locations/${locationId}`,
  'locations.edit': (locationId = ':locationId', tab = ':tabValue') =>
    `${prefix}/locations/${locationId}/edit/${tab}`,
  'locations.create': `${prefix}/locations/create`,
  map: `${prefix}/map`,
  mapSensor: (deviceId = ':deviceId') => `${prefix}/map/sensor/${deviceId}`,
  sensor: (locationId = ':locationId', deviceId = ':deviceId') =>
    `${prefix}/locations/${locationId}/sensor/${deviceId}`,
}
