import { Pagination } from '../helper'
import { z } from 'zod'

export const tagSchema = z.object({
  id: z.string(),
  name: z.string(),
})

export const tagListSchema = z.object({
  tags: z.array(tagSchema),
})

export type TTag = z.infer<typeof tagSchema>

export type TTagListPaginated = Pagination<TTag>

export type TTagList = z.infer<typeof tagListSchema>
