import { z } from 'zod'

export const userSchema = z.object({
  email: z.string(),
  phoneNumber: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  state: z.string(),
  createdAt: z.string(),
  permissions: z.array(z.string()),
  role: z.string(),
})

export const signInResponseSchema = z.object({
  userId: z.string(),
  email: z.string(),
  role: z.string(),
  state: z.string(),
  createdAt: z.string(),
  phoneNumber: z.string().nullable(),
  firstName: z.string(),
  lastName: z.string(),
  permissions: z.array(z.string()),
})

export type TUser = z.infer<typeof userSchema>

export type TSignInResponse = z.infer<typeof signInResponseSchema>
