import { useQuery } from '@tanstack/react-query'
import { TTagList } from 'shared/types'
import { api } from '..'

export const getDeviceTags = (deviceId: string) =>
  api
    .get<TTagList>(`/v1/devices/${deviceId}/tags`)
    .then(({ data }) =>
      data.tags.map(tag => ({ label: tag.name, value: tag.id })),
    )

export const getDeviceTagsQuery = (deviceId: string) => ({
  queryKey: ['tags.device', deviceId],
  queryFn: () => getDeviceTags(deviceId),
})

export const useGetDeviceTags = (deviceId?: string) => {
  if (!deviceId) throw new Error('Missing deviceId')

  return useQuery(getDeviceTagsQuery(deviceId))
}
