import { Pagination } from '../helper'
import { z } from 'zod'

export const snmpListItemSchema = z.object({
  id: z.string(),
  ipAddress: z.string(),
  port: z.number(),
  community: z.string(),
})

export type TSnmpListItem = z.infer<typeof snmpListItemSchema>

export type TSnmpDeviceList = Pagination<TSnmpListItem>
