import { Pagination } from '../helper'
import { z } from 'zod'

export const deviceGroupItemSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  locationId: z.string().nullable(),
  countDevice: z.number(),
})

export type TDeviceGroupItem = z.infer<typeof deviceGroupItemSchema>

export type TDeviceGroupList = Pagination<TDeviceGroupItem>
