import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const AISubsystem = lazy(() => import('../View/index'))

export const aiSubsystemRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['aiSubsystem'],
    element: <AISubsystem />,
  }),
]
