import { z } from 'zod'

export const locationSchema = z.object({
  id: z.string(),
  name: z.string(),
  description: z.string().nullable(),
  boundaries: z.array(
    z.object({
      latitude: z.number(),
      longitude: z.number(),
    }),
  ),
})

export type TLocation = z.infer<typeof locationSchema>
