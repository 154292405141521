import { ActionIcon, Flex, MantineSize, TextInput } from '@mantine/core'
import { MouseEventHandler, useState } from 'react'
import { TbCopy, TbEye, TbEyeOff } from 'react-icons/tb'

export const CodeBox = ({
  label,
  value,
  withHideButton,
  size = 'xs',
  transformValue = (value: string) => value.toUpperCase(),
}: {
  label?: string
  value: string
  withHideButton?: boolean
  size?: MantineSize
  transformValue?: (value: string) => string
}) => {
  const [isHidden, setIsHidden] = useState(true)

  const onCopy: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    navigator.clipboard.writeText(value)
  }

  const onHide: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation()
    setIsHidden(prev => !prev)
  }

  const boxValue =
    withHideButton && isHidden ? value.replace(/\S/g, '•') : value

  return (
    <TextInput
      size={size}
      label={label}
      value={transformValue(boxValue)}
      styles={{
        input: {
          fontFamily: 'monospace',
        },
      }}
      readOnly
      rightSection={
        <Flex ml={withHideButton ? '-2em' : 0}>
          <ActionIcon variant="light" onClick={onCopy}>
            <TbCopy color="currentColor" size="1em" />
          </ActionIcon>
          {withHideButton && (
            <ActionIcon variant="light" onClick={onHide}>
              {isHidden ? (
                <TbEye color="currentColor" size="1em" />
              ) : (
                <TbEyeOff color="currentColor" size="1em" />
              )}
            </ActionIcon>
          )}
        </Flex>
      }
    />
  )
}
