import { z } from 'zod'
import { api } from '..'

const addRowResponse = z.object({
  rowId: z.string(),
})
export type TAddRowResponse = z.infer<typeof addRowResponse>

export const addRow = ({ dashboardId }: { dashboardId: string }) =>
  api
    .put(`/v1/dashboards/${dashboardId}/rows`)
    .then(({ data }) => addRowResponse.parse(data))

export const deleteRow =
  ({ dashboardId, rowId }: { dashboardId: string; rowId: string }) =>
  () =>
    api.delete(`/v1/dashboards/${dashboardId}/rows/${rowId}`)
