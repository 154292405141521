import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const LoraUdpLogsList = lazy(() => import('../LoraUdpLogsList'))
const LoraPacketList = lazy(() => import('../LoraPacketList'))
const SnmpPacketList = lazy(() => import('../SnmpPacketList'))
const RawPacketTabs = lazy(() => import('../RawPacketList/RawPacketTabs'))

export const packetsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['loraUdp.list'],
    element: <LoraUdpLogsList />,
  }),
  createPanelRoute({
    path: r['loraPacket.list'],
    element: <LoraPacketList />,
  }),
  createPanelRoute({
    path: r['snmpPacket.list'],
    element: <SnmpPacketList />,
  }),
  createPanelRoute({
    path: r['rawPacket.list'](),
    element: <RawPacketTabs />,
  }),
]
