import { z } from 'zod'
import { api } from '..'

export const createDownlinkPresetSchema = z.object({
  title: z.string(),
  insertMode: z.string().transform(val => Number(val)),
  fPort: z.number(),
  payload: z
    .string()
    .trim()
    .transform(val => val.toUpperCase()),
})
export const createDownlinkPresetResponse = z.object({
  id: z.string(),
})

export type TCreateDownlinkPresetFields = z.infer<
  typeof createDownlinkPresetSchema
>

export const createDownlinkPreset =
  (deviceModelId: string) => async (data: TCreateDownlinkPresetFields) =>
    api
      .post(`/v1/lorawan/devices/${deviceModelId}/messages/templates`, {
        deviceModelId,
        ...data,
      })
      .then(({ data }) => createDownlinkPresetResponse.parse(data))
