import { z } from 'zod'

export const paginationSchema = <T extends z.ZodTypeAny>(itemSchema: T) =>
  z.object({
    items: z.array(itemSchema),
    empty: z.boolean(),
    currentPage: z.number(),
    resultsPerPage: z.number(),
    totalPages: z.number(),
    totalResults: z.number(),
    hasPreviousPage: z.boolean(),
    hasNextPage: z.boolean(),
  })
