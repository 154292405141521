import { Center, SegmentedControl, Text } from '@mantine/core'
import { useQueryClient } from '@tanstack/react-query'
import { api } from 'api/api'
import { useGetLanguages } from 'api/translations'
import { Loader } from 'components/common'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Flag } from '../LanguagePicker'
import { SettingsSection } from './DisplaySettings'

export const LanguageSettings = () => {
  const queryClient = useQueryClient()
  const { t, i18n } = useTranslation()
  const { data, isLoading, isSuccess } = useGetLanguages()
  const [val, setVal] = useState(() => i18n.language)

  const handleSelect = (lang: string) => {
    setVal(lang)
    i18n.changeLanguage(lang)
    api.defaults.headers.common['Accept-Language'] = i18n?.language
    queryClient.invalidateQueries()
  }

  const items = useMemo(
    () =>
      data && isSuccess
        ? Array.from(data).map(([code, { name }]) => {
            return {
              value: code,
              label: (
                <Center>
                  <Flag code={code} name={name} />
                  <Text ml={10}>{name}</Text>
                </Center>
              ),
            }
          })
        : [],
    [data, isSuccess],
  )

  return (
    <SettingsSection title={t('generic.language')}>
      {isLoading && <Loader />}
      {isSuccess && (
        <SegmentedControl
          fullWidth
          value={val}
          onChange={handleSelect}
          data={items}
        />
      )}
    </SettingsSection>
  )
}
