import { Group, Menu, UnstyledButton, createStyles } from '@mantine/core'
import { r } from 'config/routes'
import { useMemo, useState } from 'react'
import { TbChevronDown } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { useAuthStore } from 'store/authStore'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'
const useStyles = createStyles((theme, { opened }: { opened: boolean }) => ({
  control: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 15px',
    borderRadius: theme.radius.sm,
    border: `1px solid ${
      theme.colorScheme === 'dark' ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    transition: 'background-color 150ms ease',
    backgroundColor:
      theme.colorScheme === 'dark'
        ? theme.colors.dark[opened ? 5 : 7]
        : opened
        ? theme.colors.gray[0]
        : theme.white,

    '&:hover': {
      backgroundColor:
        theme.colorScheme === 'dark'
          ? theme.colors.dark[5]
          : theme.colors.gray[0],
    },
  },

  label: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm,
  },

  icon: {
    transition: 'transform 150ms ease',
    transform: opened ? 'rotate(180deg)' : 'rotate(0deg)',
  },
}))

export type TWorkspaceType = 'lorawan' | 'snmp' | 'admin'
const workspaces = new Map<TWorkspaceType, { label: string }>()
workspaces.set('lorawan', { label: 'LoRaWAN' })
// workspaces.set('snmp', { label: 'SNMP' })
workspaces.set('admin', { label: 'Admin' })

export function WorkspacePicker() {
  const navigate = useNavigate()
  const [opened, setOpened] = useState(false)
  const { classes } = useStyles({ opened })
  const selectedWorkspace = useCustomizerStore(state => state.selectedWorkspace)
  const { setWorkspace } = useCustomizerActions()
  const handleSelect = (id: TWorkspaceType) => () => {
    navigate(r['panel'])
    setWorkspace(id)
  }
  const userRoles = useAuthStore(state => state.user?.role)

  const admin = userRoles?.includes('Admin')

  const items = useMemo(
    () =>
      Array.from(workspaces)
        .filter(workspace =>
          workspace[0] === 'admin'
            ? userRoles?.includes('Admin')
              ? true
              : false
            : true,
        )
        .map(([id, { label }]) => {
          return (
            <Menu.Item onClick={handleSelect(id)} key={id}>
              {label}
            </Menu.Item>
          )
        }),
    [userRoles],
  )

  return admin ? (
    <Menu
      onOpen={() => setOpened(true)}
      onClose={() => setOpened(false)}
      radius="sm"
      width={'target'}
      transitionProps={{
        transition: 'pop-top-left',
      }}
    >
      <Menu.Target>
        <UnstyledButton className={classes.control + ' workspace-picker'}>
          <Group spacing="xs">
            <span className={classes.label}>
              {workspaces.get(selectedWorkspace)?.label}
            </span>
          </Group>
          <TbChevronDown size={16} className={classes.icon} />
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>{items}</Menu.Dropdown>
    </Menu>
  ) : null
}
