import { z } from 'zod'

export const loraDeviceSchema = z.object({
  deviceId: z.string(),
  eui: z.string(),
  appEUI: z.string(),
  appKey: z.string(),
  appNonce: z.string(),
  devAddr: z.string(),
  appSkey: z.string(),
  nwkSKey: z.string(),
})

export type TLoraDevice = z.infer<typeof loraDeviceSchema>
