import { z } from 'zod'
import { api } from '..'

export const sendDownlinkMessageSchema = z.object({
  type: z.string().transform(val => Number(val)),
  port: z.number(),
  payload: z
    .string()
    .trim()
    .transform(val => val.toUpperCase()),
  confirmed: z.boolean().nullish(),
})
export const sendDownlinkMessageResponse = z.object({
  id: z.string(),
})

export type TSendDownlinkMessageFields = z.infer<
  typeof sendDownlinkMessageSchema
>

export const sendDownlinkMessage =
  (deviceId: string) => (data: TSendDownlinkMessageFields) =>
    api
      .post(`/v1/lorawan/devices/${deviceId}/messages/downlink`, {
        deviceId,
        ...data,
        payload: data.payload.split(' ').join(''),
        type: data.type,
        confirmed: data?.confirmed || false,
      })
      .then(({ data }) => sendDownlinkMessageResponse.parse(data))
