import { z } from 'zod'

export const addDeviceGroupSchema = z.object({
  name: z.string(),
  description: z.string().max(100).nullish(),
})

export type TAddDeviceGroupFields = z.infer<typeof addDeviceGroupSchema>

export const addDeviceGroupResponseSchema = z.object({
  deviceGroupId: z.string(),
})

export type TAddDeviceGroupResponse = z.infer<
  typeof addDeviceGroupResponseSchema
>
