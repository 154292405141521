import axios from 'axios'
import { useAuthStore } from 'store/authStore'
import { api } from './api'
import { refreshCookie } from './auth/refreshToken'

/** @type {Array} */
let requestsToRefresh: any[] = []

/** @type {boolean} */
let isRefreshRequesting = false

export const setupAxios = () => {
  api.interceptors.request.use(
    async config => {
      // @ts-ignore
      if (!config.url) console.error('req.url is not defined')

      return config
    },
    err => Promise.reject(err),
  ),
    api.interceptors.response.use(
      response => response,
      err => {
        const { response, config } = err

        if (response.status === 401) {
          if (!isRefreshRequesting) {
            isRefreshRequesting = true

            refreshCookie()
              .then(({ data }) => {
                requestsToRefresh.forEach(cb => cb(true))
              })
              .catch(() => {
                useAuthStore.setState({
                  isLoggedIn: false,
                  user: null,
                })
              })
              .finally(() => {
                requestsToRefresh = []
                isRefreshRequesting = false
              })
          }

          return new Promise((resolve, reject) => {
            requestsToRefresh.push((isSuccess: boolean) => {
              if (isSuccess) {
                resolve(axios(config))
              }
              reject(err)
            })
          })
        }

        return Promise.reject(err)
      },
    )
}

export const revokeToken = () => {
  axios.interceptors.request.use(
    config => {
      // @ts-ignore
      config.headers!.Authorization = false
      // @ts-ignore
      delete config.headers!.Authorization
      return config
    },
    err => Promise.reject(err),
  )
}
