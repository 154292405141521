import { TAlertRuleList } from 'shared/types'
import { api } from '..'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export const getAlertRuleList = (params?: Record<string, string>) =>
  api
    .get<TAlertRuleList>('/v1/alert-rules', { params })
    .then(({ data }) => data)

export const alertRuleListQuery = (params?: Record<string, string>) => ({
  queryFn: () => getAlertRuleList(params),
  queryKey: ['alertRules', params],
})

export const useGetAlertRuleList = (manualParams?: Record<string, string>) =>
  usePaginatedQuery(alertRuleListQuery, manualParams)
