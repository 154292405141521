import { z } from 'zod'
import { Pagination } from '../helper'
import { AlertLevelEnum } from './TAlertLevel'

export const alertRuleSchema = z.object({
  id: z.string(),
  level: AlertLevelEnum.optional(),
  name: z.string().nullable(),
  active: z.boolean(),
  sendSms: z.boolean(),
  sendEmail: z.boolean(),
})

export type TAlertRule = z.infer<typeof alertRuleSchema>

export type TAlertRuleList = Pagination<TAlertRule>
