import { forwardRef, PropsWithChildren } from 'react'
import {
  Avatar,
  Group,
  MediaQuery,
  Menu,
  Text,
  UnstyledButton,
} from '@mantine/core'
import { TbChevronRight } from 'react-icons/tb'

export interface UserButtonProps
  extends React.ComponentPropsWithoutRef<'button'> {
  image?: string
  firstName: string | null
  lastName: string | null
  email: string
}

const UserButton = forwardRef<HTMLButtonElement, UserButtonProps>(
  ({ image, firstName, lastName, email, ...others }: UserButtonProps, ref) => (
    <UnstyledButton
      data-testid={'user-dropdown-button'}
      ref={ref}
      sx={theme => ({
        display: 'block',
        width: '100%',
        padding: theme.spacing.md,
        color:
          theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,
        '&:hover': {
          backgroundColor:
            theme.colorScheme === 'dark'
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
      {...others}
    >
      <Group>
        <Avatar src={image} radius="xl">
          {firstName !== null && firstName.substring(0, 1)}
          {lastName !== null && lastName.substring(0, 1)}
        </Avatar>

        <MediaQuery smallerThan={'md'} styles={{ display: 'none' }}>
          <div style={{ flex: 1 }}>
            <Text size="xs" weight={500}>
              {firstName} {lastName}
            </Text>
            <Text color="dimmed" size="xs">
              {email}
            </Text>
          </div>
        </MediaQuery>

        <TbChevronRight size={16} />
      </Group>
    </UnstyledButton>
  ),
)

UserButton.displayName = 'UserButton'

export const UserButtonMenu = ({
  children,
  ...user
}: PropsWithChildren<UserButtonProps>) => {
  return (
    <Group position="center">
      <Menu
        zIndex={10}
        withArrow
        transitionProps={{
          transition: 'pop-top-right',
        }}
      >
        <Menu.Target>
          <UserButton {...user} />
        </Menu.Target>
        {children}
      </Menu>
    </Group>
  )
}
