import { UseQueryResult } from '@tanstack/react-query'
import {
  Children,
  cloneElement,
  isValidElement,
  PropsWithChildren,
  ReactElement,
} from 'react'
import { ErrorFallbackPure } from '../ErrorView'
import { QueryData, QueryDataProps } from './QueryData'
import { QueryError, QueryErrorProps } from './QueryError'
import { QueryLoader } from './QueryLoader'
import { QueryNoData, QueryNoDataProps } from './QueryNoData'

export type QueryProps<T> = {
  query: UseQueryResult<T>
  disableNoDataComponent?: boolean
  disableErrorComponent?: boolean
}

const QueryWrapper = <T,>({
  query,
  children,
  disableNoDataComponent,
  disableErrorComponent,
}: PropsWithChildren<QueryProps<T>>) => {
  const childrenArray = Children.toArray(children)

  const loader = childrenArray.find(
    child => isValidElement(child) && child.type === QueryLoader,
  ) as ReactElement<PropsWithChildren<Partial<QueryProps<T>>>>
  const noData = childrenArray.find(
    child => isValidElement(child) && child.type === QueryNoData,
  ) as ReactElement<QueryNoDataProps<T>>
  const data = childrenArray.find(
    child => isValidElement(child) && child.type === QueryData,
  ) as ReactElement<
    PropsWithChildren<Partial<QueryProps<T>> & QueryDataProps<T>>
  >
  const error = childrenArray.find(
    child => isValidElement(child) && child.type === QueryError,
  ) as ReactElement<PropsWithChildren<Partial<QueryProps<T>> & QueryErrorProps>>

  if (!data) return null

  return (
    <>
      {isValidElement(loader) ? (
        cloneElement(loader, { ...loader.props, query })
      ) : (
        <QueryLoader query={query} />
      )}
      {isValidElement(data) ? (
        cloneElement(data, { ...data.props, query })
      ) : (
        <QueryLoader query={query} />
      )}
      {isValidElement(error) ? (
        cloneElement(error, { ...error.props, query })
      ) : disableErrorComponent ? null : (
        <QueryError query={query}>
          {error => <ErrorFallbackPure error={error} />}
        </QueryError>
      )}
      {isValidElement(noData) ? (
        cloneElement(noData, { ...noData.props, query, disableNoDataComponent })
      ) : (
        <QueryNoData
          title="No data"
          query={query}
          disableNoDataComponent={disableNoDataComponent}
        />
      )}
    </>
  )
}

export const Query = {
  Wrapper: QueryWrapper,
  Loader: QueryLoader,
  Data: QueryData,
  NoData: QueryNoData,
  Error: QueryError,
}
