import { api } from '..'
import { z } from 'zod'
import { paginationSchema } from 'utils/paginationSchema'
import { usePaginatedQuery } from 'hooks/usePaginatedQuery'

export const oidListItemSchema = z.object({
  id: z.string(),
  objectIdentifier: z.string(),
  description: z.string().nullable(),
  oidIndicator: z.string().nullable(),
})

export const oidListSchema = paginationSchema(oidListItemSchema)

export type TOidList = Awaited<ReturnType<typeof getOids>>
export type TOidListItem = TOidList['items'][number]
export const getOids = (params?: Record<string, string>) =>
  api
    .get('/v1/snmp/oids', { params })
    .then(({ data }) => oidListSchema.parse(data))

export const oidsQuery = (params?: Record<string, string>) => ({
  queryKey: ['oids', params],
  queryFn: () => getOids(params),
})

export const useGetOids = (manualParams?: Record<string, string>) =>
  usePaginatedQuery(oidsQuery, manualParams)
