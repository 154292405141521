import { Text, Tooltip } from '@mantine/core'
import {
  TDownlinkMessage,
  TDownlinkMessagesList,
} from 'api/loraDevices/getDownlinkMessages'
import { IconButton } from 'components/common'
import Datatable, { TDatatable } from 'components/datatable'
import { TFunction } from 'i18next'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FiMousePointer, FiPlus } from 'react-icons/fi'
import { HiCheckCircle, HiXCircle } from 'react-icons/hi'
import { openCreateDownlinkPreset } from './CreateDownlinkPreset'

const headers: (
  t: TFunction,
  deviceModelId: string,
) => TDatatable<TDownlinkMessagesList['items'][number]>[] = (
  t,
  deviceModelId,
) => [
  {
    id: 'date',
    label: t('generic.date'),
    colSize: 1,
    selector: (item, { dayjs }) => (
      <Text>{dayjs(item.createdAt).format('DD.MM.YYYY, HH:mm:ss')}</Text>
    ),
  },
  {
    id: 'payload',
    label: 'Payload',
    colSize: 2,
    selector: item => (
      <Text sx={{ fontFamily: 'monospace' }} truncate>
        {item.payload}
      </Text>
    ),
  },
  {
    id: 'port',
    label: 'Port',
    colSize: 1,
    selector: item => <Text>{item.port}</Text>,
  },
  {
    id: 'type',
    label: t('generic.type'),
    colSize: 1,
    selector: item => (
      <Text>
        {item?.type === 1 ? 'PUSH' : null}
        {item?.type === 2 ? 'REPLACE' : null}
        {!item?.type ? 'NoData' : null}
      </Text>
    ),
  },
  {
    id: 'status',
    label: 'Status',
    colSize: 1,
    selector: item => (
      <Tooltip label={item.isSent ? 'Sent' : 'Failed'}>
        <Text span color={item.isSent ? 'green' : 'red'}>
          {item.isSent ? (
            <HiCheckCircle size="1.2rem" />
          ) : (
            <HiXCircle size="1.2rem" />
          )}
        </Text>
      </Tooltip>
    ),
  },
  {
    id: 'status',
    label: 'Confirmed',
    colSize: 1,
    selector: item => (
      <>
        {item?.isConfirmed === null ? (
          <Text>N/A</Text>
        ) : (
          <Tooltip
            label={
              item.isConfirmed
                ? t('loraDevices.confirmed')
                : t('loraDevices.NotConfirmed')
            }
          >
            <Text span color={item.isConfirmed ? 'green' : 'red'}>
              {item.isConfirmed ? (
                <HiCheckCircle size="1.2rem" />
              ) : (
                <HiXCircle size="1.2rem" />
              )}
            </Text>
          </Tooltip>
        )}
      </>
    ),
  },
  {
    id: 'actions',
    label: '',
    colSize: 0.25,
    selector: item => <ResendMessage {...item} />,
  },
  {
    id: 'actions2',
    label: '',
    colSize: 0.25,
    selector: item => <AddPreset {...item} deviceModelId={deviceModelId} />,
  },
]

export const DownlinkMessageTable = ({
  deviceModelId,
  data,
}: {
  deviceModelId: string
  data: TDownlinkMessagesList
}) => {
  const { t } = useTranslation()

  return (
    <Datatable noShadow headers={headers(t, deviceModelId)} data={data.items} />
  )
}

export const ResendMessage = memo(
  ({ payload, port, type }: TDownlinkMessage) => {
    const { setValue } = useFormContext()

    const onClick = () => {
      const presetItem = {
        type: type?.toString(),
        port,
        payload,
      }

      Object.entries(presetItem).forEach(([key, value]) => setValue(key, value))
    }

    return (
      <IconButton
        data-testid={'use-this-preset-device-button'}
        icon={FiMousePointer}
        onClick={onClick}
        label="Use this preset"
      />
    )
  },
)

export const AddPreset = memo(
  ({
    payload,
    port,
    type,
    deviceModelId,
  }: TDownlinkMessage & { deviceModelId: string }) => {
    return (
      <IconButton
        data-testid={'add-this-preset-device-button'}
        icon={FiPlus}
        onClick={() =>
          openCreateDownlinkPreset(deviceModelId, port, payload, type)
        }
        label="Add this preset"
      />
    )
  },
)
