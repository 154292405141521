import { TFunction } from 'i18next'

type SelectItem = {
  value: string
  label: string
}

export enum ColumnTypeENUM {
  LinearWith0 = 1,
  AutomaticAutoScale = 2,
  Columnar = 5,
  NumericalValue = 6,
}

const columnTypeArray = (t: TFunction) => [
  {
    value: 1,
    label: t('columnType.linearIncludingZero'),
  },
  {
    value: 2,
    label: t('columnType.linearAutoscale'),
  },
  {
    value: 5,
    label: t('columnType.columnar'),
  },
  {
    value: 6,
    label: t('columnType.numericalValue'),
  },
]

export const columnType = (t: TFunction) =>
  new Map(
    columnTypeArray(t).map(({ value, ...rest }) => {
      return [value, rest]
    }),
  )

export const columnTypeOptions = (t: TFunction): SelectItem[] =>
  columnTypeArray(t).map(({ value, label }) => ({
    value: String(value),
    label,
  }))
