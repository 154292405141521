import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { TMeasurementsChart } from 'shared/types'
import { transformMeasurements } from 'utils/transformMeasurements'
import { api } from '..'
import { formatValues } from './getChartMeasurements'

export const measurementsChartQueryKey = ({
  deviceId,
  dashboardId,
  indicatorCode,
  params,
}: {
  deviceId: string
  dashboardId: string
  indicatorCode: string
  params?: Record<string, string>
}) => [
  'measurements.chart.public',
  deviceId,
  dashboardId,
  indicatorCode,
  formatValues(v => dayjs(v).format('DD.MM.YYYY, HH:mm'), params),
]

export const getChartMeasurementsPublic = (
  deviceId: string,
  dashboardId: string,
  indicatorCode: string,
  params?: Record<string, string>,
) =>
  api
    .get<TMeasurementsChart>(
      `/v1/devices/${deviceId}/dashboards/${dashboardId}/chart-measurements`,
      {
        params: {
          deviceId,
          dashboardId,
          indicatorCode,
          ...params,
        },
      },
    )
    .then(({ data }) =>
      data && data.measurements ? transformMeasurements(data.measurements) : [],
    )

export const measurementChartQueryPublic = (
  deviceId: string,
  dashboardId: string,
  indicatorCode: string,
  params?: Record<string, string>,
) => {
  return {
    queryFn: () =>
      getChartMeasurementsPublic(deviceId, indicatorCode, dashboardId, params),
    queryKey: measurementsChartQueryKey({
      deviceId,
      dashboardId,
      indicatorCode,
      params,
    }),
  }
}
export const useGetChartMeasurementsPublic = (
  deviceId?: string,
  dashboardId?: string,
  indicatorCode?: string,
  manualParams?: { fromDate: string; toDate: string },
) => {
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())

  if (!deviceId || !indicatorCode || !dashboardId)
    throw new Error('Missing deviceId, indicatorCode or dashboardId')

  const query = measurementChartQueryPublic(
    deviceId,
    indicatorCode,
    dashboardId,
    manualParams ?? params,
  )

  return useQuery({
    ...query,
    keepPreviousData: true,
    useErrorBoundary: false,
    // staleTime: 1000 * 5 * 60,
  })
}
