import { z } from 'zod'

export const availableLogicalOperators = ['And', 'Or'] as const
export const logicalOperatorEnum = z.enum(availableLogicalOperators)
export type TLogicalOperator = z.infer<typeof logicalOperatorEnum>

export const logicalOperatorsResponseSchema = z.object({
  operators: z.array(
    z.object({
      value: logicalOperatorEnum,
    }),
  ),
})

export type TLogicalOperatorsResponse = z.infer<
  typeof logicalOperatorsResponseSchema
>
