import { z } from 'zod'
import { DeviceTypeEnum } from './DeviceTypes'

export const RegisterDeviceSchema = z.object({
  typeCode: DeviceTypeEnum,
  networkProvider: z
    .string()
    .transform(val => Number(val))
    .optional(),
})

export type TRegisterDeviceFields = z.infer<typeof RegisterDeviceSchema>

export const registerDeviceResponseSchema = z.object({
  deviceId: z.string(),
})

export type TRegisterDeviceResponse = z.infer<
  typeof registerDeviceResponseSchema
>
