import { Stack } from '@mantine/core'
import {
  TDashboard,
  useGetDeviceDefaultDashboard,
} from 'api/dashboards/getDashboard'
import { MeasurementAreaChart } from 'components/chart'
import { Query } from 'components/common'
import { Fragment } from 'react'

export const DeviceIndicatorsList = ({
  name,
  deviceId,
  deviceModelId,
}: {
  name?: string
  deviceId: string
  deviceModelId: string | null
}) => {
  const query = useGetDeviceDefaultDashboard({
    deviceId,
    deviceModelId: deviceModelId ?? undefined,
  })
  return (
    <Query.Wrapper query={query}>
      <Query.Data>
        {(data: TDashboard) => (
          <Stack>
            {data.rows.map(row => (
              <Fragment key={row.id}>
                {row.columns.map(col => (
                  <MeasurementAreaChart
                    key={col.id}
                    collapsable
                    columnType={col?.columnType}
                    name={name}
                    deviceId={deviceId}
                    indicator={col.indicator}
                    titleProps={{ weight: 500, order: 6 }}
                  />
                ))}
              </Fragment>
            ))}
          </Stack>
        )}
      </Query.Data>
    </Query.Wrapper>
  )
}
