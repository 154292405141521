import { ErrorView } from 'components'
import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { qrCodesRoutes } from 'views/panel/QrCodes/@Routes'
import { aiSubsystemRoutes } from '../AISubsystem/@Routes'
import { accountRoutes } from '../Account/@Routes'
import { alertRulesRoutes } from '../AlertRules/@Routes'
import { alertRoutes } from '../Alerts/@Routes'
import { DeviceGroupRoutes } from '../DeviceGroups/@Routes'
import { devicesRoutes } from '../Devices/@Routes'
import { emailsRoutes } from '../Emails/@Routes'
import { gatewayRoutes } from '../Gateways/@Routes'
import { indicatorsRoutes } from '../Indicators/@Routes'
import { labelsRoutes } from '../Labels/@Routes'
import { locationRoutes } from '../Locations/@Routes'
import { loraDevicesRoutes } from '../LoraDevices/@Routes'
import { measurementsRoutes } from '../Measurements/@Routes'
import { modelsRoutes } from '../Models/@Routes'
import { organizationRoutes } from '../Organization/@Routes'
import { packetsRoutes } from '../Packets/@Routes'
import { createPanelRoute } from '../PanelLayout'
import { parsersRoutes } from '../Parsers/@Routes'
import { snmpDevicesRoutes } from '../SNMP/@Routes'
import { snmpOidsRoutes } from '../SNMPOids/@Routes'
import { UserRoutes } from '../Users/@Routes'

const Dashboard = lazy(() => import('../Dashboard'))

export const panelRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['panel'],
    element: <Dashboard />,
    errorElement: <ErrorView to={r['index']} />,
  }),
  ...loraDevicesRoutes,
  ...gatewayRoutes,
  ...locationRoutes,
  ...organizationRoutes,
  ...DeviceGroupRoutes,
  ...UserRoutes,
  ...accountRoutes,
  ...alertRulesRoutes,
  ...measurementsRoutes,
  ...devicesRoutes,
  ...labelsRoutes,
  ...emailsRoutes,
  ...aiSubsystemRoutes,
  ...alertRoutes,
  ...snmpDevicesRoutes,
  ...parsersRoutes,
  ...indicatorsRoutes,
  ...modelsRoutes,
  ...packetsRoutes,
  ...snmpOidsRoutes,
  ...qrCodesRoutes,
]
