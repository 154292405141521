import { Anchor, Box, Center, Text } from '@mantine/core'
import { useCustomizerStore } from 'store/customizerStore'

export const SidebarFooter = () => {
  const sidebarColor = useCustomizerStore(state => state.sidebarColor)

  return (
    <Center
      pt="xl"
      sx={theme => ({
        backgroundColor:
          theme.colorScheme === 'dark' ? theme.colors.dark[9] : sidebarColor,
        borderTop: `1px solid ${
          theme.colorScheme === 'dark'
            ? theme.colors.dark[2]
            : theme.colors.gray[7]
        }`,
      })}
    >
      <Box>
        <Text size="sm" color="dimmed">
          ConnectedLife &copy; {new Date().getFullYear()}
        </Text>
        <Anchor
          color={'dimmed'}
          href="https://connectedlife.pl/contact"
          target={'_blank'}
          sx={{ display: 'block', textDecoration: 'underline' }}
          align="center"
          size="xs"
        >
          Contact support
        </Anchor>
      </Box>
    </Center>
  )
}
