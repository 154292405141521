import { ActionIcon } from '@mantine/core'
import { openConfirmModal } from '@mantine/modals'
import React, { MouseEventHandler } from 'react'
import { useTranslation } from 'react-i18next'
import { TbTrash } from 'react-icons/tb'
import { useDeleteColumn } from './hooks/useDeleteColumn'

export const DeleteDashboardColumn = ({
  rowId,
  columnId,
}: {
  rowId: string
  columnId: string
}) => {
  const { t } = useTranslation()
  const mutation = useDeleteColumn({ rowId, columnId })

  const onClick: MouseEventHandler = e => {
    e.stopPropagation()
    openConfirmModal({
      title: t('dashboard.deleteColumn'),
      children: t('dashboard.deleteColumnConfirm'),
      confirmProps: { color: 'red', loading: mutation.isLoading },
      labels: { confirm: t('generic.delete'), cancel: t('generic.cancel') },
      onConfirm: mutation.mutate,
    })
  }

  return (
    <ActionIcon onClick={onClick} variant="light" color="red">
      <TbTrash />
    </ActionIcon>
  )
}
