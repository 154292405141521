import { Kbd, TextInput, Flex, Box } from '@mantine/core'
import { useOs } from '@mantine/hooks'
import { useSpotlight } from '@mantine/spotlight'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TbSearch } from 'react-icons/tb'

export function SearchSpotlight() {
  const spotlight = useSpotlight()
  const { t } = useTranslation()
  const os = useOs()

  const [rightSection, width] = useMemo(
    () =>
      os !== 'ios' && os !== 'android'
        ? [
            <Flex key={os} align="center">
              <Kbd mr={5}>{os === 'macos' ? 'Cmd' : 'Ctrl'}</Kbd>
              <span>+</span>
              <Kbd ml={5}>K</Kbd>
            </Flex>,
            90,
          ]
        : [null, 0],
    [os],
  )

  return (
    <Box
      sx={theme => ({
        width: 100,
        [`${theme.fn.largerThan('md')}`]: {
          width: 'auto',
        },
      })}
    >
      <TextInput
        placeholder={t('generic.search') as string}
        icon={<TbSearch size="1rem" />}
        rightSectionWidth={width}
        rightSection={rightSection}
        onClick={() => spotlight.openSpotlight()}
        onChange={() => spotlight.openSpotlight()}
        sx={{ cursor: 'pointer' }}
        value=""
        styles={{ input: { cursor: 'pointer' } }}
      />
    </Box>
  )
}
