import {
  Burger,
  BurgerProps,
  createStyles,
  getStylesRef,
  Group,
  Navbar,
  ScrollArea,
  Stack,
  Text,
  useMantineTheme,
} from '@mantine/core'
import { useClickOutside, useMediaQuery } from '@mantine/hooks'
import { navigationConfig } from 'config/navigationConfig'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconType } from 'react-icons'
import { To } from 'react-router-dom'
import { useAuthStore } from 'store/authStore'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'
import { NavbarToggle } from '../common/NavbarToggle'
import { LinkItem } from './LinkItem'
import { LinkCollapse, LinksGroup } from './LinksGroup'
import { SidebarFooter } from './SidebarFooter'
import { SidebarHeader } from './SidebarHeader'
import { WorkspacePicker } from './WorkspacePicker'

export type NavigationItem = { label: string; permissionId?: string } & (
  | {
      type: 'link'
      icon: IconType
      href: To
    }
  | {
      type: 'divider'
    }
  | {
      type: 'linkGroup'
      icon: IconType
      links: LinkCollapse[]
    }
)

export const SidebarHamburger = (
  props: Omit<BurgerProps, 'opened' | 'onClick' | 'aria-label' | 'sx'>,
) => {
  const mobileMenuOpened = useCustomizerStore(state => state.mobileMenuOpened)
  const { toggleMobileMenu } = useCustomizerActions()
  const label = mobileMenuOpened ? 'Close navigation' : 'Open navigation'

  return (
    <Burger
      color={mobileMenuOpened ? 'white' : undefined}
      sx={theme => ({
        [`${theme.fn.largerThan('sm')}`]: {
          display: 'none',
        },
      })}
      opened={mobileMenuOpened}
      onClick={() => toggleMobileMenu()}
      aria-label={label}
      {...props}
    />
  )
}

export const VerticalSidebar = () => {
  const { t } = useTranslation()
  const isSidebarCollapsed = useCustomizerStore(state => state.sidebarCollapsed)
  const selectedWorkspace = useCustomizerStore(state => state.selectedWorkspace)
  const mobileMenuOpened = useCustomizerStore(state => state.mobileMenuOpened)
  const { toggleMobileMenu } = useCustomizerActions()
  const sidebarColor = useCustomizerStore(state => state.sidebarColor)
  const userPermissions = useAuthStore(state => state.user?.permissions) ?? []
  const theme = useMantineTheme()
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`)

  const navbarRef = useClickOutside(() => {
    if (!isSmallScreen || !mobileMenuOpened) return
    toggleMobileMenu()
  }, ['pointerdown'])

  const { classes } = useStyles({
    isCollapsed: isSidebarCollapsed,
    mobileMenuOpened,
    sidebarColor,
  })

  const items = useMemo(
    () => navigationConfig.get(selectedWorkspace)!(t),
    [t, selectedWorkspace],
  )

  const links = useMemo(
    () =>
      items
        .filter(
          item =>
            !item.permissionId || userPermissions.includes(item.permissionId),
        )
        .map((item, i) => {
          switch (item.type) {
            case 'divider':
              return (
                <Text
                  key={item.label + '-divider'}
                  className={classes.divider}
                  color={'dimmed'}
                  weight="500"
                  ml={'sm'}
                  mt="md"
                  transform="uppercase"
                  size={'sm'}
                >
                  {item.label}
                </Text>
              )
            case 'link':
              return (
                <LinkItem
                  isCollapsed={isSidebarCollapsed}
                  {...item}
                  key={i + '-' + item.label + '-link'}
                />
              )
            default:
              return (
                <LinksGroup
                  isCollapsed={isSidebarCollapsed}
                  {...item}
                  key={i + '-' + item.label + '-group'}
                />
              )
          }
        }),
    [userPermissions, isSidebarCollapsed, selectedWorkspace, items],
  )

  return (
    <Navbar ref={navbarRef} p="xl" className={classes.navbar}>
      <Navbar.Section mb="sm" className={classes.header}>
        <Group mb="sm" noWrap position="apart">
          <SidebarHeader />
          {isSmallScreen ? <SidebarHamburger /> : <NavbarToggle />}
        </Group>
        <WorkspacePicker />
      </Navbar.Section>

      <Navbar.Section
        grow
        className={classes.links}
        component={ScrollArea}
        type="auto"
      >
        <Stack spacing={'sm'} py={0} className={classes.linksInner}>
          {links}
        </Stack>
      </Navbar.Section>
      <Navbar.Section className={classes.footer} mt="sm">
        <SidebarFooter />
      </Navbar.Section>
    </Navbar>
  )
}

const useStyles = createStyles(
  (
    theme,
    {
      isCollapsed,
      mobileMenuOpened,
      sidebarColor,
    }: {
      isCollapsed: boolean
      mobileMenuOpened: boolean
      sidebarColor: string
    },
  ) => {
    return {
      navbar: {
        zIndex: 1001,
        paddingBottom: 0,
        height: '100dvh',
        position: 'fixed',
        background:
          theme.colorScheme === 'dark' ? theme.colors.dark[9] : sidebarColor,
        boxShadow: '0 0 15px #0000000d',
        overflow: 'hidden',

        width: isCollapsed ? 75 : 280,
        [`${theme.fn.smallerThan('sm')}`]: {
          pointerEvents: mobileMenuOpened ? 'auto' : 'none',
          width: mobileMenuOpened ? 280 : 0,
          padding: mobileMenuOpened ? undefined : 0,
        },
        [`${theme.fn.largerThan('sm')}`]: {
          '&:hover': {
            width: 280,
          },
        },

        transition: 'width .4s cubic-bezier(.25,.8,.25,1)',

        '.nav-toggle': {
          display: isCollapsed ? 'none' : 'block',
        },

        '&:hover .nav-toggle': {
          display: 'block',
        },

        '&:hover .logo-text, .logo-text': {
          transform: 'scaleX(1)',
        },

        '.logo-text': {
          transform: isCollapsed ? 'scaleX(0)' : 'scaleX(1)',
        },

        '.workspace-picker': {
          display: isCollapsed ? 'none' : 'flex',
        },
        '&: hover .workspace-picker': {
          display: 'flex',
        },

        '&:hover .nav-link, &:hover .nav-link-control': {
          width: '100%',
        },

        '.nav-collapse': {
          display: isCollapsed ? 'none' : 'block',
        },

        '&:hover .nav-collapse': {
          display: 'block',
        },

        [`&:hover .${getStylesRef('divider')}, &:hover .${getStylesRef(
          'footer',
        )}`]: {
          display: 'block',
        },
      },

      divider: {
        ref: getStylesRef('divider'),
        display: isCollapsed ? 'none' : 'block',
      },

      header: {
        paddingInline: theme.spacing.xs,
        paddingTop: 0,
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        color: theme.colors.brand[5],
      },

      links: {
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
      },

      linksInner: {
        paddingBlock: theme.spacing.xl,
      },

      footer: {
        ref: getStylesRef('footer'),
        marginLeft: `calc(${theme.spacing.md} * -1)`,
        marginRight: `calc(${theme.spacing.md} * -1)`,
        display: isCollapsed ? 'none' : 'block',
      },
    }
  },
)
