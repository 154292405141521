import { Button, Group, Stack } from '@mantine/core'
import { closeAllModals, openModal } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import {
  TExportDashboardToCSVFields,
  exportDashboardTOCSV,
  exportDashboardToCSVSchema,
} from 'api/dashboards/exportDashboardToCSV'
import { InputDatetimePicker } from 'components/form/input/Input_Datetime'
import dayjs from 'dayjs'
import { useFormMutation } from 'hooks/useFormMutation'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsCalendar2Date } from 'react-icons/bs'
import { FaFileCsv } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { capitalizeFirstLetter } from 'shared/utils'
import { HookFormError, IconButton, InputSubmit } from '..'

export const ExportDashboardToCSVButton = ({
  id,
  name,
  fromDate,
  toDate,
}: {
  id: string
  name: string
  fromDate?: string
  toDate?: string
}) => {
  const { t } = useTranslation()
  const { deviceId } = useParams()
  const onClick = () => {
    openModal({
      title: t('dashboard.exportToCSVFile'),
      size: 'lg',
      children: (
        <ExportDashboardToCSVForm
          mutationFn={exportDashboardTOCSV({
            deviceId: deviceId!,
            dashboardId: id!,
            dashboardName: name!,
          })}
          fromDate={fromDate}
          toDate={toDate}
        />
      ),
    })
  }

  return (
    <IconButton
      size={'lg'}
      onClick={onClick}
      icon={FaFileCsv}
      label={t('dashboard.exportToCSVFile')}
    />
  )
}

export const ExportDashboardToCSVForm = ({
  mutationFn,
  fromDate,
  toDate,
}: {
  mutationFn: (data: TExportDashboardToCSVFields) => Promise<unknown>
  fromDate?: string
  toDate?: string
}) => {
  const { t } = useTranslation()

  const { methods, onSubmit } = useFormMutation({
    mutationFn,
    schema: exportDashboardToCSVSchema,
    onSuccess: () => {
      closeAllModals()
    },
    onError: () => {
      showNotification({
        color: 'red',
        message: t('generic.errorOccured'),
      })
    },
    initial: {
      fromDate,
      toDate,
    },
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <ExportDashboardToCSVFormFields />
      </form>
    </FormProvider>
  )
}

export const ExportDashboardToCSVFormFields = () => {
  const { t } = useTranslation()

  return (
    <Stack>
      <Group grow>
        <InputDatetimePicker
          placeholder={capitalizeFirstLetter(t('generic.dateFrom'))}
          name="fromDate"
          maxDate={dayjs().toDate()}
          clearable
          icon={<BsCalendar2Date />}
          popoverProps={{
            withinPortal: true,
            zIndex: 10000,
          }}
        />
        <InputDatetimePicker
          placeholder={capitalizeFirstLetter(t('generic.dateTo'))}
          name="toDate"
          maxDate={dayjs().toDate()}
          clearable
          icon={<BsCalendar2Date />}
          popoverProps={{
            withinPortal: true,
            zIndex: 10000,
          }}
        />
      </Group>
      <HookFormError />
      <Group position="right">
        <Button variant="outline" onClick={() => closeAllModals()}>
          {t('generic.cancel')}
        </Button>
        <InputSubmit mt={0} value={t('generic.export')} />
      </Group>
    </Stack>
  )
}
