import { z } from 'zod'
import { indicatorSchema, Pagination } from '../helper'

export const unitSchema = z.object({
  symbol: z.string(),
  name: z.string(),
  quantity: z.string(),
})

export const measurementItemSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  packetId: z.string(),
  value: z.number().nullable(),
  date: z.string(),
  unit: unitSchema.nullable(),
  indicator: indicatorSchema.nullable(),
})

export const measurementsChartSchema = z.object({
  measurements: z.array(measurementItemSchema),
})

export type TMeasurementItem = z.infer<typeof measurementItemSchema>

export type TUnit = z.infer<typeof unitSchema>

export type TMeasurementList = Pagination<TMeasurementItem>

export type TMeasurementsChart = z.infer<typeof measurementsChartSchema>
