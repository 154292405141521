import { createStyles } from '@mantine/core'
import { useMemo } from 'react'
import { TDatatable } from '.'
import { DatatableRow } from './DatatableRow'

export type DatatableProps<TItem> = {
  headers: TDatatable<TItem>[]
  exclude?: string[]
  noShadow?: boolean
  data: TItem[]
  href?: (_data: TItem) => string
  onClick?: (_data: TItem) => void
}

export const Datatable = <TItem,>({
  exclude = [],
  headers,
  noShadow = false,
  data,
  href,
  onClick,
}: DatatableProps<TItem>) => {
  const width = headers.reduce((acc, { colSize }) => acc + colSize, 0)
  const { classes } = useStyles({ noShadow })

  const filteredHeaders = useMemo(() => {
    if (exclude.length === 0) return headers

    return headers.filter(header => !exclude.includes(header.id))
  }, [exclude, headers])

  return (
    <div className={classes.wrapper}>
      <table className={classes.table}>
        <thead className={classes.thead}>
          <tr className={classes.tr}>
            {filteredHeaders.map(({ id, label, colSize }) => (
              <th
                className={classes.th}
                style={{
                  width: `${Math.round((colSize / width) * 10000) / 100}%`,
                }}
                key={id}
              >
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((item, i) => (
            <DatatableRow
              href={href}
              onClick={onClick}
              key={i}
              value={item}
              headers={filteredHeaders}
            />
          ))}
        </tbody>
      </table>
    </div>
  )
}

const useStyles = createStyles(
  (theme, { noShadow }: { noShadow: boolean }) => ({
    wrapper: {
      width: '100%',
      display: 'flex',
      background: theme.colorScheme === 'light' ? '#fff' : theme.colors.dark[6],
      borderRadius: theme.radius.md,
      boxShadow: noShadow ? undefined : theme.shadows.lg,
    },
    table: {
      border: 'none',
      borderTopLeftRadius: theme.radius.xs,
      borderTopRightRadius: theme.radius.xs,
      width: '100%',
      borderCollapse: 'collapse',
      wordWrap: 'break-word',
    },
    thead: {
      display: 'none',
      [`@media (min-width: ${theme.breakpoints.lg})`]: {
        display: 'table-header-group',
      },
    },
    tr: {
      background: 'transparent',
      borderBottom: `2px solid ${
        theme.colorScheme === 'light'
          ? theme.colors.gray[2]
          : theme.colors.dark[7]
      }`,
    },
    th: {
      padding: theme.spacing.sm,
      fontSize: '0.85rem',
      textAlign: 'left',
    },
  }),
)
