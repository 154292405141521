import { Card, Grid, Group } from '@mantine/core'
import { deviceGroupListHelper } from 'api/deviceGroups'
import { organizationsListHelper } from 'api/organizations/getOrganizations'
import InputBoolean from 'components/form/input/Input_Boolean'
import { Input_MultiSelect } from 'components/form/input/Input_MultiSelect'
import { useFilterForm } from 'hooks/useFilterForm'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FiCpu } from 'react-icons/fi'
import { MdLocationCity } from 'react-icons/md'
import { TbLayout2, TbSearch } from 'react-icons/tb'
import { z } from 'zod'
import { AutoSubmit, InputAsyncSelect, InputText } from '..'

const schema = z.object({
  search: z.string().nullish(),
  organizationId: z.string().nullish(),
  isDeviceWithoutOrganization: z.boolean().nullish(),
  deviceGroups: z.array(z.string().nullish()).nullish(),
  orderBy: z.string().nullish(),
  sortOrder: z.string().nullish(),
})

export const DeviceFilters = () => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)

  return (
    <FormProvider {...methods}>
      <Card>
        <form onSubmit={onSubmit}>
          <Grid>
            <Grid.Col span={12} xs={4} lg={2}>
              <InputText
                name="search"
                icon={TbSearch}
                placeholder={`${t('generic.search')}`}
              />
            </Grid.Col>
          </Grid>
          <AutoSubmit />
        </form>
      </Card>
    </FormProvider>
  )
}

export const DeviceAdminFilters = () => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)

  return (
    <FormProvider {...methods}>
      <Card>
        <form onSubmit={onSubmit}>
          <Group grow>
            <InputText
              name="search"
              icon={TbSearch}
              placeholder={`${t('generic.search')}`}
            />
            <InputAsyncSelect
              name={'organizationId'}
              placeholder={t('generic.organization')}
              {...organizationsListHelper}
              icon={MdLocationCity}
              clearable
            />
            <Input_MultiSelect
              {...deviceGroupListHelper}
              name="deviceGroups"
              placeholder={t('sensor.deviceGroup')}
              icon={<TbLayout2 />}
            />
            <InputBoolean
              name={'isDeviceWithoutOrganization'}
              placeholder={t('loraDevices.unassignedDevices')}
              style={{ minWidth: '260px' }}
              icon={FiCpu}
            />
          </Group>
          <AutoSubmit />
        </form>
      </Card>
    </FormProvider>
  )
}
