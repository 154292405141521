import { z } from 'zod'
import { api } from '..'

export const addColumnSchema = z.object({
  columnSize: z.string().optional().default('OneOnThree'),
  indicator: z.string().optional(),
  oderNo: z.number().optional(),
  name: z.string().optional().nullable(),
  type: z
    .string()
    .transform(val => Number(val))
    .optional()
    .nullable(),
})
export type TAddColumnFields = z.infer<typeof addColumnSchema>

const addColumnResponse = z.object({
  columnId: z.string(),
})
export type TAddColumnResponse = z.infer<typeof addColumnResponse>

export function addColumn(
  rowId: string,
): (data: TAddColumnFields) => Promise<TAddColumnResponse>
export function addColumn(
  data: TAddColumnFields,
): (rowId: string) => Promise<TAddColumnResponse>
export function addColumn(
  arg: string | TAddColumnFields,
):
  | ((data: TAddColumnFields) => Promise<TAddColumnResponse>)
  | ((rowId: string) => Promise<TAddColumnResponse>) {
  if (typeof arg === 'string') {
    const rowId = arg
    return (data: TAddColumnFields) =>
      api
        .put(`/v1/dashboards/rows/${rowId}/columns`, data)
        .then(({ data }) => addColumnResponse.parse(data))
  } else {
    const data = arg
    return (rowId: string) =>
      api
        .put(`/v1/dashboards/rows/${rowId}/columns`, data)
        .then(({ data }) => addColumnResponse.parse(data))
  }
}

export const deleteColumnn =
  ({ columnId, rowId }: { columnId: string; rowId: string }) =>
  () =>
    api.delete(`/v1/dashboards/rows/${rowId}/columns/${columnId}`)
