import { z } from 'zod'
import { api } from '..'

export const setColumnOrderSchema = z.object({
  orderNo: z.number(),
  rowId: z.string(),
  columnId: z.string(),
})

export type TSetColumnOrderFields = z.infer<typeof setColumnOrderSchema>

export const setColumnOrder = ({
  rowId,
  columnId,
  ...data
}: TSetColumnOrderFields) =>
  api.put(`/v1/dashboards/rows/${rowId}/columns/${columnId}/order`, data)
