import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const LoraDeviceList = lazy(() => import('../List'))
const SingleLoraDevice = lazy(() => import('../Single'))
const EditLoraDevice = lazy(() => import('../Edit'))

export const loraDevicesRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['loraDevices.list'],
    element: <LoraDeviceList />,
  }),
  createPanelRoute({
    path: r['loraDevices.view'](),
    element: <SingleLoraDevice />,
  }),
  createPanelRoute({
    path: r['loraDevices.edit'](),
    element: <EditLoraDevice />,
  }),
]
