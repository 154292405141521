import { z } from 'zod'
import { api } from '..'

export const changeAlertStatusSchema = z.object({
  status: z.string(),
  comment: z.string().nullish(),
})
export type TChangeAlertStatusFields = z.infer<typeof changeAlertStatusSchema>

export const changeAlertStatus =
  (alertId: string) => (data: TChangeAlertStatusFields) =>
    api.put(`/v1/alert-rules/${alertId}/status`, data)
