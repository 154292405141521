import { useQuery } from '@tanstack/react-query'
import { TRANSLATIONS_API_URL } from 'config/env'
import { TLanguage } from 'shared/types'
import { api } from '..'

export const getLanguages = () =>
  api
    .get<TLanguage[]>(`${TRANSLATIONS_API_URL}/v1/translations/languages`)
    .then(({ data }) => {
      const entries = Object.entries(
        data.reduce((lookup: Record<string, TLanguage>, curr) => {
          lookup[curr.code] = curr

          return lookup
        }, {}),
      )

      return new Map(entries)
    })

export const useGetLanguages = () =>
  useQuery({
    queryFn: getLanguages,
    queryKey: ['languages'],
  })
