import { closeAllModals } from '@mantine/modals'
import { showNotification } from '@mantine/notifications'
import { useQueryClient } from '@tanstack/react-query'
import {
  editDashboard,
  editDashboardSchema,
  TEditDashboardFields,
} from 'api/dashboards/editDashboard'
import { AxiosError } from 'axios'
import { useFormMutation } from 'hooks/useFormMutation'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ErrorResponse } from 'shared/types'
import { DashboardFormFields } from './DashboardCreate'

export const EditDashboardInfoForm = ({
  initial,
  id,
}: {
  initial: TEditDashboardFields
  id: string
}) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const onError = (data: AxiosError<ErrorResponse>) => {
    showNotification({
      color: 'red',
      message:
        data.response?.data.errors.map(error => error.message).join(', ') ??
        t('generic.errorOccured'),
    })
  }

  const onSuccess = () => {
    queryClient.invalidateQueries(['dashboards', 'available'])
    closeAllModals()
  }

  const { methods, onSubmit } = useFormMutation({
    mutationFn: editDashboard(id),
    schema: editDashboardSchema,
    onSuccess,
    onError,
    initial,
  })

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <DashboardFormFields isEdit />
      </form>
    </FormProvider>
  )
}
