import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const MeasurementList = lazy(() => import('../List'))

export const measurementsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['measurements.list'],
    element: <MeasurementList />,
  }),
]
