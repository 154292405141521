import { z } from 'zod'

export const errorSchema = z.object({
  code: z.string(),
  message: z.string(),
  name: z.string(),
})

export const errorResponseSchema = z.object({
  errors: z.array(errorSchema),
})

export type ErrorResponse = z.infer<typeof errorResponseSchema>

export type Error = z.infer<typeof errorSchema>
