import { Box, LoadingOverlay, Stack } from '@mantine/core'
import { ReactNode } from 'react'
import { Pagination } from 'shared/types'
import { ListPagination } from '../Pagination'
import { QueryPaginatedProps } from './QueryPaginated'

export type QueryPaginatedDataProps<T> = {
  withPagination?: boolean
  children: ((_data: Pagination<T>) => ReactNode | ReactNode[]) | ReactNode
  disablePerPage?: boolean
}

export const QueryPaginatedData = <T,>({
  disablePerPage,
  children,
  query,
  withPagination = false,
}: Partial<QueryPaginatedProps<T>> & QueryPaginatedDataProps<T>) => {
  if (!query) throw new Error('Missing query data')

  if (query.data && Object.keys(query.data.items).length > 0)
    return (
      <Stack>
        <Box pos="relative">
          {children instanceof Function ? children(query.data) : children}
          <LoadingOverlay visible={query.isFetching && query.isPreviousData} />
        </Box>
        {withPagination && (
          <ListPagination disablePerPage={disablePerPage} {...query.data} />
        )}
      </Stack>
    )

  return null
}
