import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const EmailList = lazy(() => import('../List'))
const EmailSingle = lazy(() => import('../Single'))

export const emailsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['emails.list'],
    element: <EmailList />,
  }),
  createPanelRoute({
    path: r['emails.single'](),
    element: <EmailSingle />,
  }),
]
