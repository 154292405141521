import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import isoWeek from 'dayjs/plugin/isoWeek'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

import 'dayjs/locale/pl'
import 'dayjs/locale/en-gb'

dayjs.extend(relativeTime)
dayjs.extend(isoWeek)
dayjs.extend(localizedFormat)
dayjs.extend(customParseFormat)

export const dayjsLocaleCodes = {
  'pl-PL': 'pl',
  'en-GB': 'en-gb',
}

export const useDayjs = () => {
  const { i18n } = useTranslation()

  const code = i18n.language as keyof typeof dayjsLocaleCodes
  const locale =
    code in dayjsLocaleCodes
      ? dayjsLocaleCodes[code]
      : dayjsLocaleCodes['en-GB']

  return useCallback(
    (
      ...args: [
        date?: dayjs.ConfigType,
        format?: dayjs.OptionType,
        locale?: string,
        strict?: boolean,
      ]
    ) => dayjs(...args).locale(locale),
    [locale],
  )
}
