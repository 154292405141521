import { showNotification } from '@mantine/notifications'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { setColumnSize } from 'api/dashboards/setColumnSize'
import { AxiosError } from 'axios'
import { ErrorResponse } from 'shared/types'

export const useResizeColumn = ({
  rowId,
  columnId,
}: {
  rowId: string
  columnId: string
}) => {
  const queryClient = useQueryClient()
  const onError = (error: AxiosError<ErrorResponse>) => {
    if (error.response?.data.errors) {
      showNotification({
        color: 'red',
        message: error.response.data.errors
          .map(error => error.message)
          .join(', '),
      })
      return
    }
    showNotification({
      color: 'red',
      message: 'An error occured while resizing a column',
    })
  }

  const mutation = useMutation({
    mutationFn: setColumnSize({ rowId, columnId }),
    onSuccess: () => {
      queryClient.refetchQueries('dashboards' as unknown as readonly unknown[])
    },
    onError,
  })

  return mutation
}
