import { Card, Grid } from '@mantine/core'
import { useGetDevice } from 'api/devices'
import { getModelIndicatorsOptionsHelper } from 'api/models/getModelIndicators'
import { InputDeviceSelect } from 'components/devices/DeviceSelectInput'
import { AutoSubmit, InputAsyncSelect } from 'components/form'
import { InputDatetimePicker } from 'components/form/input/Input_Datetime'
import dayjs from 'dayjs'
import { useFilterForm } from 'hooks/useFilterForm'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BsCalendar2Date } from 'react-icons/bs'
import { TiWeatherPartlySunny } from 'react-icons/ti'
import { useParams } from 'react-router-dom'
import { capitalizeFirstLetter } from 'shared/utils'
import { z } from 'zod'

const schema = z.object({
  indicatorCode: z.string().nullish(),
  deviceId: z.string().nullish(),
  dateFrom: z.string().datetime().nullish(),
  dateTo: z.string().datetime().nullish(),
})

export const DeviceMeasurementsFilters = ({
  noWrapper = false,
  excludeDevice,
}: {
  noWrapper?: boolean
  excludeDevice?: boolean
}) => {
  const { deviceId } = useParams()
  const { data } = useGetDevice(deviceId)
  const { t } = useTranslation()
  const { methods, onSubmit } = useFilterForm(schema)
  const indicatorHelper = useGetIndicatorsList({
    deviceModelId: data?.modelId || '',
  })
  const Wrapper = noWrapper ? 'div' : Card

  return (
    <FormProvider {...methods}>
      <Wrapper style={{ overflow: 'visible' }}>
        <form onSubmit={onSubmit}>
          <Grid>
            <Grid.Col span={12} xs={4} lg={2}>
              <InputAsyncSelect
                name="indicatorCode"
                placeholder={capitalizeFirstLetter(t('sensor.indicator'))}
                icon={TiWeatherPartlySunny}
                {...indicatorHelper}
                clearable
              />
            </Grid.Col>
            <Grid.Col span={12} xs={4} lg={2}>
              <InputDatetimePicker
                placeholder={capitalizeFirstLetter(t('generic.from'))}
                name="dateFrom"
                maxDate={dayjs().toDate()}
                clearable
                icon={<BsCalendar2Date />}
              />
            </Grid.Col>
            <Grid.Col span={12} xs={4} lg={2}>
              <InputDatetimePicker
                placeholder={capitalizeFirstLetter(t('generic.to'))}
                name="dateTo"
                maxDate={dayjs().toDate()}
                clearable
                icon={<BsCalendar2Date />}
              />
            </Grid.Col>
            <Grid.Col span={12} xs={6} lg={4}>
              {!excludeDevice && <InputDeviceSelect />}
            </Grid.Col>
          </Grid>
          <AutoSubmit />
        </form>
      </Wrapper>
    </FormProvider>
  )
}

export const useGetIndicatorsList = ({
  deviceModelId,
}: {
  deviceModelId: string
}) => {
  const { t } = useTranslation()
  if (!deviceModelId) throw new Error('Missing deviceModelId')

  return getModelIndicatorsOptionsHelper(deviceModelId, t)
}
