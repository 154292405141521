import { ActionIcon, Alert, Button, Menu } from '@mantine/core'
import { showNotification } from '@mantine/notifications'
import { setupFirebase } from 'firebase/setupFirebase'
import { MdOutlineNotificationImportant } from 'react-icons/md'
import { AlertsList } from './AlertsList'

export const AlertsDropdown = () => {
  return (
    <Menu withArrow width={300} shadow={'sm'}>
      <Menu.Target>
        <ActionIcon>
          <MdOutlineNotificationImportant size="1.5rem" color="currentColor" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown>
        <NotificationStatus />
        <AlertsList />
      </Menu.Dropdown>
    </Menu>
  )
}

const NotificationStatus = () => {
  const enableNotifications = async () => {
    try {
      const permission = await Notification.requestPermission()
      if (permission !== 'granted')
        throw new Error(
          'You did not grant permission to receive notifications.',
        )

      await setupFirebase()
    } catch (error) {
      if (error instanceof Error)
        showNotification({
          color: 'red',
          message: error.message,
        })
    }
  }
  const notificationsArePending = Notification.permission === 'default'
  const notificationsAreDenied = Notification.permission === 'denied'

  if (notificationsAreDenied)
    return (
      <Alert m="sm" color="red" title="Notification permission denied!">
        You have denied permission to notifications. You will have to enable
        them manually by clicking on the icon to the left of the address bar.
      </Alert>
    )

  if (notificationsArePending)
    return (
      <Button
        fullWidth
        onClick={enableNotifications}
        leftIcon={<MdOutlineNotificationImportant />}
        color="red"
      >
        Enable notifications
      </Button>
    )

  return null
}
