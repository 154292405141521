import {
  ActionIcon,
  Anchor,
  Card,
  CardProps,
  Flex,
  Group,
  Skeleton,
  Stack,
  Title,
} from '@mantine/core'
import { useGetLocation } from 'api/locations'
import { DeviceTags } from 'components/tags/DeviceTags'
import { r } from 'config/routes'
import { PropsWithChildren } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCardText } from 'react-icons/bs'
import { FiExternalLink } from 'react-icons/fi'
import { Link } from 'react-router-dom'
import { TDevice } from 'shared/types'
import { capitalizeFirstLetter } from 'shared/utils'
import { LabeledContent, OnlineStatus } from '..'

export const DeviceCard = ({
  data,
  children,
  link,
  wrapperProps,
}: PropsWithChildren<{
  data: TDevice
  link?: boolean
  wrapperProps?: Omit<CardProps, 'children'>
  withoutLocation?: boolean
}>) => {
  const { t } = useTranslation()

  return (
    <Card sx={{ overflow: 'visible', flexGrow: 1 }} {...wrapperProps}>
      <Group align={'start'} position={'apart'}>
        <Stack align={'flex-start'}>
          <Flex align="center" mb={'xl'} wrap={'nowrap'}>
            {(data.type === 'LoraDevice' || data.type === 'LoraGateway') && (
              <OnlineStatus isOnline={data.isOnline} />
            )}
            <Title lineClamp={1} order={2}>
              {data.name}
            </Title>
            {link && (
              <ActionIcon
                variant="transparent"
                color="brand"
                component={Link}
                ml={8}
                to={
                  data.type === 'LoraDevice'
                    ? r['loraDevices.view'](data?.deviceId, 'indicators')
                    : data.type === 'LoraGateway'
                    ? r['gateways.view'](data?.deviceId, 'indicators')
                    : data.type === 'SNMP'
                    ? r['snmpDevices.view'](data?.deviceId, 'indicators')
                    : ''
                }
              >
                <FiExternalLink size="1.5rem" color="green" />
              </ActionIcon>
            )}
          </Flex>
          <LabeledContent icon={BsCardText} label="Description">
            {data.description}
          </LabeledContent>

          <Group spacing={'xl'}>
            <LabeledContent label={t('registerDevice.deviceType')}>
              {t(`typeCode.${data.type}`)}
            </LabeledContent>
            {data.model && (
              <LabeledContent label={'Model'}>{data.model.name}</LabeledContent>
            )}
            {data.type !== 'LoraGateway' ? (
              <LabeledContent label={t('registerDevice.provider')}>
                {data.provider}
              </LabeledContent>
            ) : null}
            <LabeledContent label={t('loraDevices.broker')}>
              {data.networkProvider?.id > 0 ? data.networkProvider?.name : null}
            </LabeledContent>
            {data.locationId !== null && (
              <LocationInfo locationId={data.locationId} />
            )}
          </Group>
          <LabeledContent label={t('generic.deviceGroups')}>
            {data?.deviceGroups && data?.deviceGroups?.length > 0
              ? data.deviceGroups.map(({ name }) => name)?.join(', ')
              : '-'}
          </LabeledContent>
          <DeviceTags />
        </Stack>
        {children}
      </Group>
    </Card>
  )
}

const LocationInfo = ({ locationId }: { locationId: string }) => {
  const { t } = useTranslation()
  const { data, isSuccess, isFetching } = useGetLocation(locationId)

  return (
    <LabeledContent label={capitalizeFirstLetter(t('location.single'))}>
      {isFetching && <Skeleton height={8} mt={6} width="70%" radius="xl" />}
      {isSuccess && (
        <Anchor component={Link} to={r['locations.view'](locationId)}>
          {data.name}
        </Anchor>
      )}
    </LabeledContent>
  )
}
