import { z } from 'zod'
import { RoleEnum } from './TRole'

export const createMemberSchema = z.object({
  email: z.string().email(),
  firstName: z.string(),
  lastName: z.string(),
  role: RoleEnum,
})

export type TCreateMemberFields = z.infer<typeof createMemberSchema>
