import {
  init,
  BrowserTracing,
  Replay,
  reactRouterV6Instrumentation,
} from '@sentry/react'
import { useEffect } from 'react'
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom'

init({
  dsn: 'https://f9283cbb90ee4ddc82b9c431eb1d71e2@o4504645159813120.ingest.sentry.io/4505584134914048',
  integrations: [
    new BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [
        // /^https:\/\/api.connectedlife.st.cetuspro.com/,
        // /^https:\/\/api.cams-snmp.connectedlife.internal/,
      ],
      routingInstrumentation: reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      ),
    }),
    new Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  enabled: !import.meta.env.DEV,
})
