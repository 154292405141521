import { Button } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { TbPlus } from 'react-icons/tb'
import { useDashboardContext } from './DashboardProvider'
import { useDashboardStore } from './hooks/useDashboardStore'
import { useInsertRow } from './hooks/useInsertRow'

export const AddRowButton = () => {
  const { t } = useTranslation()
  const isEditMode = useDashboardStore(state => state.isEditMode)
  const dashboard = useDashboardContext()

  const mutation = useInsertRow()

  if (!isEditMode) return null

  return (
    <Button
      sx={{ borderStyle: 'dashed' }}
      loading={mutation.isLoading}
      onClick={() => mutation.mutate({ dashboardId: dashboard.id })}
      size="xl"
      fullWidth
      leftIcon={<TbPlus />}
      variant="default"
    >
      {t('dashboard.insertRow')}
    </Button>
  )
}
