import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const UserInfo = lazy(() => import('..'))

export const accountRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['account'](),
    element: <UserInfo />,
  }),
]
