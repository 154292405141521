import { AxiosError, GenericAbortSignal } from 'axios'
import { api } from '..'

export const registerToken = (token: string, signal?: GenericAbortSignal) =>
  api
    .post(
      '/v1/pushs/register',
      { token },
      {
        signal,
      },
    )
    .catch(e => {
      // If request is cancelled, do nothing
      if (e instanceof AxiosError && e.code === 'ERR_CANCELED') return

      // Else, rethrow :D
      throw new Error(e)
    })
