import { Pagination } from '../helper'
import { z } from 'zod'

export const alertRuleDeviceSchema = z.object({
  deviceId: z.string(),
  alertRuleId: z.string(),
})

export type TAlertRuleDevice = z.infer<typeof alertRuleDeviceSchema>

export type TAlertRuleDeviceList = Pagination<TAlertRuleDevice>
