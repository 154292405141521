import { MultiSelect, MultiSelectProps, SelectItem } from '@mantine/core'
import { useInfiniteQuery, UseQueryOptions } from '@tanstack/react-query'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedValue } from '@mantine/hooks'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const Input_MultiSelect = ({
  queryKey,
  queryFn,
  ...props
}: Omit<MultiSelectProps, 'data'> &
  Pick<UseQueryOptions<SelectItem[]>, 'queryKey'> & {
    queryFn: (params: Record<string, string>) => Promise<SelectItem[]>
    name: string
  }) => {
  const { t } = useTranslation()
  const { control } = useFormContext()
  const [search, setSearch] = useState('')
  const [debouncedSearch] = useDebouncedValue(search, 500)
  const { data, isLoading, fetchNextPage } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => queryFn({ search: pageParam }),
    enabled: !props.disabled,
  })

  useEffect(() => {
    if (!debouncedSearch || props.disabled) return

    fetchNextPage({ pageParam: debouncedSearch })
  }, [debouncedSearch])

  const items = useMemo(
    () =>
      data?.pages
        ? [
            ...new Map(
              data.pages
                .flatMap(page => page)
                .map(item => [item['value'], item]),
            ).values(),
          ]
        : [],
    [data],
  )

  return (
    <Controller
      control={control}
      name={props.name}
      render={({ field, fieldState }) => {
        const { error } = fieldState
        return (
          <MultiSelect
            disabled={isLoading}
            data={items}
            searchable
            onSearchChange={setSearch}
            error={error?.message}
            clearable
            nothingFound={t('generic.noData')}
            {...props}
            {...field}
          />
        )
      }}
    />
  )
}
