import { z } from 'zod'

export const availableMathSymbols = ['=', '<', '>', '≥', '≤'] as const
export const mathSymbolEnum = z.enum(availableMathSymbols)
export type TMathSymbol = z.infer<typeof mathSymbolEnum>

export const mathSymbolsResponseSchema = z.object({
  symbols: z.array(
    z.object({
      value: mathSymbolEnum,
    }),
  ),
})

export type TMathSymbolsResponse = z.infer<typeof mathSymbolsResponseSchema>
