import { Group, Image } from '@mantine/core'
import { useCustomizerStore } from 'store/customizerStore'
import { CLLogoIcon, CLLogoText } from '..'

export const SidebarHeader = () => {
  const logo = useCustomizerStore(state => state.logo)
  const logoText = useCustomizerStore(state => state.logoText)

  return (
    <Group
      noWrap
      spacing={0}
      sx={{
        height: '2.5rem',
      }}
    >
      {logo ? (
        <Image width={'2.5rem'} height={'2.5rem'} fit="contain" src={logo} />
      ) : (
        <CLLogoIcon />
      )}
      {logoText ? (
        <Image height={'2.5rem'} fit="contain" src={logoText} />
      ) : (
        <CLLogoText theme={'dark'} />
      )}
    </Group>
  )
}
