import { useQuery } from '@tanstack/react-query'
import { useParams } from 'react-router-dom'
import { z } from 'zod'
import { api } from '..'
import { oidListItemSchema } from './getOids'

export const snmpDeviceOidsQueryKey = (deviceId: string) => [
  'snmpDevice',
  deviceId,
  'oids',
]

const getSnmpDeviceOids = (deviceId: string) =>
  api
    .get(`/v1/snmp/oidDevices/devices/${deviceId}/oids`)
    .then(({ data }) => z.array(oidListItemSchema).parse(data))

export const useGetSnmpDeviceOids = () => {
  const { deviceId } = useParams() as { deviceId: string }

  return useQuery({
    queryKey: snmpDeviceOidsQueryKey(deviceId),
    queryFn: () => getSnmpDeviceOids(deviceId),
  })
}

export const getDeviceOidsIndicatorsHelper = (deviceId: string) => ({
  queryKey: ['oids', 'helper', deviceId],
  queryFn: () =>
    getSnmpDeviceOids(deviceId).then(data =>
      data.map(item => ({
        value: item.id,
        label: item.oidIndicator ?? item.objectIdentifier,
        description: item.description,
      })),
    ),
})
