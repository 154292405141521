import { useQuery } from '@tanstack/react-query'
import { useSearchParams } from 'react-router-dom'
import { Pagination } from 'shared'
import { z } from 'zod'
import { api } from '..'

export type TDownlinkMessage = {
  id: string
  deviceId: string
  createdAt: Date
  type: number
  port: number
  payload: string
  isSent: boolean
  sentAt: Date
  isConfirmed: boolean
}

export type TDownlinkMessagesList = Pagination<TDownlinkMessage>

export const downlinkMessageItemSchema = z.object({
  id: z.string(),
  deviceId: z.string(),
  createdAt: z.string(),
  port: z.number(),
  payload: z.string(),
  isSent: z.boolean(),
  sentAt: z.string().nullable(),
  isConfirmed: z.boolean().nullable(),
  type: z.number(),
})

export type TDownlinkMessageItem = z.infer<typeof downlinkMessageItemSchema>

export type TMeasurementList = Pagination<TDownlinkMessageItem>

export const getDownlinkMessagesByDeviceId = (
  deviceId: string,
  params?: Record<string, string>,
) =>
  api
    .get<TMeasurementList>(
      `/v1/lorawan/devices/${deviceId}/messages/downlink`,
      {
        params: { deviceId, ...params },
      },
    )
    .then(({ data }) => data)

export const downlinkMessagesListQueryByDevice = (
  deviceId: string,
  params?: Record<string, string>,
) => ({
  queryFn: () => getDownlinkMessagesByDeviceId(deviceId, params),
  queryKey: ['downlink-messages', deviceId, params],
})

export const useGetDownlinkMessagesByDevice = (deviceId?: string) => {
  if (!deviceId) throw new Error('Missing deviceId')
  const [urlParams] = useSearchParams()
  const params = Object.fromEntries(urlParams.entries())

  const query = downlinkMessagesListQueryByDevice(deviceId, params)

  return useQuery({
    ...query,
    keepPreviousData: true,
    useErrorBoundary: false,
  })
}
