import { z } from 'zod'
import { api } from '..'
import { assignDashboardToDevice } from './deviceDashboards/assignDashboardToDevice'
import { setDefaultDeviceDashboard } from './deviceDashboards/setAsDefaultDeviceDashboard'

export const createDashboardSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
})
const createDashboardResponse = z.object({
  dashboardId: z.string(),
})

export type TCreateDashboardFields = z.infer<typeof createDashboardSchema>

export const createDashboard =
  ({ deviceId }: { deviceId: string }) =>
  async (data: TCreateDashboardFields) => {
    const { dashboardId } = await api
      .post('/v1/dashboards', data)
      .then(({ data }) => createDashboardResponse.parse(data))

    await assignDashboardToDevice({ deviceId }, { dashboardsId: [dashboardId] })
    await setDefaultDeviceDashboard({ deviceId, dashboardId })

    return { dashboardId }
  }
