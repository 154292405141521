import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const DeviceGroupList = lazy(() => import('../List'))
const DeviceGroupCreate = lazy(() => import('../Create'))
const DeviceGroupSingle = lazy(() => import('../Single'))
const DeviceGroupEdit = lazy(() => import('../Edit'))

export const DeviceGroupRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['deviceGroup.list'],
    element: <DeviceGroupList />,
  }),
  createPanelRoute({
    path: r['deviceGroup.add'],
    element: <DeviceGroupCreate />,
  }),
  createPanelRoute({
    path: r['deviceGroup.view'](),
    element: <DeviceGroupSingle />,
  }),
  createPanelRoute({
    path: r['deviceGroup.edit'](),
    element: <DeviceGroupEdit />,
  }),
]
