import { create, StateCreator } from 'zustand'
import { devtools } from 'zustand/middleware'

export interface DashboardState {
  isEditMode: boolean

  actions: DashboardActions
}

export interface DashboardActions {
  toggleEditMode: (val?: boolean) => void
}

const initialState: Omit<DashboardState, 'actions'> = {
  isEditMode: false,
}

const dashboardStore: StateCreator<DashboardState> = set => ({
  ...initialState,
  actions: {
    toggleEditMode: val =>
      set(state => ({ isEditMode: val ?? !state.isEditMode })),
  },
})

export const useDashboardStore = create<DashboardState>()(
  devtools(dashboardStore, {
    enabled: import.meta.env.DEV,
    name: 'CL Dashboard',
  }),
)

export const useDashboardActions = () =>
  useDashboardStore(state => state.actions)
