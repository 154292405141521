import { ColorScheme } from '@mantine/core'
import { api } from 'api/api'
import { TWorkspaceType } from 'components/layout/WorkspacePicker'
import { TOrganization } from 'shared/types'
import { create, StateCreator } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

export interface CustomizerState {
  extendedView: boolean
  extendedChartsView: boolean
  colorScheme: ColorScheme | 'system'
  fontSize: FontSize
  primaryColor: string
  sidebarColor: string
  logo: string | null
  logoText: string | null
  sidebarCollapsed: boolean
  mobileMenuOpened: boolean
  selectedWorkspace: TWorkspaceType

  actions: CustomizerActions
}

export type FontSize = 12 | 14 | 16 | 18 | 20

type ColorsPayload = {
  primaryColor: string | null
  sidebarColor: string | null
}
type LogosPayload = { logo: string | null; logoText: string | null }
export interface CustomizerActions {
  toggleExtendedView: (val?: boolean) => void
  toggleExtendedChartsView: (val?: boolean) => void
  toggleColorScheme: (val: ColorScheme | 'system' | undefined) => void
  setColors: (payload: ColorsPayload) => void
  setLogos: (payload: LogosPayload) => void
  setBranding: (payload: ColorsPayload & LogosPayload) => void
  updateData: () => void
  toggleSidebarCollapse: (val?: boolean) => void
  toggleMobileMenu: (val?: boolean) => void
  setFontSize: (fontSize: FontSize) => void
  setWorkspace: (workspace: TWorkspaceType) => void
}

const initialState: Omit<CustomizerState, 'actions'> = {
  extendedView: false,
  extendedChartsView: false,
  colorScheme: 'system',
  fontSize: 16,
  primaryColor: '#ffdb25',
  sidebarColor: '#3b3f54',
  logo: null,
  logoText: null,
  sidebarCollapsed: false,
  mobileMenuOpened: false,
  selectedWorkspace: 'lorawan',
}

const customizerStore: StateCreator<CustomizerState> = (set, get) => ({
  ...initialState,

  actions: {
    toggleSidebarCollapse: val =>
      set(state => ({ sidebarCollapsed: val || !state.sidebarCollapsed })),
    toggleMobileMenu: val =>
      set(state => ({ mobileMenuOpened: val || !state.mobileMenuOpened })),
    toggleExtendedView: val =>
      set(state => ({ extendedView: val ?? !state.extendedView })),
    toggleExtendedChartsView: val =>
      set(state => ({ extendedChartsView: val ?? !state.extendedChartsView })),
    toggleColorScheme: value =>
      set(state => ({
        colorScheme:
          value !== undefined
            ? value
            : state.colorScheme === 'dark'
            ? 'light'
            : 'dark',
      })),
    setColors: ({ primaryColor, sidebarColor }) =>
      set({
        primaryColor: primaryColor ?? initialState.primaryColor,
        sidebarColor: sidebarColor ?? initialState.sidebarColor,
      }),
    setLogos: ({ logo, logoText }) => set({ logo, logoText }),
    setBranding: ({ primaryColor, sidebarColor, logo, logoText }) => {
      get().actions.setColors({ primaryColor, sidebarColor })
      get().actions.setLogos({ logo, logoText })
    },
    updateData: async () => {
      const organization = await api
        .get<TOrganization>('/v1/organization')
        .then(({ data }) => data)
      set({
        primaryColor: organization.primaryColor ?? initialState.primaryColor,
        sidebarColor: organization.sidebarColor ?? initialState.sidebarColor,
        logo: organization.logo?.url ?? null,
        logoText: organization.textLogo?.url ?? null,
      })
    },
    setFontSize: fontSize => set({ fontSize }),
    setWorkspace: selectedWorkspace => set({ selectedWorkspace }),
  },
})

const customizerStoreFactory = () =>
  create<CustomizerState>()(
    devtools(
      persist(customizerStore, {
        name: 'cl-customizer',
        partialize: state =>
          Object.fromEntries(
            Object.entries(state).filter(([key]) => !['actions'].includes(key)),
          ),
      }),
      {
        enabled: import.meta.env.DEV,
        name: 'CL Customizer',
      },
    ),
  )

export const useCustomizerStore = customizerStoreFactory()
export const useCustomizerActions = () =>
  useCustomizerStore(state => state.actions)
export const initialColors = {
  primaryColor: initialState.primaryColor,
  sidebarColor: initialState.sidebarColor,
}
