import { z } from 'zod'

export const addOidDefSchema = z.object({
  oidId: z.string().optional(),
  objectIdentifier: z.string(),
  description: z.string().nullish(),
  oidIndicator: z.string().nullish(),
})

export const addOidDefArraySchema = z.object({ oids: z.array(addOidDefSchema) })

export type TAddOidDefFields = z.infer<typeof addOidDefSchema>
export type TAddOidDefArrayFields = z.infer<typeof addOidDefArraySchema>
