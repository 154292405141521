import pushImg from 'assets/img/push-img.svg'
import {
  Button,
  Center,
  Checkbox,
  Group,
  Image,
  Stack,
  Text,
  Title,
} from '@mantine/core'
import { useLocalStorage } from '@mantine/hooks'
import { closeModal } from '@mantine/modals'

export const dontAskAgainKey = 'notification-request-modal-dont-ask-again'
export const notificationRequestModalId = 'notification-request-modal'

export const NotificationRequestModal = ({
  onAllow,
}: {
  onAllow: () => void
}) => {
  const [dontAskAgain, setDontAskAgain] = useLocalStorage({
    key: dontAskAgainKey,
    defaultValue: false,
  })
  return (
    <Stack p={'lg'} pb={0}>
      <Center
        sx={theme => ({
          width: '40%',
          margin: '0 auto',
          [`${theme.fn.largerThan('lg')}`]: {
            width: '80%',
          },
        })}
      >
        <Image
          src={pushImg}
          alt="A person holding a push notification looking at a phone with couple of other notifications on the screen."
          mb={'md'}
        />
      </Center>
      <Title order={3}>Never miss an alert from your devices.</Title>
      <Text>
        We would like to request permission to send push notifications to your
        device to alert you of any important alerts coming from your devices.
        This will allow you to stay informed and respond promptly to any unusual
        activity, ensuring the safety and security of your property.
      </Text>
      <Group mt={'lg'} position={'apart'}>
        <Checkbox
          checked={dontAskAgain}
          onChange={e => setDontAskAgain(e.target.checked)}
          label={"Don't ask me again"}
        />
        <Button
          onClick={() => closeModal(notificationRequestModalId)}
          ml={'auto'}
          data-testid={'notification-request-modal-cancel'}
          variant={'subtle'}
        >
          Not now
        </Button>
        <Button
          data-testid={'notification-request-modal-confirm'}
          onClick={onAllow}
        >
          Allow
        </Button>
      </Group>
    </Stack>
  )
}
