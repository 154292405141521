import { useQuery } from '@tanstack/react-query'
import { getAlertRule } from 'api/alertRules'
import { TAlertRule } from 'shared/types'
import { api } from '..'

export const getAlertRuleByDeviceList = (deviceId: string) =>
  api
    .get<TAlertRule[]>(`/v1/devices/${deviceId}/alert-rules`, {
      params: { results: 100 },
    })
    .then(({ data }) => data)

export const getAlertRuleByDevice = async (deviceId: string) => {
  const list = await getAlertRuleByDeviceList(deviceId)

  const promises = list.map(item => getAlertRule(item.id))
  const data = await Promise.all(promises)

  return data as TAlertRule[]
}

export const getAlertRuleByDeviceQuery = (deviceId: string) => ({
  queryKey: ['alertRule', deviceId, 'alertRules'],
  queryFn: () => getAlertRuleByDevice(deviceId),
})

export const useGetAlertRuleByDevice = (deviceId?: string) => {
  if (!deviceId) throw new Error('Missing deviceId')

  const query = getAlertRuleByDeviceQuery(deviceId)

  return useQuery({
    ...query,
  })
}
