import { z } from 'zod'

export const snmpDeviceSchema = z.object({
  id: z.string(),
  ipAddress: z.string(),
  port: z.number(),
  community: z.string(),
})

export type TSnmpDevice = z.infer<typeof snmpDeviceSchema>
