import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const AlertList = lazy(() => import('../List'))
const AlertSingle = lazy(() => import('../Single'))

export const alertRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['alert.list'],
    element: <AlertList />,
  }),
  createPanelRoute({
    path: r['alert.view'](),
    element: <AlertSingle />,
  }),
]
