export const findMinAndMaxValueForChart = (
  points: (number | null)[],
): {
  min: number
  max: number
} => {
  if (points.length === 0) {
    return { min: 0, max: 0 }
  }

  let minByY: number = points[0] || 0
  let maxByY: number = points[0] || 0

  for (const point of points) {
    if (point != null) {
      if (point < minByY) {
        minByY = point
      }
      if (point > maxByY) {
        maxByY = point
      }
    }
  }

  const y_min = Math.min(minByY, 0)
  const y_max = Math.max(maxByY, 0)

  return { min: y_min * 1.05, max: y_max * 1.05 }
}
