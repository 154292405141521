import { Loader as Spinner } from '@mantine/core'
import { useCustomizerStore } from 'store/customizerStore'

export const Loader = () => {
  const primaryColor = useCustomizerStore(state => state.primaryColor)
  return (
    <div
      data-testid="loader"
      style={{
        display: 'grid',
        placeItems: 'center',
        flexGrow: 1,
        width: '100%',
        height: '100%',
      }}
    >
      <Spinner variant="bars" size="lg" color={primaryColor ?? 'yellow'} />
    </div>
  )
}
