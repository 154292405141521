const indicatorColorsArray = [
  {
    code: 'PM1',
    color: '#339a12',
  },
  {
    code: 'PM2.5',
    color: '#20610b',
  },
  {
    code: 'PM10',
    color: '#0c2903',
  },
  {
    code: 'AirHumidity',
    color: '#030c29',
  },
  {
    code: 'AirPressure',
    color: '#3d1818',
  },
  {
    code: 'AirTemperature',
    color: '#6e0a0a',
  },
  {
    code: 'NoiseLevel',
    color: '#171010',
  },
  {
    code: 'AirGasResistance',
    color: '#3d0d33',
  },
  {
    code: 'InternalRegulatorVoltage',
    color: '#362004',
  },
  {
    code: 'InternalBatteryVoltage',
    color: '#121110',
  },
  {
    code: 'InternalTemperature',
    color: '#400303',
  },
  {
    code: 'ExternalBatteryVoltage',
    color: '#3b0134',
  },
]

export const indicatorColors = new Map(
  indicatorColorsArray.map(({ code, color }) => [code, color]),
)
