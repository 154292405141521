import { useQuery } from '@tanstack/react-query'
import { TLocation } from 'shared/types'
import { api } from '..'

export const getLocation = (locationId?: string | null) => {
  if (!locationId) throw new Error('Missing locationId')
  return api
    .get<TLocation>(`/v1/locations/${locationId}`)
    .then(({ data }) => data)
}

export const useGetLocation = (locationId?: string | null) => {
  return useQuery({
    queryFn: () => getLocation(locationId),
    queryKey: ['locations', locationId],
  })
}
