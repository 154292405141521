import { z } from 'zod'
import { Pagination } from '../helper'

export const loraDeviceListItemSchema = z.object({
  deviceId: z.string(),
  eui: z.string(),
  appEUI: z.string(),
  appKey: z.string().nullish(),
  isOnline: z.boolean(),
  deviceGroups: z
    .array(
      z.object({
        id: z.string().nullable(),
        name: z.string().nullable(),
        description: z.string().nullable(),
        locationId: z.string().nullable(),
        countDevice: z.number().nullable(),
      }),
    )
    .nullable(),
})

export type TLoraDeviceListItem = z.infer<typeof loraDeviceListItemSchema>

export type TLoraDeviceList = Pagination<TLoraDeviceListItem>
