const prefix = ''

export const deviceGroupsUrls = {
  'deviceGroup.list': `${prefix}/device-groups`,
  'deviceGroup.add': `${prefix}/device-groups/add`,
  'deviceGroup.view': (deviceGroupId = ':deviceGroupId') =>
    `${prefix}/device-groups/${deviceGroupId}`,
  'deviceGroup.edit': (deviceGroupId = ':deviceGroupId') =>
    `${prefix}/device-groups/${deviceGroupId}/edit`,
}
