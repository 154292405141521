import { Suspense } from 'react'
import { Outlet, RouteObject } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { ErrorView, Loader } from 'components/common'

export const PlainLayout = ({ title }: { title?: string | null }) => {
  return (
    <>
      {title && (
        <Helmet>
          <title>{title} | Connected Life</title>
        </Helmet>
      )}
      <Suspense fallback={<Loader />}>
        <Outlet />
      </Suspense>
    </>
  )
}

export const createPlainRoute: (route: RouteObject) => RouteObject = route => ({
  path: route.path,
  element: <PlainLayout />,
  children: [{ ...route, errorElement: <ErrorView /> }],
})
