import { z } from 'zod'
import { logicalOperatorEnum } from './TLogicalOperators'

export const createAlertRuleConditionGroupSchema = z.object({
  logicalOperator: logicalOperatorEnum.optional().default('And'),
})

export type TCreateAlertRuleGroupFields = z.infer<
  typeof createAlertRuleConditionGroupSchema
>

export const createAlertRuleGroupResponseSchema = z.object({
  ruleConditionGroupId: z.string(),
})

export type TCreateAlertRuleGroupResponse = z.infer<
  typeof createAlertRuleGroupResponseSchema
>
