import { create, StateCreator } from 'zustand'
import { devtools } from 'zustand/middleware'
import { TSignInResponse, TEditUserInfoFields, TUser } from '../types'

export interface AuthState {
  user: TUser | null
  organizationId: string | null
  isLoggedIn: boolean

  actions: AuthActions
}

export interface AuthActions {
  setLoginData: (data: TUser | TSignInResponse) => void
  clearLoginData: () => void
  updateUserInfo: (data: TEditUserInfoFields) => void
  setOrganizationId: (organizationId: string) => void
}

const initialState: Omit<AuthState, 'actions'> = {
  user: null,
  organizationId: null,
  isLoggedIn: false,
}

const authStore: StateCreator<AuthState> = set => ({
  ...initialState,
  actions: {
    setLoginData: data =>
      set({
        user: data,
        isLoggedIn: true,
      }),
    clearLoginData: () => set(initialState),
    updateUserInfo: data =>
      set(state => {
        const prev = state.user as TUser
        return { user: { ...prev, ...data } }
      }),
    setOrganizationId: organizationId => set({ organizationId }),
  },
})

export const authStoreFactory = () =>
  create<AuthState>()(
    devtools(authStore, {
      enabled: import.meta.env.DEV,
      name: 'CL Auth',
    }),
  )
