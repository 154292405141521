import { SegmentedControl } from '@mantine/core'
import { useMemo } from 'react'
import { useDashboardContext } from './DashboardProvider'
import { useResizeColumn } from './hooks/useResizeColumn'
import { calculateRemainingSpace } from './utils/calculateRemainingSpace'
import {
  dashboardColumnSize,
  dashboardColumnSizeArray,
} from './utils/dashboardColumnSize'

export const DashboardColumnSizer = ({
  columnSize,
  columnId,
  rowId,
}: {
  columnSize: string
  rowId: string
  columnId: string
}) => {
  const dashboard = useDashboardContext()
  const currentRow = useMemo(
    () => dashboard.rows.find(row => row.id === rowId)!,
    [dashboard],
  )
  const remainingSpace = useMemo(
    () =>
      calculateRemainingSpace(
        dashboard.rows.find(row => row.id === rowId)!.columns,
      ),
    [dashboard],
  )
  const availableOptions = useMemo(
    () =>
      dashboardColumnSizeArray.map(item => ({
        ...item,
        disabled:
          currentRow.columns.length !== 1 &&
          item.colSpan >
            remainingSpace + dashboardColumnSize.get(columnSize)!.colSpan,
      })),
    [remainingSpace],
  )

  const mutation = useResizeColumn({ rowId, columnId })
  return (
    <SegmentedControl
      size="xs"
      data={availableOptions}
      value={columnSize}
      onChange={val => {
        if (val === columnSize) return
        return mutation.mutate({ size: val })
      }}
    />
  )
}
