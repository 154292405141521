import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const DeviceList = lazy(() => import('../List'))
const RegisterDevice = lazy(() => import('../RegisterDevice/VRegisterDevice'))

export const devicesRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['devices.list'],
    element: <DeviceList />,
  }),
  createPanelRoute({
    path: r['devices.register'],
    element: <RegisterDevice />,
  }),
]
