import { ActionIcon, ActionIconProps, Tooltip } from '@mantine/core'
import { MouseEventHandler } from 'react'
import { IconType } from 'react-icons'
import { Link, To } from 'react-router-dom'

interface IconButtonProps extends ActionIconProps {
  label: string
  to?: To
  onClick?: MouseEventHandler<HTMLButtonElement>
  icon: IconType
}

export const IconButton = ({
  label,
  to,
  onClick,
  icon: Icon,
  variant,
  color,
  ...props
}: IconButtonProps) => {
  return (
    <Tooltip
      transitionProps={{
        transition: 'pop',
      }}
      label={label}
    >
      {to ? (
        <ActionIcon
          variant={variant ?? 'filled'}
          color={color ?? 'brand'}
          component={Link}
          to={to}
          {...props}
        >
          <Icon size="1.5rem" color="currentColor" />
        </ActionIcon>
      ) : (
        <ActionIcon
          variant={variant ?? 'filled'}
          color={color ?? 'brand'}
          onClick={onClick}
          {...props}
        >
          <Icon size="1.5rem" color="currentColor" />
        </ActionIcon>
      )}
    </Tooltip>
  )
}
