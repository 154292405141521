import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const LabelsList = lazy(() => import('../List'))
const LabelSingle = lazy(() => import('../Single'))

export const labelsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['labels.list'],
    element: <LabelsList />,
  }),
  createPanelRoute({
    path: r['labels.single'](),
    element: <LabelSingle />,
  }),
]
