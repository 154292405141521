import { RemindPassword } from 'shared/types'
import { z } from 'zod'
import { api } from '..'

export const remindPassword = (params: RemindPassword) =>
  api.get('/v1/accounts/reset-password', { params })

export const resetPasswordSchema = z
  .object({
    token: z.string(),
    userId: z.string(),
    password: z.string(),
    confirmPassword: z.string(),
  })
  .superRefine(({ password, confirmPassword }, ctx) => {
    if (confirmPassword !== password) {
      ctx.addIssue({
        code: 'custom',
        message: "Passwords don't match",
        path: ['confirmPassword'],
      })
    }
  })

export type TResetPasswordFields = z.infer<typeof resetPasswordSchema>

export const resetPassword = (data: TResetPasswordFields) =>
  api.post('/v1/accounts/reset-password', data)
