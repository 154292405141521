import { Switch } from '@mantine/core'
import { useTranslation } from 'react-i18next'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'

export const ExtendedChartsViewSwitch = () => {
  const { t } = useTranslation()
  const extendedChartsView = useCustomizerStore(
    state => state.extendedChartsView,
  )
  const { toggleExtendedChartsView } = useCustomizerActions()

  return (
    <Switch
      label={t('generic.extendedView')}
      checked={extendedChartsView}
      onChange={event => toggleExtendedChartsView(event.currentTarget.checked)}
    />
  )
}
