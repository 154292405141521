import { useDisclosure } from '@mantine/hooks'
import { useCallback, useState } from 'react'

export const useDisclosureState = <TState>(initialState: boolean) => {
  const [state, setState] = useState<TState | null>(null)
  const [isOpen, handlers] = useDisclosure(initialState)

  const open = useCallback(
    (value: TState) => {
      handlers.open()
      setState(value)
    },
    [handlers],
  )
  const close = useCallback(() => {
    handlers.close()
    setState(null)
  }, [handlers])

  return { isOpen, state, open, close }
}
