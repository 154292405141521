import { Anchor, AnchorProps } from '@mantine/core'
import { forwardRef, Ref } from 'react'
import { Link } from 'react-router-dom'

type TableLinkProps = {
  href: string
} & AnchorProps

export const TableLink = forwardRef(
  (
    { href, children, ...props }: TableLinkProps,
    ref: Ref<HTMLAnchorElement>,
  ) => {
    return (
      <Anchor
        ref={ref}
        component={Link}
        to={href}
        onClick={e => {
          e.stopPropagation()
        }}
        {...props}
      >
        {children}
      </Anchor>
    )
  },
)

TableLink.displayName = 'TableLink'
