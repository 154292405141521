import { useQuery } from '@tanstack/react-query'
import { TTagListPaginated } from 'shared/types'
import { api } from '..'

export const getTagsList = (params?: Record<string, string>) =>
  api.get<TTagListPaginated>('/v1/tags', { params }).then(({ data }) => data)

export const getTagsListQuery = (params?: Record<string, string>) => ({
  queryKey: ['tags', params],
  queryFn: () =>
    getTagsList(params).then(data =>
      data.items.map(tag => ({ label: tag.name, value: tag.id })),
    ),
})

export const useGetTags = (search?: string) => {
  const query = getTagsListQuery(search ? { search } : undefined)

  return useQuery({
    ...query,
    keepPreviousData: true,
  })
}

export const getMultiSelectTagsQuery = {
  queryKey: ['tags', 'multiselect'],
  queryFn: (params: Record<string, string>) =>
    getTagsList(params).then(data =>
      data.items.map(item => ({ value: item.id, label: item.name })),
    ),
}
