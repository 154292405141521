import { Box, Flex, Text } from '@mantine/core'
import { forwardRef } from 'react'
import { IconType } from 'react-icons'

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  label: string
  icon?: IconType
  description?: string
}

export const SelectItemWithIcon = forwardRef<HTMLDivElement, ItemProps>(
  ({ label, description, icon: Icon, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Flex align={'center'} gap={'xs'}>
        {Icon && <Icon size={'1.2em'} />}
        <Box>
          <Text size="sm">{label}</Text>
          {description && (
            <Text size="xs" opacity={0.65}>
              {description}
            </Text>
          )}
        </Box>
      </Flex>
    </div>
  ),
)
