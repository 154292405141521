import { Button, Group, Radio, Stack } from '@mantine/core'
import { closeAllModals, openModal } from '@mantine/modals'
import { T } from 'components/common/T'
import {
  InputBinary,
  InputNumber,
  InputRadio,
  InputSubmit,
  InputText,
} from 'components/form'
import { FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BiRename } from 'react-icons/bi'
import { TbNumber } from 'react-icons/tb'
import { useCreateDownlinkPreset } from './hooks/useCreateDownlinkPreset'

export const openCreateDownlinkPreset = (
  deviceModelId: string,
  fPort?: number,
  payload?: string,
  insertMode?: number,
) => {
  openModal({
    title: <T>loraDevices.createDownlinkPreset</T>,
    children: (
      <CreateDownlinkPreset
        deviceModelId={deviceModelId}
        fPort={fPort}
        payload={payload}
        insertMode={insertMode}
      />
    ),
  })
}

type TCreateDownlinkPreset = {
  deviceModelId: string
  fPort?: number
  payload?: string
  insertMode?: number
}

export const CreateDownlinkPreset = ({
  deviceModelId,
  fPort,
  payload,
  insertMode,
}: TCreateDownlinkPreset) => {
  const { t } = useTranslation()
  const { methods, onSubmit } = useCreateDownlinkPreset(
    deviceModelId,
    fPort,
    payload,
    insertMode,
  )

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <Stack>
          <InputText
            required
            name="title"
            label={t('generic.title')}
            icon={BiRename}
          />
          <InputRadio required name="insertMode" label="Insert mode">
            <Group mt="sm">
              <Radio value={'2'} label="Replace downlink queue" />
              <Radio value={'1'} label="Push to downlink queue" />
            </Group>
          </InputRadio>
          <InputNumber
            sx={{ flexGrow: 0 }}
            name="fPort"
            min={1}
            max={127}
            step={1}
            precision={0}
            label={'Port'}
            required
            icon={TbNumber}
          />
          <InputBinary
            length={32}
            name="payload"
            label={'Payload'}
            required
            maskChar={null}
            icon={TbNumber}
          />
          <Group position="right">
            <Button
              variant={'outline'}
              onClick={() => closeAllModals()}
              type="button"
            >
              {t('generic.cancel')}
            </Button>
            <InputSubmit mt={0} value={t('generic.add')} />
          </Group>
        </Stack>
      </form>
    </FormProvider>
  )
}
