import { Group, Slider } from '@mantine/core'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IoText } from 'react-icons/io5'
import {
  FontSize,
  useCustomizerActions,
  useCustomizerStore,
} from 'store/customizerStore'
import { SettingsSection } from './DisplaySettings'

const MARKS: { value: FontSize }[] = [
  { value: 12 },
  { value: 14 },
  { value: 16 },
  { value: 18 },
  { value: 20 },
]

export const FontSettings = () => {
  const { t } = useTranslation()
  const fontSize = useCustomizerStore(state => state.fontSize)
  const { setFontSize } = useCustomizerActions()

  const [val, setVal] = useState<number>(() => fontSize)

  return (
    <SettingsSection title={t('generic.fontSettings')}>
      <Group>
        <IoText size="16" />

        <Slider
          label={null}
          sx={{ flexGrow: 1 }}
          value={val}
          onChange={setVal}
          onChangeEnd={val => setFontSize(val as FontSize)}
          step={2}
          min={12}
          max={20}
          marks={MARKS}
          styles={{ markLabel: { display: 'none' } }}
        />
        <IoText size="24" />
      </Group>
    </SettingsSection>
  )
}
