import { z } from 'zod'

export const languageSchema = z.object({
  id: z.number(),
  name: z.string(),
  nameEng: z.string(),
  code: z.string(),
})

export type TLanguage = z.infer<typeof languageSchema>
