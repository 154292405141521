import { useQuery } from '@tanstack/react-query'
import { TFunction } from 'i18next'
import { TDeviceIndicatorsResponse, TIndicatorsResponse } from 'shared/types'
import { api } from '..'

export const getAllIndicators = () =>
  api
    .get<TIndicatorsResponse>('/v1/devices/indicators')
    .then(({ data }) => data.indicators)

export const getIndicatorsOptionsHelper = (t: TFunction) => ({
  queryKey: ['indicators', 'helper'],
  queryFn: () =>
    getAllIndicators().then(data =>
      data.map(item => ({
        value: item.code,
        label: `${
          item?.isExternal ? item?.name : t(`indicator.${item?.code}`)
        } ${item.unit ? `(${item.unit})` : ''}`,
        unit: item.unit,
      })),
    ),
})

export const useGetAllIndicators = () =>
  useQuery({
    queryKey: ['allIndicators', 'helper'],
    queryFn: () => getAllIndicators(),
    staleTime: Infinity,
  })

export const useGetIndicators = (t: TFunction) =>
  useQuery({ ...getIndicatorsOptionsHelper(t), staleTime: Infinity })

export const getDeviceIndicators = (deviceId: string) =>
  api
    .get<TDeviceIndicatorsResponse>(`/v1/devices/${deviceId}/indicators`)
    .then(({ data }) => data.indicators)

export const getDeviceIndicatorsOptionsHelper = (
  deviceId: string,
  t: TFunction,
) => ({
  queryKey: ['indicators', 'helper', deviceId],
  queryFn: () =>
    getDeviceIndicators(deviceId).then(data =>
      data.map(item => ({
        value: item.indicatorCode,
        label: `${
          item?.indicator?.isExternal
            ? item?.indicator?.name
            : t(`indicator.${item.indicatorCode}`)
        } ${item.indicator?.unit ? `(${item.indicator.unit})` : ''}`,
        unit: item.indicator.unit,
      })),
    ),
})

export const useGetDeviceIndicators = (deviceId?: string) => {
  if (!deviceId) throw new Error('Missing deviceId')

  return useQuery({
    queryFn: () => getDeviceIndicators(deviceId),
    queryKey: ['indicators', deviceId],
    staleTime: Infinity,
  })
}
