import { z } from 'zod'
import { api } from '..'

export const setRowOrderSchema = z.object({
  rowId: z.string(),
  orderNo: z.number(),
})

export type TSetRowOrderFields = z.infer<typeof setRowOrderSchema>

export const setRowOrder =
  ({ dashboardId }: { dashboardId: string }) =>
  ({ rowId, orderNo }: TSetRowOrderFields) =>
    api.put(`/v1/dashboards/${dashboardId}/rows/${rowId}/order`, { orderNo })
