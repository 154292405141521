import { Box, Button, Group, Stack, Text } from '@mantine/core'
import { TDownlinkPresetListItem } from 'api/loraDevices/getDownlinkPresets'
import { downlinkInsertModes } from 'data/downlinkInsertMode'
import { memo } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export const DownlinkPresetItem = memo((item: TDownlinkPresetListItem) => {
  const { t } = useTranslation()
  const { setValue } = useFormContext()

  const onClick = () => {
    const presetItem = {
      type: item.insertMode.toString(),
      fPort: item.port,
      payload: item.payload,
    }

    Object.entries(presetItem).forEach(([key, value]) => setValue(key, value))
  }

  return (
    <Box
      sx={theme => ({
        borderBottom: `1px solid ${
          theme.colorScheme === 'light'
            ? theme.colors.gray[4]
            : theme.colors.dark[4]
        }`,
        '&:last-child': {
          borderBottom: 'none',
          paddingBottom: 0,
        },
        '&:first-of-type': {
          paddingTop: 0,
        },
      })}
      key={item.id}
      py="sm"
      px="lg"
    >
      <Group position="apart" spacing={'xl'}>
        <Stack spacing={0}>
          <Text size="sm">{item.title}</Text>
          <Group>
            <Text size="xs" sx={{ fontFamily: 'monospace' }}>
              {item.payload}
            </Text>
            <Text size="xs">Port: {item.port}</Text>
            <Text size="xs">
              {t('generic.type')}:{' '}
              {downlinkInsertModes(t).get(item.insertMode)?.label}
            </Text>
          </Group>
        </Stack>
        <Button compact onClick={onClick} size="sm">
          Use this preset
        </Button>
      </Group>
    </Box>
  )
})
