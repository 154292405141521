import { r } from 'config/routes'
import { lazy } from 'react'
import { RouteObject } from 'react-router-dom'
import { createPanelRoute } from 'views/panel/PanelLayout'

const ModelList = lazy(() => import('../List'))
const ModelEdit = lazy(() => import('../Edit'))
const ModelCreate = lazy(() => import('../Create'))
const ModelSingle = lazy(() => import('../Single'))
// const ModelTester = lazy(() => import('../Tester'))

export const modelsRoutes: RouteObject[] = [
  createPanelRoute({
    path: r['model.list'],
    element: <ModelList />,
  }),
  createPanelRoute({
    path: r['model.add'],
    element: <ModelCreate />,
  }),
  createPanelRoute({
    path: r['model.view'](),
    element: <ModelSingle />,
  }),
  createPanelRoute({
    path: r['model.edit'](),
    element: <ModelEdit />,
  }),
  // createPanelRoute({
  //   path: r['parser.tester'],
  //   element: <ParserTester />,
  // }),
]
