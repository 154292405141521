import {
  ColorSchemeProvider,
  createEmotionCache,
  MantineProvider,
} from '@mantine/core'
import { QueryClientProvider } from '@tanstack/react-query'
import { PropsWithChildren } from 'react'
import queryClient from './queryClient'
import { useCreateTheme } from './theme'
import { ModalsProvider } from '@mantine/modals'
import { Notifications } from '@mantine/notifications'
import { useCustomizerActions, useCustomizerStore } from 'store/customizerStore'
import { HelmetProvider } from 'react-helmet-async'
import { getSystemColorScheme } from 'utils/getSystemColorScheme'
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const cache = createEmotionCache({ key: 'm', prepend: false })

export const Providers = ({ children }: PropsWithChildren) => {
  const colorScheme = useCustomizerStore(state => state.colorScheme)
  const { toggleColorScheme } = useCustomizerActions()
  const theme = useCreateTheme()

  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <ColorSchemeProvider
          colorScheme={
            colorScheme === 'system' ? getSystemColorScheme() : colorScheme
          }
          toggleColorScheme={toggleColorScheme}
        >
          <MantineProvider
            withGlobalStyles
            withNormalizeCSS
            theme={theme}
            emotionCache={cache}
          >
            <ModalsProvider
              modalProps={{
                styles: {
                  overlay: {
                    zIndex: 1002,
                  },
                  inner: {
                    zIndex: 1003,
                  },
                },
              }}
            >
              <Notifications />
              {children}
            </ModalsProvider>
          </MantineProvider>
        </ColorSchemeProvider>
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </HelmetProvider>
  )
}
