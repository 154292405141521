import { z } from 'zod'
import { DeviceTypeEnum } from '../devices'

export const registerLoraGatewayResponseSchema = z.object({
  gatewayId: z.string(),
  deviceId: z.string(),
})

export const RegisterLoraGatewaySchema = z.object({
  deviceId: z.string(),
  eui: z.string(),
  typeCode: DeviceTypeEnum,
})

export type TRegisterLoraGatewayFields = z.infer<
  typeof RegisterLoraGatewaySchema
>

export type TRegisterLoraGatewayResponse = z.infer<
  typeof registerLoraGatewayResponseSchema
>
