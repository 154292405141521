import { z } from 'zod'

export const DeviceTypeEnum = z.enum([
  'UnKnown',
  'LoraDevice',
  'LoraGateway',
  'SNMP',
  'MQTT',
])
export type TDeviceType = z.infer<typeof DeviceTypeEnum>

export const deviceTypesResponseSchema = z.object({
  types: z.array(
    z.object({
      code: DeviceTypeEnum,
    }),
  ),
})

export type TDeviceTypesResponse = z.infer<typeof deviceTypesResponseSchema>
